import styled from "styled-components";
import {getNumWeek} from "../../../../components/functions/TimesFunctions";
import React, {useEffect, useMemo, useState} from "react";
import {BsChevronUp} from "react-icons/bs";
import {useGetParamsTimed} from "../../../../hooks/useGetParamsTimed";
import {useGetAllUser} from "../../../../features/user/user.hooks";
import {useGetCompteurTempsDateDate} from "../../../../features/compteurTempsDateDate/compteurTempsDateDate.hooks";
import TimedRoundAvatar from "../../../../components/atoms/TimedRoundAvatar/TimedRoundAvatar";
import TimedSkeletonTable from "../../../../components/atoms/TimedSkeleton/TimedSkeletonTable";
import TimedTable from "../../../../components/molecules/TimedTable/TimedTable";
import {useCentreConnectedData} from "../../../../services/hooks";

interface OneWeekGestionHSCtrlProps{
    className?:string;
    From:Date;
    To:Date;
    supervisor?:boolean;
}
const TitlesTab:TitleTable[]=[
    {libelle:'Pic', field:'avatar', fieldSort:'nom', date:false, textAlign:'text-center', width:"80px", StyleHead:{textAlign:"center"}},
    {libelle:'Initiales', field:'inits', fieldSort:'inits', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
    {libelle:'Nom', field:'fullName', fieldSort:'fullNameLib', date:false, textAlign:'', width:"120px"},
    {libelle:'Heures Ct', field:'heuresCt', fieldSort:'heuresCt', date:false, textAlign:'', width:"120px"},
    {libelle:'Heures Tr.', field:'heuresDid', fieldSort:'heuresDid', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
    {libelle:'Heures Non Tr.', field:'heuresNoWork', fieldSort:'heuresNoWork', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
    {libelle:'Heures Regulées.', field:'heuresRegules', fieldSort:'heuresRegules', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
    {libelle:'Total heures', field:'heuresTotales', fieldSort:'heuresTotales', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
    {libelle:'Ecart', field:'ecart', fieldSort:'ecart', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
    {libelle:'Actions', field:'action', fieldSort:'actNb', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
]

const OneWeekGestionHSCtrl = ({className, From, To, supervisor}:OneWeekGestionHSCtrlProps)=>{
    const centre = useCentreConnectedData();
    const {isLoading:loadUsers, data:users} = useGetAllUser(supervisor ? centre : null);
    const {TabCollab, TabFx} = useGetParamsTimed();
    const [open, setOpen] = useState(false)
    const [usersConc, setUsersConc] = useState<User[]>([]);
    const [alertBadChoice, setAlertBadChoice] = useState(false);
    const CptsQuery = useGetCompteurTempsDateDate(From.toString().slice(0,10), To.toString().slice(0,10), usersConc.map(u=>u.id), supervisor ? centre : null);
    useEffect(()=>{
        setAlertBadChoice(false);
        if(users){
            if(TabCollab){
                setUsersConc(users.filter(u=>TabCollab.indexOf(u.id.toString())!==-1));
            }else if(TabFx){
                if(TabFx.length === 1 && TabFx[0] === "1"){
                    setUsersConc([]);
                    setAlertBadChoice(true);
                } else {
                    setUsersConc(users.filter(u=>TabFx.indexOf(u.Fonction.id.toString())!==-1));
                }
            } else {
                setUsersConc(users);
            }
        }
    }, [users, TabCollab, TabFx])
    const lineFiltered = useMemo(()=>{
        const list:any[] = []
        if(usersConc && CptsQuery.data){
            usersConc.map(user=>{
                const myCpt = CptsQuery.data.find(c=>c.User.id === user.id);
                if(myCpt && myCpt.heuresAFaire>0){
                    list.push({
                        id:user.id,
                        avatar: <TimedRoundAvatar src={user.avatarUrl !== undefined ? user.avatarUrl : ''} size={"sm"}/>,
                        fullName: <strong>{user.prenom + ' ' + user.nom}</strong>,
                        fullNameLib: user.prenom + ' ' + user.nom,
                        inits: user.initials,
                        heuresCt:Math.round(myCpt.heuresAFaire*100)/100,
                        heuresDid:Math.round(myCpt.heuresFaitesRelevees*100)/100,
                        heuresNoWork:Math.round(myCpt.heuresNoWRelevees*100)/100,
                        heuresRegules:Math.round(myCpt.regules*100)/100,
                        heuresTotales:Math.round((myCpt.heuresFaitesRelevees+myCpt.heuresNoWRelevees+myCpt.regules)*100)/100,
                        ecart:Math.round(((myCpt.heuresFaitesRelevees+myCpt.heuresNoWRelevees+myCpt.regules) - myCpt.heuresAFaire)*100)/100,
                        action:<div>XX</div>,
                        actNb:1
                    })
                }
                return user;
            })
        }
        return list;
    }, [usersConc, CptsQuery.data])
    return (
        <div className={`one_w_h_s ${className}`}>
            <div className={`line_titre`} onClick={()=>setOpen(o=>!o)}>
                <div className={`numSem`}>S{getNumWeek(From)} du {From.toLocaleDateString()} au {To.toLocaleDateString()}</div>
                <div className={`switch_chevron`}>
                    <div className={`wrap-chevron ${open ? 'open' : 'close'}`}>
                        <BsChevronUp/>
                    </div>
                </div>
            </div>
            <div className={'body-hs'} aria-expanded={!open}>
                {(loadUsers || CptsQuery.isLoading) ?
                    <TimedSkeletonTable nbColumns={6} nbLines={5} /> :
                    <>
                        <div className={"wrap-tab"}>
                            <TimedTable
                                Titles={TitlesTab}
                                sortable={true}
                                themeColor={"Primary"}
                                Outline={true}
                                Lines={lineFiltered ? lineFiltered : []}
                                scrollable={true}
                                loading={false}
                                maxHeight={"450px"}
                                onClickLine={()=>{}}
                            />
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export const OneWeekGestionHS = styled(OneWeekGestionHSCtrl)`
  margin-bottom: 10px;
  .wrap-tab{
    height: 450px;
    overflow: hidden;
  }
  .line_titre{
    display: flex;
    justify-content: flex-start;
    gap: 5px;
    align-items: center;
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: ${props => props.theme.PrimaryExtraLight};
    padding: 0.5rem;
    font-size: 14px;
    font-weight: bold;
    &:hover{
      cursor: pointer;
    }
    .SlideDown{

    }
    .wrap-chevron{
      transform: rotate(0deg);
      transition: transform 0.3s;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &.close{
        transform: rotate(180deg);
        transition: transform 0.3s;
      }
    }
  }
  
  .body-hs[aria-expanded="true"] {
    max-height: 0;
    padding: 0;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  }
  .body-hs{
    max-height: 1000px;
    overflow: hidden;
    transition: max-height 1s ease-in-out;
    position: relative;
    background: white;
  }
`
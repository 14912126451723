import {dataFetcher} from "../../services/dataGuetter/dataFetcher";


export async function getPointeuseByParams(params:any|null):Promise<Pointeuse[]>{
    let TabSearch:string[] = [];
    if(params) {
        for (const [key, value] of Object.entries(params)){
            TabSearch.push(`${key}=${value}`)
        }
    }
    const search = TabSearch.length>0 ? `${TabSearch.join('&')}&pagination=false` : 'pagination=false'
    const res:any = await dataFetcher(`pointeuses?${search}`, {method:'get'})
    return res["hydra:member"];
}


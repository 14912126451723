import styled from "styled-components";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {OneTime} from "./components/OneTime";
import {useSearchParams} from "react-router-dom";
import {ConvertBase5, getWeekByDate} from "../../../../components/functions/TimesFunctions";
import {OneHeadDate} from "./components/OneHeadDate";
import {useGetOneUser, useGetUser} from "../../../../features/user/user.hooks";
import {useGetPlageWeekOne, useModifyPlage} from "../../../../features/plage/plage.hooks";
import {OneColumnDay} from "./components/OneColumnDay";
import {useGetParamsTimed} from "../../../../hooks/useGetParamsTimed";

interface TestPageCtrlProps{
    className?:string;
}

const hdep = 5;
const hfin = 23;
const heightHour = 40;
const heightWrap = heightHour * ((hfin - hdep)+1);
const WidthHours = "30px";

const VISIBLE =1;
const HIDDEN =2;
const ENTERING =3;
const LEAVING =4;


const TestPageCtrl = ({className}:TestPageCtrlProps)=>{
    const Today = new Date(new Date().getTime() - new Date().getTimezoneOffset()*60000);
    const [searchParams] = useSearchParams();
    const {TabCollab} = useGetParamsTimed();
    const refZD = useRef<HTMLDivElement>(null);
    const [OnDrop, setOnDrop] = useState(false);
    const [state, setState] = useState(OnDrop ? HIDDEN : VISIBLE)
    const [posShadow, setPosShadow] = useState<{x:number, y:number}>({x:0, y:0})
    const [posShadow2, setPosShadow2] = useState<{x:number, y:number, yBottom:number}>({x:0, y:0, yBottom:0})
    const [posDep, setPosDep] = useState<{x:number, y:number, yBottom:number}>({x:0, y:50, yBottom:0})
    const [datePick, setDatePick] = useState<Date>(Today)
    const [idUser, setIdUser] = useState(0);
    const UserConnectedQuery = useGetUser();
    const UserQuery = useGetOneUser(idUser);
    const {isLoading, isError, data:plages} = useGetPlageWeekOne(datePick.toISOString().slice(0,10), idUser);
    const [myPlages, setMyPlages] = useState<Plage[]>([])
    const [tabDates, setTabDates] = useState<Date[]>([]);
    const {Monday, Sunday} = getWeekByDate(datePick)
    const [tabColumns, setTabColumns] = useState<React.ReactNode[]>([]);
    const refCol1 = useRef<HTMLDivElement>(null)
    const refCol2 = useRef<HTMLDivElement>(null)
    const refCol3 = useRef<HTMLDivElement>(null)
    const refCol4 = useRef<HTMLDivElement>(null)
    const refCol5 = useRef<HTMLDivElement>(null)
    const refCol6 = useRef<HTMLDivElement>(null)
    const refCol7 = useRef<HTMLDivElement>(null)
    const [plageCurrent, setPlageCurrent] = useState<Plage|null>(null)
    const [posYDown, setPosYDown] = useState<number>(0);
    const [posYDown2, setPosYDown2] = useState<number>(0);
    const [posXDown, setPosXDown] = useState<number>(0);
    const [posXDown2, setPosXDown2] = useState<number>(0);
    const [forMoving, setForMoving] = useState(false);
    const [needSave, setNeedSave] = useState(false);
    const mutation = useModifyPlage();
    const VerifHorse = useCallback((myPlage:Plage)=>{
        if(plages){
            return plages.filter(p=>new Date(p.plageAt).toISOString() === new Date(myPlage.plageAt).toISOString()).filter(p=>{
                return !(p.Statut.id === 5 || p.id === myPlage.id || p.heureFin <= myPlage.heureDebut || p.heureDebut >= myPlage.heureDebut);
            });
        } else {
            return [];
        }
    }, [plages]);
    const resetPlage = useCallback((id:number)=>{
        if(plages) {
            const plageOrigine = plages.find(p => p.id === id);
            if (plageOrigine) {
                setMyPlages(pls => pls.map(p=>p.id === id ? plageOrigine : p))
            }
        }
    }, [plages])
    useEffect(()=>{
        if(plageCurrent && needSave){
            const PlagesHorsing:Plage[] = plageCurrent.Statut.id === 5 ? [] : VerifHorse(plageCurrent);
            if(PlagesHorsing.length>0){

            }
        }
        // if(VerifHorse(new Date(plage.plageAt), heureDepart, heurefin, plage.id) && plage.Statut.id !== 5){
        //     toggle();
        //     ResetPos();
        // } else {
        //     setIsSubmitting(true);
        //     const datas: ModifyPlageFormData = {
        //         id: plage.id,
        //         heureDebut: heureDepart,
        //         heureFin: heurefin,
        //         plageAt: plage.plageAt,
        //         Lieu: '/api/lieus/' + plage.Lieu.id,
        //         Acte: '/api/actes/' + plage.Acte.id,
        //         Statut: '/api/statut_plages/' + plage.Statut.id,
        //         Memo: plage.Memo ? plage.Memo : '',
        //         jPlusUn:heurefin<heureDepart,
        //     }
        //     mutation.mutate((datas), {
        //         onSuccess: () => {
        //             setIsSubmitting(false);
        //             setNeedSave(false)
        //         },
        //         onError:()=>{
        //             ResetPos();
        //             setIsSubmitting(false);
        //             setNeedSave(false)
        //         }
        //     });
        // }
    }, [plageCurrent, needSave])
    let hours:React.ReactNode[] = [];
    let startH = hdep;
    useEffect(()=>{
        if(plages) {
            setMyPlages(plages)
        }
    }, [plages])
    useEffect(()=>{
        if(refZD.current){
            console.log(posShadow2);
            const wRef = refZD.current.getBoundingClientRect().width;
            const WDay = wRef/7;
            const Left = posShadow2.x;
            const decalageJ = Math.floor(Left/WDay);
            const GoodDay = tabDates[decalageJ];
            const Top = posShadow2.y;
            const InHour = Top/heightHour + hdep;
            const myHD = InHour*12;
            const decalageEnPX = posShadow2.yBottom - posShadow2.y;
            const decalageEnBase5 = myHD + decalageEnPX/heightHour*12;
            setPlageCurrent((pg)=> {
                return pg ? {...pg, heureDebut: InHour*12, heureFin:decalageEnBase5, plageAt:GoodDay ? GoodDay.toISOString().slice(0,10) : pg.plageAt} : null;
            })
        }
    }, [posShadow2, refZD, tabDates])
    useEffect(()=>{
        if(!OnDrop){
            setState(LEAVING)
        } else{
            setState((s)=>s===HIDDEN ? ENTERING : LEAVING)
        }
    }, [OnDrop])
    useEffect(()=>{
        if(state === LEAVING){
            if(posYDown === posYDown2 && posXDown2 === posXDown){
                alert('click')
            } else {
                if(plageCurrent) {
                    setMyPlages(plages => plages.map(p => p.id === plageCurrent?.id ? plageCurrent : p))
                }
            }
            setState(HIDDEN)
        }else if(state === ENTERING){
            setState(VISIBLE);
        }
    }, [state, plageCurrent, posXDown2, posYDown2, posYDown, posYDown])
    const getTop = (p:Plage)=>{
        const heureDebut = p.heureDebut;
        const HdepBase5 = hdep*12;
        const diff = heureDebut-HdepBase5;
        return (diff/12)*heightHour;
    }
    const getTopBottom = (p:Plage)=>{
        const top = getTop(p);
        const heureFin = p.heureFin;
        const heureDebut = p.heureDebut;
        const dureeBase5 = heureFin - heureDebut;
        const dureeHeure = dureeBase5/12;
        const height = dureeHeure*heightHour;
        return top+height;
    }
    const setIsCurrent = (e:any, plage:Plage, id:string)=>{
        if(refZD.current) {
            const rect = refZD.current.getBoundingClientRect();
            const YPos = rect.y;
            const TopPlage = getTop(plage);
            const BottomPlage = getTopBottom(plage);
            const limit = 20;
            const RealLimit = YPos+BottomPlage-limit;
            setForMoving(e.clientY > RealLimit)
            setPlageCurrent(plage);
            setPosYDown(e.clientY);
            setPosYDown2(e.clientY);
            setPosXDown2(e.clientX);
            setPosXDown(e.clientX);
            makeOnDrop(e.clientX, getTop(plage), id, plage);
        }
    }
    useEffect(()=>{
        if(!!TabCollab){
            setIdUser(parseInt(TabCollab[0]));
        } else {
            if(UserConnectedQuery.data){
                setIdUser(UserConnectedQuery.data.id);
            }
        }
    }, [TabCollab, UserConnectedQuery.data])
    useEffect(()=>{
        const TabRef = [refCol1, refCol2, refCol3, refCol4, refCol5, refCol6, refCol7]
        const TabReturn:React.ReactNode[] = [];
        if(tabDates.length>0 && myPlages.length>0){
            for(let idx=0; idx<=tabDates.length-1; idx++){
                TabReturn.push(<OneColumnDay setIsCurrent={setIsCurrent} key={`colD${idx}`} Plages={myPlages} myDate={tabDates[idx]} ref={TabRef[idx]} hdep={hdep} hfin={hfin} heightHour={heightHour}/>)
            }
        }
        setTabColumns(TabReturn);
    }, [tabDates, myPlages])
    while(startH<=hfin){
        const myStartH = startH;
        hours.push(<OneTime key={`one_hour${myStartH}`} height={heightHour} myHour={myStartH+1} Wid={WidthHours}/>)
        startH++;
    }
    useEffect(()=>{
        const {Monday, Sunday} = getWeekByDate(datePick)
        let curr = Monday;
        const TabDateReturn:Date[] = [];
        while(curr<=Sunday){
            const myDate = curr;
            TabDateReturn.push(myDate);
            curr = new Date(curr.getTime() + 86400_000);
        }
        setTabDates(TabDateReturn);
    }, [datePick])
    useEffect(()=>{
        const TabParams = [...Array.from(searchParams)];
        const TabFrom = TabParams.find(tab=>tab[0]==='from');
        const Today = new Date();
        if(!TabFrom){
            setDatePick(Today);
        } else {
            const TabDat = TabFrom[1].split('/');
            const dateFrom = new Date(`${TabDat[2]}-${TabDat[1]}-${TabDat[0]}`)
            setDatePick(dateFrom);
        }
    }, [searchParams])
    const makeOnDrop = (coordX:number, coordY:number,idCurr:string, plage:Plage)=>{
        if(refZD.current && idCurr!==''){
            const myCurr = document.getElementById(idCurr);
            const rectZD = refZD.current.getBoundingClientRect();
            const rectCurr = myCurr ? myCurr.getBoundingClientRect() : null;
            if(rectCurr) {
                setPosDep({
                    x: coordX,
                    y: coordY,
                    yBottom:plage ? getTopBottom(plage) : 0
                })
                setPosShadow2({
                    x: (rectCurr.x-1) - rectZD.x,
                    y: (rectCurr.y-1) - rectZD.y,
                    yBottom:plage ? getTopBottom(plage) : 0
                })
            }

            setOnDrop(true);
        }
    }
    useEffect(()=>{
        document.addEventListener('mouseup', ()=>{
            setOnDrop(false);
        })
        return ()=>{
            document.removeEventListener('mouseup', ()=>{setOnDrop(false)})
        }
    }, [])
    useEffect(()=>{
        const handleMove = (e:any)=>{
            const posX = e.clientX
            setPosXDown2(posX);
            setPosYDown2(e.clientY);
            const coef = 10;
            const rectZD = refZD.current ? refZD.current.getBoundingClientRect() : null;
            const coordZD = rectZD ? {x:rectZD.x, y:rectZD.y} : {x:-1000, y:-1000}
            const PosWhenClick = posYDown - coordZD.y;
            const posRelativeY = e.clientY - coordZD.y;
            const posPlageY = posDep.y;
            const Deplacement = posRelativeY - PosWhenClick;
            let diffY = 0;
            let diffYBottom=0;
            if(!forMoving){
                diffY = Math.floor(Deplacement/coef)*coef;
                diffYBottom = Math.floor(Deplacement/coef)*coef;
            } else {
                diffYBottom = Math.floor(Deplacement/coef)*coef;
            }
            const posY =  posPlageY + diffY;
            const PosYB = posDep.yBottom+diffYBottom;
            const coord1 = refCol1.current ? {x:refCol1.current.getBoundingClientRect().x, y:refCol1.current.getBoundingClientRect().y} : {x:-1000, y:-1000}
            const coord2 = refCol2.current ? {x:refCol2.current.getBoundingClientRect().x, y:refCol2.current.getBoundingClientRect().y} : {x:-1000, y:-1000}
            const coord3 = refCol3.current ? {x:refCol3.current.getBoundingClientRect().x, y:refCol3.current.getBoundingClientRect().y} : {x:-1000, y:-1000}
            const coord4 = refCol4.current ? {x:refCol4.current.getBoundingClientRect().x, y:refCol4.current.getBoundingClientRect().y} : {x:-1000, y:-1000}
            const coord5 = refCol5.current ? {x:refCol5.current.getBoundingClientRect().x, y:refCol5.current.getBoundingClientRect().y} : {x:-1000, y:-1000}
            const coord6 = refCol6.current ? {x:refCol6.current.getBoundingClientRect().x, y:refCol6.current.getBoundingClientRect().y} : {x:-1000, y:-1000}
            const coord7 = refCol7.current ? {x:refCol7.current.getBoundingClientRect().x, y:refCol7.current.getBoundingClientRect().y} : {x:-1000, y:-1000}
            const TabCoord = [coord7, coord6, coord5, coord4, coord3, coord3, coord2, coord1]
            let coordFinal = {x:0, y:posY, yBottom:PosYB};
            for(let i=0; i<=TabCoord.length-1; i++)
            {
                const c = TabCoord[i];
                if(posX>c.x){
                    coordFinal = {...coordFinal, x:c.x - coordZD.x}
                    break;
                }
            }
            setPosShadow2(coordFinal);

            setPosShadow({x:posX, y:posY})
        }
        if(OnDrop) {
            window.addEventListener('mousemove', handleMove)
        } else {
            window.removeEventListener('mousemove', handleMove)
        }
        return ()=>{
            window.removeEventListener('mousemove', handleMove)
        }


    }, [OnDrop, refCol1, refCol2, refCol3, refCol4, refZD, posDep, posShadow2, posShadow, posYDown, forMoving])
    const getStyleShadow = ()=>{
        if(!plageCurrent){
            return {width:"100%", display:OnDrop ? "block" : "none"}
        } else {
            console.log('iiii');
            console.log(posShadow2);
            const heureFin = plageCurrent.heureFin;
            const heureDebut = plageCurrent.heureDebut;
            const dureeBase5 = heureFin - heureDebut;
            const dureeHeure = dureeBase5/12;
            const height = posShadow2.yBottom - posShadow2.y;
            const myBackground = plageCurrent.Lieu.backColor;
            const myFontColor = plageCurrent.Lieu.fontColor;
            return {left:posShadow2.x+"px", top:posShadow2.y+"px", background:myBackground, color:myFontColor, zIndex:5, display:OnDrop ? "block" : "none", height:height+"px" }
        }
    }
    return(
        <div className={`test_page ${className}`}>
            <div className={"infos"}>
                <div className={`Drop`}>
                    {OnDrop ? "onDrop" : "noOnDrop"}
                </div>
                <div>
                    State : {state}
                </div>
                <div>
                    xDep : {posDep.x}, yDep:{posDep.y}
                </div>
                <div>
                    x : {posShadow.x}, y:{posShadow.y}
                </div>
                <div>{Monday.toLocaleDateString()} {Sunday.toLocaleDateString()}</div>
            </div>
            <div className={"wrap_calendar"}>
                <div className={`line_dates`}>
                    <div className={`space_hours`}/>
                    <div className={`wrapper_dates`}>
                        {tabDates.map((item:Date, indx:number)=>(
                            <OneHeadDate myDate={item} key={`one_date${indx}`}/>
                        ))}
                    </div>
                </div>
                {isLoading ? 'chargement' :
                    <div className={`wrap_zone`}>
                        <div className={`wrap_hours`}>
                            {hours.map(item=>(
                                item
                            ))}
                        </div>
                        <div className={"zone_dropping"} ref={refZD}>
                            <div className={`toMove`} style={getStyleShadow()}>
                                <div className={`Lieu_move`}>{plageCurrent ? plageCurrent.Lieu.libelle : ''} - {plageCurrent ? plageCurrent.Acte.libelle : ''}</div>
                                <div className={`horaires`}>
                                    {plageCurrent ? ConvertBase5(plageCurrent.heureDebut) : ''} - {plageCurrent ? ConvertBase5(plageCurrent.heureFin) : ''}
                                </div>
                            </div>
                            {tabColumns.map((item)=>(item))}
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

const TestPage = styled(TestPageCtrl)`
  width: 100%;
  height: 100%;
  .wrap_hours{
    padding-top: 1px;
  }
  .infos{
    display: flex;
    justify-content: flex-start;
    gap: 10px;
  }
  .toMove{
    position: absolute;
    width: 13%;
    padding: 0.25rem;
    height: 40px;
    background: black;
    z-index: 5;
    &:hover{
      cursor: grab;
    }
  }
  .line_dates{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    .space_hours{
      width: ${WidthHours};
    }
    .wrapper_dates{
      flex-grow: 1;
      display: flex;
      justify-content: flex-start;
    }
  }
  .ColA{
    width: 14.28571%;
    height: 100%;
    position: relative;
  }
  .wrap_calendar{
    width: 95%;
    margin: auto;
    background: white;
  }
  .wrap_zone{
    width: 100%;
    height: ${`${heightWrap}px`};
    overflow-y: scroll;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
  }
    .zone_dropping{
      flex-grow: 1;
      position: relative;
      border:solid black 1px;
      height:  ${`${heightWrap}px`};
      display: flex;
      justify-content: flex-start;
    }
`

export default TestPage;
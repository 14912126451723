import styled from "styled-components";
import {TColumn, TypeDrop} from "../PlanningGlobalLieu/PlanningGlobalLieu3";

interface CellSegmentationCtrlProps{
    className?:string;
    segmentation:string;
    TabCol:TypeDrop[];
    datePick:Date;
    setPlages:(Plages:Plage[])=>void;
    Plages:Plage[];
    supervisor?:boolean;
}

const CellSegmentationCtrl = (props:CellSegmentationCtrlProps)=>{
    return (
        <div className={`cell_segmentation ${props.className}`}>
            {props.segmentation}
        </div>
    )
}

export const CellSegmentation = styled(CellSegmentationCtrl)`
    width: 50px;
  overflow: hidden;
  padding: 0.35rem;
`
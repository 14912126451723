import {dataFetcher} from "../../services/dataGuetter/dataFetcher";

/**
 * @summary Creation d'un user
 * @param datas
 */
export function createUser(datas:userNewFormData):Promise<User>{
    return dataFetcher(`users`, {
        method:'POST',
        body:datas
    })
}

export function getInfosUserMob():Promise<MeSimply>{
    return dataFetcher(`users/memob?gl=1`, {method:'get'})
}

/**
 * Permet de se connecter via l'adresse mail et le mot de passe, retourne un objet content le jwt.
 * @param username
 * @param password
 */
export function login(username: string, password: string) {
    return dataFetcher("login", {
        method: "POST",
        body: {
            username: username,
            password: password,
            come:'admin'
        },
    });
}



/**
 * Permet de récupérer les informations de l'utilisateur
 */
export function getInfosUser():Promise<UserSimple>{
    return dataFetcher(`users/me`, {
        method:'get',
    })
}

/**
 * Permet de récupére un utilisateur
 * @param id
 */
export function getInfosOneUser(id:number):Promise<User>{
    return dataFetcher(`users/${id}`, {method:'get'})
}

export function getUserByUserName():Promise<User>{
    return dataFetcher(`users/meReal`, {method:'get'})
}

export function postWMSub(datas:WMsubFormData):Promise<WebMasterSubstitutes>{
    return dataFetcher(`web_master_substitutes`, {
        method:"post",
        body:datas
    })
}

export function CancelSub():Promise<WebMasterSubstitutes>{
    return dataFetcher(`web_master_substitutes/cancel`, {
        method:"get",
    })
}



export function AddEvts(datas:UserEvtAddFormData):Promise<EventCollab>{
    const formData = new FormData();
    if(!!datas.file){
        formData.append('file', datas.file);
        if(!!datas.TypeDocument) {
            formData.append('TypeDocument', datas.TypeDocument.toString());
        }
        if(!!datas.name) {
            formData.append('name', datas.name);
        }
        if(!!datas.isDiffusable) {
            formData.append('isDiffusable', datas.isDiffusable.toString());
        }
    }
    formData.append('eventAt', datas.eventAt);
    formData.append('type', datas.type.toString());
    formData.append('details', datas.details);
    return dataFetcher<EventCollab>(
        `users/${datas.id}/addEvt`,
        {
            method: "POST",
            body: formData,
        },
        true
    );
}

export function changeLogo(fileToPost: File, id: number | undefined): Promise<User> {
    const formData = new FormData();
    formData.append("file", fileToPost);
    return dataFetcher<User>(
        `users/${id}/postAvatar`,
        {
            method: "POST",
            body: formData,
        },
        true
    );
}

/**
 * Permet de récupérer tous les users
 */
export async function getAllUser(idCentre:number|null):Promise<User[]>{
    let url = `users`;
    if(idCentre){
        url = `users?Centre=${idCentre}`
    }
    const res:any = await dataFetcher(url, {method:'get'});
    return res["hydra:member"];
}

/**
 * Permet de récupérer tous les usersTousCentre
 */
export async function getAllUserFull():Promise<User[]>{
    const res:any = await dataFetcher(`users?Centre=-1`, {method:'get'});
    return res["hydra:member"];
}

export async function getUsersByCentre(idCentre:number, signal:any):Promise<User[]>{
    const res:any = await dataFetcher(`users?Centre=${idCentre}`, {method:'get', signal});
    return res["hydra:member"];
}

/**
 * Permet de récupérer les users par fonctions
 * @param list
 */
export async function getUserByFonctions(list:string[], idCentre:number|null):Promise<User[]>{
    const TabFonction = list.map(item=>`Fonction[]=${parseInt(item)}`);
    let params = TabFonction.join('&');
    if(idCentre){
        params+=`&Centre=${idCentre}`
    }
    const res:any = await dataFetcher(`users?${params}`, {method:'get'});
    return res["hydra:member"];
}

export async function getUserByFonctionFAdm(idFx:number, idFxAdm:number, idCentre:number|null):Promise<User[]>{
    let params = `Fonction=${idFx}&FonctionAdm=${idFxAdm}`
    if(idCentre){
        params+=`&Centre=${idCentre}`
    }
    const res:any = await dataFetcher(`users?${params}`, {method:'get'})
    return res["hydra:member"];
}

/**
 * Permet de récupérer plusieurs utilisateurs par id
 * @param list
 */
export async function getUserByMultiID(list:string[]):Promise<User[]>{
    const TabIDS = list.map(item=>`id[]=${parseInt(item)}`);
    const res:any = await dataFetcher(`users?${TabIDS.join('&')}`, {method:'get'});
    return res["hydra:member"];
}

/**
 * Permet de mettre à jour les informations personnelles
 * @param datas
 */
export function updateInfosPerso(datas:UserInfoPersoFormData):Promise<User>{
    return dataFetcher(`users/${datas.id}`, {
        method:'put',
        body:datas
    })
}

export function updateCentreUC(idUser:number, id:number):Promise<User>{
    const datas = {Centre:`/api/centres/${id}`}
    return dataFetcher(`users/${idUser}`, {
        method:'put',
        body:datas
    })
}

/**
 * Permet de mettre à jour les informations professionnelles
 * @param datas
 */
export function updateInfosPro(datas:UserInfosProfessionnellesFormData):Promise<User>{
    return dataFetcher(`users/${datas.id}`, {
        method:'put',
        body:datas
    })
}

export function updateJoursTrav(id:number, datas:JoursTravail):Promise<User>{
    return dataFetcher(`users/${id}`, {
        method:'put',
        body: {joursTravail:datas}
    })
}

export function getInfosPlanning(id:number):Promise<Week>{
    return dataFetcher(`users/${id}/infosPlanning`, {method:'get'})
}

export async function getContrats(id:number, idCentre:number|null):Promise<Contrat[]>{
    let parms = `User=${id}`
    if(idCentre){
        parms+=`&Centre=${idCentre}`
    }
    const res:any = await dataFetcher(`contrats?${parms}`)
    return res["hydra:member"];
}

export async function getPlageSemCurr(id:number):Promise<Plage[]>{
    const Today = new Date();
    const jToday = Today.getDay();
    const jReste = 8-jToday;
    const LastSunday = new Date(Today.getTime() - (86400000*jToday));
    const NextMonday = new Date(Today.getTime() + (86400000*jReste));
    const LastSundayStr = `${LastSunday.getFullYear()}-${LastSunday.getMonth()+1 <10 ? `0${LastSunday.getMonth()+1}` : LastSunday.getMonth()+1}-${LastSunday.getDate()}`;
    const NextMondayStr = `${NextMonday.getFullYear()}-${LastSunday.getMonth()+1 <10 ? `0${NextMonday.getMonth()+1}` : NextMonday.getMonth()+1}-${NextMonday.getDate()}`;
    const res:any = await dataFetcher(`plages?User=${id}&plageAt[strictly_before]=${NextMondayStr}&plageAt[strictly_after]=${LastSundayStr}`)
    return res["hydra:member"];
}

export function saveConfigPl(datas:UserConfigFormData):Promise<User>{
    return dataFetcher(`users/${datas.id}/save_config_planning`, {
        method:'post',
        body:{config:datas.config}
    })
}

export function saveConfigPlCGL(datas:UserConfigFormData):Promise<User>{
    return dataFetcher(`users/${datas.id}/save_config_planning`, {
        method:'post',
        body:{config:datas.config, CGL_only:1}
    })
}

export function getUserReplace(id:number, idCentre:number|null):Promise<UserDispo[]>{
    let parms = `Plage=${id}`
    if(idCentre){
        parms+=`&Centre=${idCentre}`
    }
    return dataFetcher(`users/who_replace?${parms}`, {method:'get'})
}

export function sendIdentity(id:number, isWM:boolean=false):Promise<User>{
    return dataFetcher(`users/${id}/send_identity?isWM=${isWM}`, {method:'get'})
}

export function saveOptin(id:number):Promise<UserSimple>{
    return dataFetcher(`users/${id}`, {
        method:'put',
        body:{optIn:true}
    })
}


export function cloturePeriodeAbs(dispo:number, idperiode:number, iduser:number):Promise<string>{
    return dataFetcher(`users/${iduser}/cloture_periode_cp`, {
        methode:'post',
        body:{dispo:dispo, idperiode:idperiode}
    })
}

export function cancelCloturePeriodeAbs(idperiode:number, iduser:number):Promise<string>{
    return dataFetcher(`users/${iduser}/cancel_cloture_periode_cp`, {
        methode:'post',
        body:{idperiode:idperiode}
    })
}



import {createGlobalStyle} from "styled-components";


const GlobalStyle =createGlobalStyle((props) => ({
    "*": {
        margin: "0px",
        padding: "0px",
        outline: "0px",
        boxSizing: "border-box",
        fontFamily: "'Roboto', sans-serif",

        //Scrollbar Custom pour Chrome et Safari :
        "&::-webkit-scrollbar": {
            width: "3px",
        },

        "&::-webkit-scrollbar-track": {
            background: "transparent",
        },

        "&::-webkit-scrollbar-thumb": {
            background: props.theme.NeutreLight,
            borderRadius: "3px",
        },

        "&::-webkit-scrollbar-thumb:hover": {
            background: props.theme.Neutre,
        },
        "&::-webkit-scrollbar-thumb:active": {
            background: props.theme.Neutre,
        },

        //Scrollbar Custom pour Mozilla :
        scrollbarColor: props.theme.NeutreLight + " " + props.theme.Neutre,
    },
    body:{
        background: props.theme.BodyBackground,
        color:props.theme.colorDark,
    },
    "#root": {
        margin: "0 auto",
    },
}));

export default GlobalStyle;
import styled, {useTheme} from "styled-components";
import {TitrePage} from "../../../../assets/styles/elements";
import {useGetParamsTimed} from "../../../../hooks/useGetParamsTimed";
import {getWeekByTwoDate, getWeeksByDate} from "../../../../components/functions/TimesFunctions";
import TimedAlertChoice from "../../../../components/atoms/TimedAlerteChoice/TimedAlertChoice";
import React from "react";
import {OneWeekGestionHS} from "./OneWeekGestionHS";

interface GestionHeuresSuppCtrlProps{
    className?:string;
    supervisor?:boolean;
}



const GestionHeuresSuppCtrl = ({className, supervisor}:GestionHeuresSuppCtrlProps)=>{
    const {To, From} = useGetParamsTimed();
    const theme= useTheme();
    return (
        <div className={`gestion_heures_supp ${className}`}>
            <TitrePage>Gestion Heures Supplémentaires</TitrePage>
            <div className={`content_page`}>
                {(!From || !To) ?
                    <TimedAlertChoice>
                        Depuis la smartNav,  <span className={"hightlight"} style={{color:theme.Error , fontWeight:"bold"}}>Définissez la période</span> sur laquelle vous souhaitez gérer les heures supplémentaires
                    </TimedAlertChoice>:
                    <>
                    {getWeekByTwoDate(From, To).map((i, idx)=>(
                        <OneWeekGestionHS key={`Ow${idx}`} From={i.Monday} To={i.Sunday} supervisor={supervisor}/>
                    ))}
                    </>
                }
            </div>
        </div>
    )
}

const GestionHeuresSupp = styled(GestionHeuresSuppCtrl)`
    padding: 2rem;
`

export default GestionHeuresSupp;
import styled from "styled-components";
import {useEffect, useState} from "react";
import {TableDays} from "../../../../../services/constantes/constantesTs";

interface OneHeadDateCtrlProps{
    className?:string;
    myDate:Date;
}

const OneHeadDateCtrl = ({className, myDate}:OneHeadDateCtrlProps)=>{
    const [libDay, setLibDay] = useState('')
    useEffect(()=>{
        const myDay = TableDays.find(d=>d.numDay === myDate.getDay())
        setLibDay(myDay ? myDay.libelle : 'nc')
    }, [])
    return (
        <div className={`one_head_date ${className}`}>
            <span>{libDay}</span>
            <span>{myDate.toLocaleDateString()}</span>
        </div>
    )
}

export const OneHeadDate = styled(OneHeadDateCtrl)`
    width: 14.2857%;
    span{
      display: block;
      text-align: center;
    }
`
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {useGetParamsTimed} from "../../../../../hooks/useGetParamsTimed";
import {useGetAllPeriodes} from "../../../../../features/periode/periode.hooks";
import {useGetAllUser} from "../../../../../features/user/user.hooks";
import {TabMois} from "../../../../../services/constantes/constantes";
import TimedChips from "../../../../../components/atoms/TimedChips/TimedChips";
import TimedSkeletonListChips from "../../../../../components/atoms/TimedSkeleton/TimedSkeletonListChips";
import TableTempsTravailleMulti from "./TableTempsTravailleMulti";
import TimedAlertChoice from "../../../../../components/atoms/TimedAlerteChoice/TimedAlertChoice";
import TimedButton from "../../../../../components/atoms/TimedButton/TimedButton";
import {useNavigate} from "react-router-dom";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import HeadPage from "../../../../../components/organisms/layouts/HeadPage";
import TimedIconButton from "../../../../../components/atoms/TimedIconButton/TimedIconButton";
import {AiOutlineFileExcel} from "react-icons/ai";
import {useGetCompteurTempsDateDate} from "../../../../../features/compteurTempsDateDate/compteurTempsDateDate.hooks";
import {useCentreConnectedData, useUserConnected} from "../../../../../services/hooks";
import {useGetOneCentre} from "../../../../../features/centre/centre.hooks";

interface TempsTravailleMultiCtrlProps{
    className?:string;
    supervisor?:boolean;
}
interface OneChoiceMonth{
    id:number,
    dateStart:Date,
    dateEnd:Date,
    libelle:string;
}

const TempsTravailleMultiCtrl = ({className, supervisor}:TempsTravailleMultiCtrlProps)=>{
    const centre = useCentreConnectedData();
    console.log(centre);
    const navigate = useNavigate();
    const Test = false;
    const {TabCollab, TabFx} = useGetParamsTimed();
    const {isLoading:loadUsers, data:users} = useGetAllUser();
    const {isLoading, data:periodes} = useGetAllPeriodes(supervisor ? centre : null);
    const [periodeCurr, setPeriodeCurr] = useState<Periode|null>(null);
    const [choicesMois, setChoicesMois] = useState<OneChoiceMonth|null>(null)
    const [choicesPeriode, setChoicesPeriode] = useState<Choice[]>([])
    const [BasketMoisChoice, setBasketMoisChoice] = useState<OneChoiceMonth[]>([]);
    const [BasketPeriodes, setBasketPeriodes] = useState<Choice[]>([]);
    const [usersConc, setUsersConc] = useState<User[]>([]);
    const [alertBadChoice, setAlertBadChoice] = useState(false);
    const [csvData, setCsvData] = useState<any[]>([])
    const [dateStart, setDateStart] = useState<string>('')
    const [dateEnd, setDateEnd] = useState<string>('')
    const [isAnnualisation, setIsAnnualisation] = useState<boolean>(false);
    const CentreQuery = useGetOneCentre(centre||-1);
    const {userConnected} = useUserConnected();
    const CptsQuery = useGetCompteurTempsDateDate(dateStart, dateEnd, usersConc.map(u=>u.id), supervisor ? centre : null);
    useEffect(()=>{
        if(userConnected && !supervisor){
            const CentreParameters = userConnected.Centre.parameters;
            const isAnnualisation = CentreParameters.find(c=>c.Parameter.id === 6)
            if(isAnnualisation){
                setIsAnnualisation(isAnnualisation.statut)
            }
        } else if(CentreQuery.data) {
            const CentreParameters = CentreQuery.data.parameters;
            const isAnnualisation = CentreParameters.find(c=>c.Parameter.id === 6)
            if(isAnnualisation){
                setIsAnnualisation(isAnnualisation.statut)
            }
        }
    }, [userConnected, supervisor, CentreQuery.data])
    useEffect(()=>{
        if(choicesMois){
            const Today = new Date();
            Today.setHours(18,0);
            const monthChoix = choicesMois.dateStart.getMonth();
            let start = new Date(choicesMois.dateStart.getFullYear(), monthChoix, 1);
            start.setHours(18,0);
            const NextMonth = new Date(start.getTime() + 32*86400000);
            const monthNext = NextMonth.getMonth();
            const firstNext = new Date(NextMonth.getFullYear(), monthNext, 1);
            let end = new Date(firstNext.getTime() - 86400000);
            end.setHours(18,0);
            setDateStart(start.toISOString().slice(0,10));
            if(end<Today) {
                setDateEnd(end.toISOString().slice(0, 10));
            } else {
                setDateEnd(Today.toISOString().slice(0, 10));
            }

        }
    }, [choicesMois])
    useEffect(()=>{
        if(periodes){
            const Today = new Date();
            const PeriodesConcerned =periodes.filter(p=>p.Type.id === 2)
            if(PeriodesConcerned){
                const Periodes = PeriodesConcerned.filter(p=>new Date(p.debutAt.slice(0,10))<=Today && new Date(p.finAt.slice(0,10))>=Today)
                if(Periodes.length>0){
                    setChoicesPeriode([{id:Periodes[0].id, libelle:''}])
                    setPeriodeCurr(Periodes[0]);
                }
                setBasketPeriodes(PeriodesConcerned.map(p=>{
                    const start = new Date(p.debutAt.slice(0,10));
                    const end = new Date(p.finAt.slice(0,10));
                    return {id:p.id, libelle:`${start.getFullYear()} - ${end.getFullYear()}`}
                }))
            }
        }
    }, [periodes])
    useEffect(()=>{
        if(periodeCurr){
            const Today = new Date();
            const EndToday = new Date(Today.getFullYear(), Today.getMonth(), 1);
            EndToday.setHours(18,0);
            const start = new Date(periodeCurr.debutAt.slice(0,10));
            const end = new Date(periodeCurr.finAt.slice(0,10));
            let endFin = new Date(end.getFullYear(), end.getMonth(), 1);
            endFin.setHours(18,0);
            if(endFin>EndToday){
                endFin = EndToday;
            }
            let starty = new Date(start.getFullYear(), start.getMonth(), 1);
            let myChoices:OneChoiceMonth[] = [];
            let idx=0;
            while(starty <=endFin){
                const myStarty = starty
                const MonthLib = TabMois.find(m=>m.num === myStarty.getMonth()+1);
                const myDateStart = myStarty;
                const lib = !MonthLib ? 'nc' : `${MonthLib.name} ${myStarty.getFullYear().toString().slice(-2)}`
                const startyFake = new Date(myStarty.getTime()+32*86400000);
                starty = new Date(startyFake.getFullYear(), startyFake.getMonth(), 1);
                myChoices.push({
                    id:idx,
                    dateStart:myDateStart,
                    dateEnd:starty,
                    libelle:lib
                })
                idx++;
            }
            const MyFirstChoice = myChoices.find(c=>c.dateStart <=new Date() && c.dateEnd >=new Date());
            if(MyFirstChoice){
                setChoicesMois({id:MyFirstChoice.id,dateStart:MyFirstChoice.dateStart, dateEnd:MyFirstChoice.dateEnd, libelle:MyFirstChoice.libelle})
            }
            setBasketMoisChoice(myChoices);
        }
    }, [periodeCurr])
    useEffect(()=>{
        setAlertBadChoice(false);
        if(users){
            if(TabCollab){
                setUsersConc(users.filter(u=>TabCollab.indexOf(u.id.toString())!==-1));
            }else if(TabFx){
                if(TabFx.length === 1 && TabFx[0] === "1"){
                    setUsersConc([]);
                    setAlertBadChoice(true);
                } else {
                    setUsersConc(users.filter(u=>TabFx.indexOf(u.Fonction.id.toString())!==-1));
                }
            } else {
                setUsersConc(users);
            }
        }
    }, [users, TabCollab, TabFx])
    const setMonth = (ch:Choice)=>{
        const myBasketMoisChoice = BasketMoisChoice.find(o=>o.id === ch.id);
        if(myBasketMoisChoice){
            setChoicesMois(myBasketMoisChoice)
        }

    }
    const setPeriode = (ch:Choice)=>{
        setChoicesPeriode([ch])
        if(periodes){
            const MyPeriode = periodes.find(p=>p.id === ch.id);
            if(MyPeriode){
                setPeriodeCurr(MyPeriode);
            }
        }
    }
    const ClickRedirect = ()=>{
        navigate('../temps_medecin');
    }
    const exportToCSV = (csvData: Object[], fileName: string) => {
        const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });

        FileSaver.saveAs(data, fileName + fileExtension);
    };
    const handleClickExportExcel = ()=>{
        if(csvData.length >0){
            const MyMonth = choicesMois;
            let NameFile = 'ExportTps';
            if(MyMonth){
                const year = MyMonth.dateStart.getFullYear();
                NameFile = MyMonth?.libelle+" "+year;
            }

            exportToCSV(csvData, NameFile)
        }
    }
    const HandleSetCsvData = (data:any)=>{
        setCsvData(data);
    }
    return (
        <div className={`TpsTravailleMulti ${className}`}>
            <HeadPage titre={"Temps travaillés"} MargBottom={"12px"}>
                <TimedIconButton
                    size={"large"}
                    themeColor={"Success"}
                    Icon={<AiOutlineFileExcel/>}
                    toolTip={"Export Xls"}
                    onClick={handleClickExportExcel}
                    BackAlw={true}
                    isActive={true}
                    marginTop={"-0.1rem"}
                />
            </HeadPage>
            {isLoading || loadUsers || Test ?
                <TimedSkeletonListChips marginTop={"15px"} marginBottom={"15px"}/>:
                <>
                    <div className={`wrap-choice-periode`}>
                        {BasketPeriodes.map((item:Choice, idx:number)=>(
                            <TimedChips
                                key={`OnePChoice${idx}`}
                                setChoice={setPeriode}
                                MyChoice={item}
                                choices={choicesPeriode}
                                withOutChoche={true}
                            />
                        ))}
                    </div>
                    <div className={`wrap-choice-month`}>
                        {BasketMoisChoice.map((item:OneChoiceMonth, idx:number)=>(
                            <TimedChips
                                key={`OneMChoice${idx}`}
                                setChoice={setMonth}
                                MyChoice={{id:item.id, libelle:item.libelle}}
                                choices={[{id:choicesMois ? choicesMois.id : 0, libelle:choicesMois ? choicesMois.libelle : ''}]}
                                withOutChoche={true}
                            />
                        ))}
                    </div>
                </>
            }
            {alertBadChoice ?
                <TimedAlertChoice title={"Choix incohérent"}>
                    <p>Les médecins ne sont pas concernés par les temps de travail, vous pouvez consulter leurs temps demi jours ici</p>
                    <div className={`wrap-btn-redirect`}>
                        <TimedButton
                            themeColor={"Primary"}
                            onClick={ClickRedirect}
                            text={"Temps médecin"}
                        />
                    </div>
                </TimedAlertChoice>
                :
                choicesMois ?
                    <TableTempsTravailleMulti
                        CptQuery={CptsQuery}
                        dateStart={choicesMois.dateStart}
                        dateEnd={choicesMois.dateEnd}
                        setCsvData={HandleSetCsvData}
                        isAnnualisation={isAnnualisation}
                    /> : 'Manque choices mois'

            }
        </div>
    )
}

const TempsTravailleMulti = styled(TempsTravailleMultiCtrl)`
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  
    .wrap-choice-month, .wrap-choice-periode{
      margin: 0 auto 15px auto;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  .wrap-btn-redirect{
    padding: 0.5rem;
  }
`

export default TempsTravailleMulti;
import {dataFetcher} from "../../services/dataGuetter/dataFetcher";

export async function getAllPeriode(id:number):Promise <Periode[]>{
    const res:any = await dataFetcher(`periodes?Centre[]=${id}&Centre[]=0`, {method:"get"})
    return res["hydra:member"];
}

/**
 * @summary permet de récupérer les périodes par type
 * @param id
 * @param idCentre
 */
export async function getPeriodeByType(id:number, idCentre:number):Promise <Periode[]>{
    const res:any = await dataFetcher(`periodes?Type=${id}&Centre[]=${idCentre}&Centre[]=0`, {method:"get"})
    return res["hydra:member"];
}
import React, {useEffect, useMemo, useRef, useState} from "react";
import styled from "styled-components";
import {FaAddressCard, FaTicketAlt, FaMoneyBillAlt, FaUserMd, FaUsers} from "react-icons/fa";
import {GiBackwardTime, GiPhotoCamera, GiSandsOfTime} from "react-icons/gi";
import {
    BsCalendarDate,
    BsCalendarMonth,
    BsCalendarRange, BsFillGearFill,
    BsLayoutWtf, BsLightbulb,
    BsNewspaper,
    BsPercent,
    BsTrash
} from "react-icons/bs";
import {MdFlutterDash, MdLocationPin, MdPivotTableChart} from "react-icons/md";
import {TbClockHour3} from "react-icons/tb";
import TimedLinkSub from "../../atoms/TimedSideLink/TimedLinkSub";
import {FiSearch} from "react-icons/fi";
import {AiFillSetting, AiOutlineAppstoreAdd, AiOutlineBarChart, AiOutlineEye} from "react-icons/ai";
import {GrDocumentTime} from "react-icons/gr";
import {ImSigma} from "react-icons/im";
import {BiChevronsLeft, BiStats, BiSupport} from "react-icons/bi";
import {CgFileDocument} from "react-icons/cg";
import {RiProfileLine} from "react-icons/ri";
import {HiSwitchHorizontal} from "react-icons/hi";
import {useLocation} from "react-router-dom";

const TabIcons = [
    {lib:"FaAddressCard", comp:<FaAddressCard/>},
    {lib:"FaTicketAlt", comp:<FaTicketAlt/>},
    {lib:"FaMoneyBillAlt", comp:<FaMoneyBillAlt/>},
    {lib:"GiPhotoCamera", comp:<GiPhotoCamera/>},
    {lib:"BsCalendarDate", comp:<BsCalendarDate/>},
    {lib:"BsCalendarMonth", comp:<BsCalendarMonth/>},
    {lib:"MdFlutterDash", comp:<MdFlutterDash/>},
    {lib:"FaUserMd", comp:<FaUserMd/>},
    {lib:"FaUsers", comp:<FaUsers/>},
    {lib:"MdPivotTableChart", comp:<MdPivotTableChart/>},
    {lib:"FiSearch", comp:<FiSearch/>},
    {lib:"AiOutlineBarChart", comp:<AiOutlineBarChart/>},
    {lib:"GrDocumentTime", comp:<GrDocumentTime/>},
    {lib:"BsCalendarRange", comp:<BsCalendarRange/>},
    {lib:"ImSigma", comp:<ImSigma/>},
    {lib:"BsNewspaper", comp:<BsNewspaper/>},
    {lib:"BiSupport", comp:<BiSupport/>},
    {lib:"CgFileDocument", comp:<CgFileDocument/>},
    {lib:"RiProfileLine", comp:<RiProfileLine/>},
    {lib:"AiFillSetting", comp:<AiFillSetting/>},
    {lib:"MdLocationPin", comp:<MdLocationPin/>},
    {lib:"BsTrash", comp:<BsTrash/>},
    {lib:"HiSwitchHorizontal", comp:<HiSwitchHorizontal/>},
    {lib:"AiOutlineEye", comp:<AiOutlineEye/>},
    {lib:"BsLayoutWtf", comp:<BsLayoutWtf/>},
    {lib:"AiOutlineAppstoreAdd", comp:<AiOutlineAppstoreAdd/>},
    {lib:"BsPercent", comp:<BsPercent/>},
    {lib:"BsLightbulb", comp:<BsLightbulb/>},
    {lib:"BiStats", comp:<BiStats/>},
    {lib:"GiBackwardTime", comp:<GiBackwardTime/>},
    {lib:"GiSandsOfTime", comp:<GiSandsOfTime/>},
    {lib:"BsFillGearFill", comp:<BsFillGearFill/>},
    {lib:"BsFillGearFill", comp:<BsFillGearFill/>},
    {lib:"TbClockHour3", comp:<TbClockHour3/>},
]

interface TimedSubMenuCtrlProps{
    subMenus:Module[];
    className?:string;
    sectionName:string|null;
    Centre:Centre|undefined|CentreComplet;
    supervisor?:boolean;

}

const TimedSubMenuCtrl = ({subMenus, className, sectionName, Centre, supervisor}:TimedSubMenuCtrlProps)=>{
    const { search } = useLocation();
    const [open, setOpen] = useState(sectionName === 'Plannings' ? false : true);
    const ref = useRef<HTMLHeadingElement>(null);
    const [heightTitre, setHeightTitre] = useState(0);
    const getIcon = (lib:string)=>{
        const myIconTab = TabIcons.find(i=>i.lib===lib);
        if(myIconTab!==undefined){
            return myIconTab.comp;
        } else {
            return <BsCalendarDate/>
        }
    }
    useEffect(()=>{
        if(ref.current) {
            if(open) {
                setHeightTitre(50);
            } else {
                setHeightTitre(ref.current.getBoundingClientRect().height)
            }
        }
    }, [open, subMenus])
    const SubMenusFiltered = useMemo(()=>{
        const listModule:Module[] = [];
        if(!!Centre) {
            subMenus.map(module => {
                const Param = module.Parameter;
                if (!Param) {
                    listModule.push(module);
                } else {
                    const ParamsCentre = Centre.parameters;
                    const GoodParams = ParamsCentre.find(x => x.Parameter.id === Param.id);
                    if (!GoodParams || GoodParams.statut) {
                        listModule.push(module);
                    }
                }
                return module;
            })
        }
        return listModule;
    }, [Centre,subMenus])
    return (
        <div className={`SubMenu ${className} ${open ? 'isOpen': 'isClose'}`}>
            <h2 ref={ref}>{sectionName ? sectionName : 'Setting'}</h2>
            <div className={`wrap-sublink`} style={{marginTop:!open ? (heightTitre+30)+'px' : '0'}}>
            {SubMenusFiltered.map((module:Module, idx:number)=>(
                <TimedLinkSub key={`ItemSub${idx}`} module={module} link={`${module.url}${search}`} icon={getIcon(module.icon)} className={`subMenu`} libelle={module.libelle} Open={open}/>
            ))}
            </div>
            <div className={`OpenCloseSubMenu ${open ? 'toClose' : 'toOpen'}`} onClick={()=>setOpen(_o=>!_o)}>
                <BiChevronsLeft/>
            </div>
        </div>
    )
}

const TimedSubMenu = styled(TimedSubMenuCtrl)`
  width: 250px;
  padding: 0.75rem 0;
  background: ${props=>props.theme.MenuBackground};
  box-shadow: 1px 0 3px rgba(0,0,0,.2);
  position: relative;
  .OpenCloseSubMenu{
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 30px;
    left: 100%;
    transform: translateX(-50%);
    border-radius: 50%;
    background: white;
    display: flex;
    flex-direction: column;
    transition: all 0.3s;
    &.toOpen{
      transform: translateX(-50%) rotate(180deg);
      transition: all 0.3s;
    }
    &:hover{
      cursor: pointer;
    }
    svg{
      margin: auto;
    }
  }
  &.isClose{
    width: 70px;
    //overflow: hidden;
    position: relative;
    h2{
      position: absolute;
      width: max-content;
      margin: 0;
      padding: 0;
      text-align: center;
      transform-origin: 100% 50%;
      transform: translateX(-100%) rotateZ(-90deg);
      top: 10px;
      left: 50%;
      border: none;
    }
    .wrap-sublink{
      margin-top: 100px;
    }
  }
  h2{
    padding: 25px 0;
    font-size: calc(16px + 0.6rem);
    width: 90%;
    text-align: center;
    color:${props=>props.theme.TextMenuColor};
    margin: 0 auto 45px;
    border-bottom: dashed 2px ${props=>props.theme.TextMenuColor};
  }
`

export default TimedSubMenu;
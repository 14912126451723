import styled from "styled-components";
import {ConvertBase5} from "../../../../../components/functions/TimesFunctions";
import React, {useState} from "react";

interface OnePlageSingleCtrlProps{
    className?:string;
    plage:Plage;
    Plages:Plage[];
    hdep:number;
    hfin:number;
    heightHour:number;
    setIsCurrent:(e:any,plage:Plage, id:string)=>void;
}

function hexToRgb(hex:string) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

const OnePlageSingleCtrl = (props:OnePlageSingleCtrlProps)=>{
    const [color, setColor] = useState({back:props.plage.Lieu.backColor, font:props.plage.Lieu.fontColor})
    const styles = ()=> {
        const heureFin = props.plage.heureFin;
        const heureDebut = props.plage.heureDebut;
        const dureeBase5 = heureFin - heureDebut;
        const dureeHeure = dureeBase5/12;
        const height = dureeHeure*props.heightHour;
        const HdepBase5 = props.hdep*12;
        const diff = heureDebut-HdepBase5;
        const Top = (diff/12)*props.heightHour;
        let myColorimetrie = {color:color.font, background:color.back, backgroundImage: "none"}
        if(props.plage.Statut.code !== 'V'){
            if(props.plage.Statut.code === 'B'){
                const r = hexToRgb(color.back) === null ? 255 : hexToRgb(color.back)?.r ;
                const g = hexToRgb(color.back) === null ? 255 : hexToRgb(color.back)?.g ;
                const b = hexToRgb(color.back) === null ? 255 : hexToRgb(color.back)?.b ;
                myColorimetrie = {
                    color: color.font,
                    background: "rgba(" + r + ", "+g+","+b+",.7)",
                    backgroundImage: "none"
                }
            } else {
                myColorimetrie = {
                    color:color.font,
                    background: "transparent",
                    backgroundImage: `linear-gradient(45deg,${color.back} 30px,${props.plage.Statut.color} 30px, ${props.plage.Statut.color} 35px,${color.back} 35px)`,
                }
            }
        }
        return {...myColorimetrie, height:height, top:Top+"px"}
    }
    return (
        <div className={`one_plage ${props.className}`} id={`Plage_${props.plage.id}`} style={styles()}  onMouseDown={(e)=>props.setIsCurrent(e,props.plage, `Plage_${props.plage.id}`)}>
            <div className={`ContentInfos`}>
                <div className={"codeStatut"} style={{color:color.font}}>{props.plage.Statut.code}</div>
                <div className={`LieuActe`}>{props.plage.Lieu.libelle} - {props.plage.Acte.libelle}</div>
                <div className={`Horaires`}>{ConvertBase5(props.plage.heureDebut)} - {ConvertBase5(props.plage.heureFin)}</div>
                {!!props.plage.Memo &&
                    <div>{props.plage.Memo}</div>
                }
                {props.plage.UserReplaced &&
                    <div className={`user_replace`}>Remplace : {props.plage.UserReplaced.prenom} {props.plage.UserReplaced.nom}</div>
                }
            </div>
        </div>
    )
}

export const OnePlageSingle = styled(OnePlageSingleCtrl)`
  position: absolute;
  left: 0;
  width: 90%;
  padding: 0.25rem;
  border-radius: 4px;
  .ContentInfos{
    max-height: 100%;
    overflow: hidden;
    position: relative;
    padding-right: 22px;
    .codeStatut{
      position: absolute;
      width: 18px;
      height: 18px;
      border:solid white 1px;
      border-radius: 6px;
      font-size: 12px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      right: 3px;
    }
    .LieuActe{
      font-weight: bold;
    }
    .Horaires{
      font-size: 12px;
    }
  }
`
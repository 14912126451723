import {useMutation, useQuery, useQueryClient, UseQueryResult} from "react-query";
import {
    AddEvts, cancelCloturePeriodeAbs, CancelSub,
    changeLogo, cloturePeriodeAbs,
    createUser,
    getAllUser, getAllUserFull,
    getContrats,
    getInfosOneUser,
    getInfosPlanning,
    getInfosUser, getInfosUserMob,
    getPlageSemCurr,
    getUserByFonctionFAdm,
    getUserByFonctions,
    getUserByMultiID,
    getUserByUserName,
    getUserReplace,
    getUsersByCentre,
    postWMSub,
    saveConfigPl, saveConfigPlCGL, saveOptin, sendIdentity, updateCentreUC,
    updateInfosPerso,
    updateInfosPro, updateJoursTrav
} from "./user.services";
import {toast} from "react-toastify";

export const useGetUser=():UseQueryResult<UserSimple, Error>=>{
    return useQuery<UserSimple, Error>(["user_connected"], getInfosUser, {
        staleTime:60000,
        refetchOnWindowFocus:false,
    })
}

export const useGetUserMob=():UseQueryResult<MeSimply, Error>=>{
    return useQuery<MeSimply, Error>(["user_connected_mob"], getInfosUserMob, {
        staleTime:100000,
        refetchOnWindowFocus:false,
    })
}

export const useUpdateCentreUC = ()=>{
    const queryClient = useQueryClient();
    return useMutation((datas: {idUser:number, id:number })=>{
            return updateCentreUC(datas.idUser, datas.id)
        },
        {
            onSuccess:(newData)=>{
                queryClient.invalidateQueries(["users"]);
                queryClient.invalidateQueries(["user_connected"]);
                queryClient.setQueryData(["user", newData.id], newData);
                toast.success("profil modifié !", {});
            },
            onError: (error: any) => {
                toast.error("Erreur : " + error.message, {});
            },
        }

    )
}



export const useSaveOptIn = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (id:number)=>{
            return saveOptin(id);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["user_connected"])
            }
        }
    )
}

export const useGetUserByCentre=(idCentre:number):UseQueryResult<User[], Error>=>{
    return useQuery<User[], Error>(["users_centre", idCentre], ({signal})=>getUsersByCentre(idCentre, signal), {
        staleTime:60000,
        refetchOnWindowFocus:true,
        enabled:idCentre!==0
    })
}

export const useAddUser = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:userNewFormData)=>{
            return createUser(datas);
        }, {
            onSuccess:(newData:User)=>{
                queryClient.invalidateQueries(["users"]);
                queryClient.setQueryData(["user", newData.id], newData);
            }
        }
    )
}

export const useSaveConfigPl=()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:UserConfigFormData)=>{
            return saveConfigPl(datas);
        },{
            onSuccess:(newUser:User)=>{
                queryClient.setQueryData(["user_connected"], newUser);
                toast.success('Configuration enregistrée');
            },
            onError:(error:any)=>{
                toast.error("Erreur : " + error.message, {});
            }
        }
    )
}

export const useSaveConfigPlCGL=()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:UserConfigFormData)=>{
            return saveConfigPlCGL(datas);
        },{
            onSuccess:(newUser:User)=>{
                queryClient.setQueryData(["user_connected"], newUser);
                toast.success('Configuration enregistrée');
            },
            onError:(error:any)=>{
                toast.error("Erreur : " + error.message, {});
            }
        }
    )
}

export const useGetAllUser=(idCentre:number|null=null):UseQueryResult<User[], Error>=>{
    return useQuery<User[], Error>(["users", idCentre], ()=>getAllUser(idCentre), {
        staleTime:60000,
        refetchOnWindowFocus:false,
    })
}

export const useGetAllUserFull=():UseQueryResult<User[], Error>=>{
    return useQuery<User[], Error>(["users_full"], getAllUserFull, {
        staleTime:60000,
        refetchOnWindowFocus:false,
    })
}

export const useGetOneUser = (id:number):UseQueryResult<User, Error>=>{
    return useQuery<User, Error>(["user", id], ()=>getInfosOneUser(id), {
        staleTime:60000,
        enabled:id!==0,
        refetchOnWindowFocus:false,
    })
}
export const useGetReal = ():UseQueryResult<User, Error>=>{
    return useQuery<User, Error>(["user_real"], getUserByUserName, {
        staleTime:60000,
    })
}

export const useSaveWMSub = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:WMsubFormData)=>{
            return postWMSub(datas);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["user_real"]);
                queryClient.invalidateQueries(["user"]);
            },
            onError:()=>{
                toast.error("Une erreur est survenue");
            }
        }
    )
}

export const useCancelWMSub = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        ()=>{
            return CancelSub();
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["user_real"]);
                queryClient.invalidateQueries(["user"]);
            },
            onError:()=>{
                toast.error("Une erreur est survenue");
            }
        }
    )
}

export const useGetUsersByFonction = (list:string[], idCentre:number|null=null):UseQueryResult<User[], Error>=>{
    return useQuery<User[], Error>(["users_fonctions", list.join('#'), idCentre], ()=>getUserByFonctions(list, idCentre), {
        staleTime:60000,
        refetchOnWindowFocus:false,
    })
}

export const useGetUsersByFonctionFAdm = (idFx:number, idFxAdm:number, idCentre:number|null=null):UseQueryResult<User[], Error>=>{
    return useQuery<User[], Error>(["users_fx_fxadm", idFx, idFxAdm, idCentre], ()=>getUserByFonctionFAdm(idFx, idFxAdm, idCentre), {
        staleTime:60000,
        refetchOnWindowFocus:false,
    })
}

export const useGetUsersByIds = (list:string[]):UseQueryResult<User[], Error>=>{
    return useQuery<User[], Error>(["users_ids", list.join('#')], ()=>getUserByMultiID(list), {
        staleTime:60000,
        refetchOnWindowFocus:false,
    })
}


export const useAddEvt = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (data:UserEvtAddFormData)=>{
            return AddEvts(data);
        },
        {
            onSuccess:()=>{
                queryClient.invalidateQueries(["events"]);
                queryClient.invalidateQueries(["events_dates"]);
                queryClient.invalidateQueries(["documents"]);
                toast.success("Evt Ajouté !", {});
            }
        }
    )
}


export const useChangeLogo = () => {
    const queryClient = useQueryClient();

    return useMutation(
        (data:UserAvatarFormData) => {
            return changeLogo(data.File, data.id);
        },
        {
            onSuccess: (newData) => {
                queryClient.invalidateQueries(["users"]);
                queryClient.invalidateQueries(["user_connected"]);
                queryClient.setQueryData(["user", newData.id], newData);
                toast.success("Image de profil modifiée !", {});
            },
            onError: (error: any) => {
                toast.error("Erreur : " + error.message, {});
            },
        }
    );
};

export const useUpdateInfosPerso = ()=>{
    const queryClient = useQueryClient();
    return useMutation((datas:UserInfoPersoFormData)=>{
        return updateInfosPerso(datas)
    },
        {
            onSuccess:(newData)=>{
                queryClient.invalidateQueries(["users"]);
                queryClient.invalidateQueries(["user_connected"]);
                queryClient.setQueryData(["user", newData.id], newData);
                toast.success("profil modifié !", {});
            },
            onError: (error: any) => {
                toast.error("Erreur : " + error.message, {});
            },
        }

    )
}
export const useUpdateInfosPro = ()=>{
    const queryClient = useQueryClient();
    return useMutation((datas:UserInfosProfessionnellesFormData)=>{
            return updateInfosPro(datas)
        },
        {
            onSuccess:(newData)=>{
                queryClient.invalidateQueries(["users"]);
                queryClient.invalidateQueries(["user_connected"]);
                queryClient.setQueryData(["user", newData.id], newData);
                toast.success("profil modifié !", {});
            },
            onError: (error: any) => {
                toast.error("Erreur : " + error.message, {});
            },
        }

    )
}
export const useUpdateJoursTrav = ()=>{
    const queryClient = useQueryClient();
    return useMutation((datas: {idUser:number, jt:JoursTravail })=>{
            return updateJoursTrav(datas.idUser, datas.jt)
        },
        {
            onSuccess:(newData)=>{
                queryClient.invalidateQueries(["users"]);
                queryClient.invalidateQueries(["user_connected"]);
                queryClient.setQueryData(["user", newData.id], newData);
                toast.success("profil modifié !", {});
            },
            onError: (error: any) => {
                toast.error("Erreur : " + error.message, {});
            },
        }

    )
}

export const useSendIdentity = ()=>{
    const queryClient = useQueryClient();
    return useMutation((id:number)=>{
        return sendIdentity(id);
    },
        {
            onSuccess:(newData)=>{
                queryClient.invalidateQueries(["users"]);
                queryClient.invalidateQueries(["user_connected"]);
                queryClient.setQueryData(["user", newData.id], newData);
                toast.success("Identifiants envoyés !", {});
            },
            onError: (error: any) => {
                toast.error("Une erreur est survenue, merci de retenter plus tard");
            },
        }
    )
}

export const useCloturePeriodeAbs = ()=>{
    const queryClient = useQueryClient();
    return useMutation((data:{dispo:number, idperiode:number, iduser:number})=>{
            return cloturePeriodeAbs(data.dispo, data.idperiode, data.iduser);
        },
        {
            onSuccess:()=>{
                queryClient.invalidateQueries(["compteur_abs_date"]);
                queryClient.invalidateQueries(["compteur_abs"]);
                toast.success("Periode cloturée !", {});
            },
            onError: (error: any) => {
                toast.error("Une erreur est survenue, merci de retenter plus tard");
            },
        }
    )
}

export const useCancelCloturePeriodeAbs = ()=>{
    const queryClient = useQueryClient();
    return useMutation((data:{idperiode:number, iduser:number})=>{
            return cancelCloturePeriodeAbs(data.idperiode, data.iduser);
        },
        {
            onSuccess:()=>{
                queryClient.invalidateQueries(["compteur_abs_date"]);
                queryClient.invalidateQueries(["compteur_abs"]);
                toast.success("Cloture annulée !", {});
            },
            onError: (error: any) => {
                toast.error("Une erreur est survenue, merci de retenter plus tard");
            },
        }
    )
}

export const useSendIdentityWM = ()=>{
    const queryClient = useQueryClient();
    return useMutation((id:number)=>{
            return sendIdentity(id, true);
        },
        {
            onSuccess:(newData)=>{
                queryClient.invalidateQueries(["users"]);
                queryClient.invalidateQueries(["user_connected"]);
                queryClient.setQueryData(["user", newData.id], newData);
                toast.success("Identifiants envoyés !", {});
            },
            onError: (error: any) => {
                toast.error("Une erreur est survenue, merci de retenter plus tard");
            },
        }
    )
}



export const useGetWeekUser= (id:number):UseQueryResult<Week, Error>=>{
    return useQuery<Week, Error>(["week", id], ()=>getInfosPlanning(id), {
        staleTime:60000,
        refetchOnWindowFocus:false,
    })
}

export const useGetPlanningThisWeek = (id:number):UseQueryResult<Plage[], Error>=>{
    return useQuery<Plage[],Error>(["planningWeek", id], ()=>getPlageSemCurr(id), {
        staleTime:60000,
        refetchOnWindowFocus:false,
    })
}

export const useGetContrats = (id:number, idCentre:number|null=null):UseQueryResult<Contrat[], Error>=>{
    return  useQuery<Contrat[], Error>(["contrats", id, idCentre], ()=>getContrats(id, idCentre), {
        staleTime:60000,
        refetchOnWindowFocus:false,
    })
}

export const useGetUserReplace = (id:number, idCentre:number|null=null):UseQueryResult<UserDispo[], Error>=>{
    return useQuery<UserDispo[], Error>(["user_dispo", id, idCentre], ()=>getUserReplace(id, idCentre), {
        staleTime:1000,
        refetchOnWindowFocus:false
    })
}
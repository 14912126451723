import {dataFetcher} from "../../services/dataGuetter/dataFetcher";

export async function getAllLieux(idCentre:number|null):Promise<Lieu[]>{
    let url = `lieus`
    if(idCentre){
        url = `lieus?Centre=${idCentre}`
    }
    const res:any = await dataFetcher(url, {method:'get'});
    return res["hydra:member"];
}

export async function getAllLieuxByCentre(idCentre:number):Promise<Lieu[]>{
    const res:any = await dataFetcher(`lieus?Centre=${idCentre}`, {method:'get'});
    return res["hydra:member"];
}

export function modifyLieu(id:number, datas:LieuFD):Promise<Lieu>{
    return dataFetcher( `lieus/${id}`, {
        method:'put',
        body:datas
    })
}

export function addLieu(datas:LieuFD):Promise<Lieu>{
    return dataFetcher(`lieus`, {
        method:'post',
        body:datas
    })
}

export function changeOrdreLieu(datas:LieuChangeOrdre):Promise<Lieu[]>{
    return dataFetcher(`lieus/updateOrdre`, {
        method:'post',
        body:datas
    })
}
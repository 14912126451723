import {dataFetcher} from "../../services/dataGuetter/dataFetcher";

/**
 * @summary Permet de récupérer tous les créneaux
 */
export async function getCreneaux(idCentre:number|null):Promise<Creneau[]>{
    let parms = `pagination=false`
    if(idCentre){
        parms+=`&Centre=${idCentre}`
    }
    const res:any = await dataFetcher(`creneaus?${parms}`, {method:'get'})
    return res["hydra:member"];
}

export async function getCreneauxFullParams(params:any|null):Promise<Creneau[]>{
    let TabSearch:string[] = [];
    if(params) {
        for (const [key, value] of Object.entries(params)){
            TabSearch.push(`${key}=${value}`)
        }
    }
    const search = TabSearch.length>0 ? `&${TabSearch.join('&')}` : ''
    const res:any = await dataFetcher(`creneaus?pagination=false${search}`, {method:'get'})
    return res["hydra:member"];
}

export async function getCreneauxFuture(idCentre:number|null):Promise<Creneau[]>{
    const Yesterday = new Date(new Date().getTime() - 86400000);
    const dateAfter = Yesterday.toISOString().slice(0,10);
    let parms = `pagination=false&creneauAt[after]=${dateAfter}`
    if(idCentre){
        parms+=`&Centre=${idCentre}`
    }
    const res:any = await dataFetcher(`creneaus?${parms}`, {method:'get'})
    return res["hydra:member"];
}

export async function getCreneauxNonPris(idCentre:number|null):Promise<Creneau[]>{
    let parms = `pagination=false&isAffect=0`
    if(idCentre){
        parms+=`&Centre=${idCentre}`
    }
    const res:any = await dataFetcher(`creneaus?${parms}`, {method:'get'})
    return res["hydra:member"];
}

export async function getCreneauxToValid(idCentre:number|null):Promise<Creneau[]>{
    let parms = `pagination=false&isAffect=1&isTreat=0`
    if(idCentre){
        parms+=`&Centre=${idCentre}`
    }
    const res:any = await dataFetcher(`creneaus?${parms}`, {method:'get'})
    return res["hydra:member"];
}

export interface ReturnCrenPage{
    result:Creneau[],
    nextPage:number,
    first:number,
    last:number,
}

export async function getCreneauxPages(page:string, params:any|null):Promise<ReturnCrenPage>{

    let TabSearch:string[] = [];
    if(params) {
        for (const [key, value] of Object.entries(params)){
            TabSearch.push(`${key}=${value}`)
        }
    }
    const search = TabSearch.length>0 ? `&${TabSearch.join('&')}` : ''
    const res:any = await dataFetcher(`${page}${search}`, {method:'get'})
    const result = res["hydra:member"];
    const TabView = res["hydra:view"];

    const nextPage =  "hydra:next" in TabView ? TabView["hydra:next"].replace("/api/", "") : undefined;
    const first = res["hydra:view"]["hydra:last"];
    const last = res["hydra:view"]["hydra:first"];

    return {result, nextPage, first, last}
}

/**
 * @summary permet d'ajouter un créneau
 * @param data
 * @constructor
 */
export function AddCreneau(data:CreneauFD):Promise<Creneau>{
    return dataFetcher(`creneaus`, {
        method:'post',
        body:data
    })
}

/**
 * @summary Permet de metre à jour le créneau
 * @param data
 */
export function majCreneau(data:CreneauFD):Promise<Creneau>{
    return dataFetcher(`creneaus/${data.id}`, {
        method:'put',
        body:data
    })
}

export function freeCreneau(id:number):Promise<Creneau>{
    return dataFetcher(`creneaus/${id}`, {
        method:'put',
        body: {UserAffected:null}
    })
}

/**
 * @summary Permet de supprimer un créneau
 * @param id
 */
export function trashCreneau(id:number):Promise<Creneau>{
    return dataFetcher(`creneaus/${id}`, {
        method:'delete'
    })
}

/**
 * @summary permet de valider le créneau
 * @param id (id du créneau)
 */
export function valideCreneau(id:number):Promise<Creneau>{
    return dataFetcher(`creneaus/${id}/valide`, {
        method:'post'
    })
}


export async function getSuggestions(idCentre:number|null):Promise<CreneauSuggestion[]>{
    let url = `creneaus/suggestions`
    if(idCentre){
        url+=`?Centre=${idCentre}`
    }
    const res:any = await dataFetcher(url, {method:'get'})
    return res["hydra:member"];
}
import React from "react";
import styled from "styled-components";
import {UseQueryResult} from "react-query";
import {ProgressBar} from "react-loader-spinner";
import {TimedStickersPlage} from "../../components/molecules/TimedStickers/TimedStickersPlage";
import {BsCheckLg} from "react-icons/bs";
import {IoWarningOutline} from "react-icons/io5";

interface GestionConflitPlageCtrlProps{
    className?:string;

    plageConflit:Plage[];
    conflictPending:boolean;

}

const GestionConflitPlageCtrl = ({className, plageConflit, conflictPending}:GestionConflitPlageCtrlProps)=>{
    return (
        <div className={`gestion-conflit ${className}`}>
            {conflictPending ?
                <div className={`wrap-loader`}>
                    <ProgressBar
                        height="80"
                        width="80"
                        ariaLabel="progress-bar-loading"
                        wrapperStyle={{}}
                        wrapperClass="progress-bar-wrapper"
                        borderColor='#255367'
                        barColor='#44A1AA'
                    /></div>:
                <div className={"results"}>
                    <div className={`wrap-conflits ${plageConflit.length > 0 ? 'warning' : 'success'}`}>
                        {plageConflit.length === 0 ?
                            <div className={`good-conflict`}><BsCheckLg/> Plage en conflit : 0</div>:
                            <div className={`bad-conflict`}><IoWarningOutline/> Plage(s) en conflit : {plageConflit.length}</div>
                        }
                    </div>
                    {plageConflit.length > 0 &&
                        <div className={`wrap-conflits-list`}>
                            <div className={'list-conflit'}>
                                {plageConflit.map((item: Plage) => (
                                    <div className={`wrap-one-conflit`} key={`Pconfli${item.id}`}>
                                        <TimedStickersPlage
                                            Plage={item}
                                            is_acte={true}
                                            is_date={true}
                                            is_lieu={true}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export const GestionConflitPlage = styled(GestionConflitPlageCtrl)`
  padding: 1rem 0.75rem;
  .wrap-loader{
    display: flex;
    justify-content: center;
  }
  .wrap-conflits-list{
    margin-top: 8px;
  }
  .good-conflict, .bad-conflict{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }
  .wrap-conflits{
    padding: 0.5rem 0.75rem;
    line-height: 150%;
    border-radius: 8px;
    font-size: 12px;
    margin-top: 15px;
    &.warning{
      background: ${props => props.theme.ComplementaryExtraLight};
      color: ${props=>props.theme.ComplementaryDark};
    }
    &.success{
      background: ${props => props.theme.SecondaryExtraLight};
      color: ${props=>props.theme.SecondaryDark};
    }
  }
  .list-conflit{
    border-left: solid ${props=>props.theme.ComplementaryLight} 4px;
    padding-left: 8px;
  }
`
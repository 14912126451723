import {useQuery, UseQueryResult} from "react-query";
import {getAllFonction, getAllFonctionByCentre} from "./fontion.services";

export const useGetFonctions = (idCentre:number|null=null):UseQueryResult<Fonction[], Error>=>{
    return useQuery(["fonctions", idCentre], ()=>getAllFonction(idCentre), {
        staleTime:60000,
        refetchOnWindowFocus:false
    })
}

export const useGetFonctionsByCentre = (idCentre:number):UseQueryResult<Fonction[], Error>=>{
    console.log(idCentre);
    return useQuery(["fonctions_centre", idCentre], ()=>getAllFonctionByCentre(idCentre), {
        staleTime:60000,
        refetchOnWindowFocus:false,
        enabled:idCentre!==0
    })
}
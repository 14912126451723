import {dataFetcher} from "../../services/dataGuetter/dataFetcher";
import {DateToDateStrEng} from "../../components/functions/TimesFunctions";

export async function getDayOffByDate(dateFrom:string, dateTo:string, idCentre:number):Promise<DayOff[]>{
    const res:any = await dataFetcher(`day_offs?offAt[after]=${dateFrom}&offAt[before]=${dateTo}&Centre[]=${idCentre}&Centre[]=0`, {method:'get'})
    return res["hydra:member"];
}

export async function getDayOffOneDate(date:Date, idCentre:number):Promise<DayOff[]>{
    const res:any = await dataFetcher(`day_offs?offAt=${DateToDateStrEng(date)}&Centre[]=${idCentre}&Centre[]=0`, {method:'get'})
    return res["hydra:member"];
}
import React, {useState} from "react";
import styled from "styled-components";
import {useUpdateJoursTravContrat} from "../../../../../features/contrat/contrat.hooks";

interface SwitchDayWorkContratCtrlProps{
    className?:string;
    keyDay:string;
    contrat?:Contrat;
    jSetter?:{actual:JoursTravail, setJour:(js:JoursTravail)=>void}
    isLectureSeule?:boolean;
    wid?:number;
}
// const getKeyValue = <U extends keyof T, T extends object>(key: U) => (obj: T) =>
//     obj[key];

const SwitchDayWorkContratCtrl = ({className, keyDay, contrat, jSetter, isLectureSeule}:SwitchDayWorkContratCtrlProps)=>{
    const mutation = useUpdateJoursTravContrat();
    const [isActive, setIsActive] = useState<boolean>(jSetter ? jSetter.actual[keyDay as keyof JoursTravail] === 1 : contrat ? contrat.joursTravail[keyDay as keyof JoursTravail] === 1 : false)
    const handleClick = ()=>{
        if(!isLectureSeule) {
            const newVal = !isActive;
            let jBase: JoursTravail = {L: 0, M: 0, Me: 0, J: 0, V: 0, S: 0, D: 0}
            if (contrat) {
                jBase = contrat.joursTravail;
            }
            if (jSetter) {
                jBase = jSetter.actual;
            }
            jBase[keyDay as keyof JoursTravail] = newVal ? 1 : 0;
            if (jSetter) {
                jSetter.setJour(jBase);
            }
            if (contrat) {
                mutation.mutate(({idContrat: contrat.id, jt: jBase}))
            }
            setIsActive(_o => !_o);
        }
    }
    return (
        <div className={`SwitDay ${className} ${isLectureSeule ? 'noCursor' : ''} ${isActive ? 'active' : 'inactive'}`} onClick={handleClick}>{keyDay}</div>
    )
}

export const SwitchDayWorkContrat = styled(SwitchDayWorkContratCtrl)`
  width: ${props=>props.wid ? `${props.wid}px` : "28px"};
  height: ${props=>props.wid ? `${props.wid}px` : "28px"};
  font-size: ${props=>props.wid ? `${props.wid/2}px` : "14px"};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 14px;
  border-radius: 4px;
  border:solid 1px ${props=>props.theme.SecondaryDark};
  background: ${props=>props.theme.SecondaryExtraLight};
  color: ${props=>props.theme.SecondaryDark};
  &.inactive{
    border:solid 1px ${props=>props.theme.NeutreExtraLight};
    background: ${props=>props.theme.NeutreMegaLight};
    color: ${props=>props.theme.NeutreLight};
  }
  &:not(.noCursor):hover{
    cursor: pointer;
  }
`
import React, {useEffect, useMemo, useRef, useState} from "react";
import styled from "styled-components";
import {GrFormClose} from "react-icons/gr";
import {BiChevronDown} from "react-icons/bi";
import {ListChoice} from "./ListChoice";

interface TimedAutoCompletionLieuCtrlProps{
    className?:string;
    setFieldValue:Function;
    Current:string;
    label:string;
    placeHolder:string;
    id:string;
    isReq:boolean;
    posLeft?:string;
    posTop?:string;
    MaxList?:string;
    Lieux:Lieu[];
    isError?:boolean;
    clearError:Function;
}

const TimedAutoCompletionLieuCtrl = ({placeHolder,label,className, Lieux, setFieldValue, Current, id, isReq, ...rest}:TimedAutoCompletionLieuCtrlProps)=>{
    const [open, setOpen] = useState(false);
    const [onHight, setOnHight] = useState(false);
    const [LieuCurrent, setLieuCurrent] = useState<Lieu|null>(null);
    const refObj = useRef<HTMLDivElement>(null);
    const [search, setSearch] = useState<string>('')
    const refSearch = useRef<HTMLInputElement>(null)
    useEffect(()=>{
        setOnHight(open);
    }, [open])
    const handleSearchChange = ()=>{
        if(refSearch.current){
            setSearch(refSearch.current.value);
        }
    }
    useEffect(() => {
        function handleClickOutside(event:any) {
            if (refObj.current && !refObj.current.contains(event.target)) {
                setOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refObj]);
    useEffect(()=>{
        if(Current!=='' && Lieux){
            const myChoice = Lieux.find(l=>l.libelle === Current);
            if(myChoice){
                setLieuCurrent(myChoice);
            }
        } else {
            setLieuCurrent(null);
        }
    }, [Current, Lieux])
    const handleClickOnIt =()=>{
        if(!open){
            setOpen(true);
        }
    }
    const handleClickChoice = (LieuChoice:Lieu|null)=>{
        if(!!LieuChoice) {
            rest.clearError(id);
            setFieldValue(id, LieuChoice.libelle, {shouldDirty: true})
        } else {
            setFieldValue(id, '', {shouldDirty: true})
        }
        setOpen(false);
    }
    const LieuxFiltered = useMemo(()=>{
        if(search===''){
            return Lieux.sort((a:Lieu, b:Lieu)=>a.libelle > b.libelle ? 1 : -1);
        } else {
            const regex = new RegExp(""+search+"", 'gi');
            return Lieux.filter(u=>u.libelle.match(regex)).sort((a:Lieu, b:Lieu)=>a.libelle > b.libelle ? 1 : -1);
        }
    }, [Lieux, search])
    return (
        <div className={`ChoiceLieu ${className}`} ref={refObj}>
            <div className={`wrap-input ${rest.isError ? "on-error" : onHight ? "is-hight" : ""}`} onClick={handleClickOnIt}>
                <label>{label} {isReq && '*'}</label>
                <div className={`front-input`}>
                    {LieuCurrent ?
                        <div className={`item-choice`}>
                            <div className="name">{LieuCurrent.libelle}</div>
                            <div className={"trash"} onClick={()=>handleClickChoice(null)}>
                                <GrFormClose/>
                            </div>
                        </div>:
                        <div className={`placeHolder`}>
                            <input className={`input-search`} value={search} onChange={handleSearchChange} ref={refSearch} placeholder={placeHolder}/>
                        </div>
                    }
                    <div className={`iconPlace`}><BiChevronDown/></div>
                </div>
            </div>
            {open &&
                <ListChoice MaxList={rest.MaxList} posLeft={rest.posLeft} posTop={rest.posLeft}>
                    <div className={`in-list-user`}>
                        {LieuxFiltered.map((l:Lieu)=>(
                            <div className={`item-choice-list isToChoice`} key={`oneChoiceL${l.id}`} onClick={()=>handleClickChoice(l)} >
                                <div className="name">{l.libelle}</div>
                            </div>
                        ))}
                    </div>
                </ListChoice>
            }
        </div>
    )
}

const TimedAutoCompletionLieu = styled(TimedAutoCompletionLieuCtrl)`
  background: white;
  padding: 0.5rem;
  position: relative;
  .placeHolder{
    color: #ccc;
    padding: 0.35rem;
  }
  &:hover{
    cursor: pointer;
  }
  .input-search{
    width: 100%;
    height: 100%;
    border: none;
    padding: 0 0.25rem;
  }
  .item-choice-list{
    padding: 0.5rem 0.75rem;
    font-size: 16px;
    &:hover{
      cursor: pointer;
      background: ${props=>props.theme.PrimaryExtraLight};
    }
  }
  .item-choice{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
    padding: 0.25rem 0.5rem;
    .trash{
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: ${props=>props.theme.NeutreSuperLight};
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &:hover{
        cursor: pointer;
      }
      svg{
        path{
          stroke:white;
          fill:white;
        }
        margin: auto;
      }
    }
    .name{
      font-weight: bold;
      font-size: 14px;
      flex-grow: 1;
    }
   
  }
  .wrap-input{
    border: solid #ccc 1px;
    position: relative;
    border-radius: 4px;
    &.on-error{
      border: solid ${props=>props.theme.Complementary} 1px;
    }
    &.is-hight{
      border: solid ${props=>props.theme.Primary} 1px;
    }
    &:hover{
      border: solid ${props=>props.theme.PrimaryExtraDark} 1px;
    }
    &.onError{
      border-color: red;
    }
    label{
      font-size: 11px;
      position: absolute;
      padding: 0 0.25rem;
      left: 5px;
      top: 0;
      transform: translateY(-50%);
      background: white;
      color:rgba(0,0,0,0.6)
    }
    .front-input{
      width: 100%;
      padding: 0.2rem;
      display: flex;
      justify-content: start;
      align-items: center;
      .item-choice, .placeHolder{
        flex-grow: 1;
      }
      .iconPlace{
        padding: 0.25rem;
      }
    }

  }
`

export default TimedAutoCompletionLieu;
import styled from "styled-components";
import {TypeDrop} from "../PlanningGlobalLieu/PlanningGlobalLieu3";
import {useMemo} from "react";
import {PlagePG} from "../PlanningGlobalLieu/OneDayPlagePG";
import {OnePlagePP} from "./OnePlagePP";
import {useDrop} from "react-dnd";
import {ItemTypePP} from "./ItemTypePP";
import {useGetAllHoraires} from "../../../../features/horaires/horaires.hooks";
import {useCentreConnectedData} from "../../../../services/hooks";
import {useGetUserMob} from "../../../../features/user/user.hooks";
import {useAddPlage} from "../../../../features/plage/plage.hooks";

interface OneDayPlanningPPCtrlProps{
    className?:string;
    PlagesSync:Plage[];
    TabCol:TypeDrop[];
    date:Date;
    setPlages:(Plages:Plage[])=>void;
    Plages:Plage[];
    //setPlageCurr:(p:Plage)=>void;
    overring:{over:(e:any, p:Plage)=>void, out:()=>void};
    actionContext:(e:any, p:Plage)=>void
    //plageCurrent?:Plage|null
    pending?:boolean
    supervisor?:boolean;
    CGI:ConfigPlanningG|null;
}

const OneDayPlanningPPCtrl = (props:OneDayPlanningPPCtrlProps)=>{
    const MyUserQuery = useGetUserMob();
    const mutationAdd = useAddPlage();
    const centre = useCentreConnectedData();
    const HorairesQuery = useGetAllHoraires(props.supervisor ? centre : null)
    const listPlage = useMemo(()=>{
        const list:PlagePG[] = [];
        props.PlagesSync.map(p=>{
            const mybackColor = !p.Acte.isPresent ? p.Acte.backColor : p.Statut.id === 5 ? `${p.Lieu.backColor}30` : (p.UserReplaced && p.User.id === 0) ? 'linear-gradient(45deg, #98CDD2 12.5%, #6EB7BE 12.5%, #6EB7BE 37.5%, #98CDD2 37.5%, #98CDD2 62.5%, #6EB7BE 62.5%, #6EB7BE 87.5%, #98CDD2 87.5%)' :  p.Acte.backColor
            const myfontColor = !p.Acte.isPresent ? p.Acte.fontColor : (p.UserReplaced && p.User.id === 0) ? '#000' : p.Acte.fontColor
            const PlageTeam = props.Plages.filter(pw=>new Date(pw.plageAt).toLocaleDateString() === new Date(p.plageAt).toLocaleDateString() && pw.id!==p.id && pw.rang && pw.rang === p.rang)
            const TabConflict = props.Plages.filter(pl=> {
                return !(pl.Statut.id === 5 || pl.plageAt !== p.plageAt || pl.User.id !== p.User.id || pl.heureFin <= p.heureDebut || pl.heureDebut >= p.heureFin || pl.id === p.id);
            });
            list.push({
                plage:p,
                backColor:mybackColor,
                fontColor:myfontColor,
                Team:PlageTeam,
                plageConflicts:TabConflict
            })
            return p;
        })
        return list;
    }, [props.PlagesSync, props.Plages])
    const DropMe = (item:{lieu:Lieu, acte:Acte})=>{
        let myNewSeg:Horaires|undefined;
        let user:User|undefined;
        props.TabCol.map(ct=> {
            console.log(ct.type);
            if (ct.type === 'Seg') {
                myNewSeg = HorairesQuery.data?.find(h => h.libelle === ct.libelle)
            }
            if (ct.type === 'User') {
                user = ct.object as User;
                console.log(user);
            }
        })
        console.log(user);
        console.log(myNewSeg);
        if(user) {
            let myNewPlage: Plage = {
                id: 0,
                plageAt: props.date.toISOString().slice(0, 10),
                heureDebut: myNewSeg ? myNewSeg.start : 96,
                heureFin: myNewSeg ? myNewSeg.end : 144,
                User: user,
                Acte: item.acte,
                Lieu: item.lieu,
                UserReplaced: null,
                rang: null,
                Statut: {id: 2, libelle: 'Previsionnel', code: 'P', color: '#F7C67F'},
                segmentation: myNewSeg?.libelle || "",
                myTime: 0
            }
            props.setPlages([...props.Plages, myNewPlage])
            SaveAddPlage(myNewPlage)
        }
    }

    const SaveAddPlage = (plage:Plage)=>{
        const datas: AddPlageFormData = {
            User:`/api/users/${plage.User.id}`,
            plageAt:plage.plageAt,
            Lieu:`/api/lieus/${plage.Lieu.id}`,
            Acte:`/api/actes/${plage.Acte.id}`,
            heureDebut:plage.heureDebut,
            heureFin:plage.heureFin,
            Statut:`/api/statut_plages/${plage.Statut.id}`,
            Memo:'',
            isTreatPointeuse:false,
            UserCreate:`/api/users/${MyUserQuery.data ? MyUserQuery.data.id : 0}`,
            Centre:`/api/centres/${plage.User.Centre.id}`,
        }
        mutationAdd.mutate((datas), {
            onSuccess:()=>{
                console.log('Added');
            }
        })
    }

    const [{isOver, isOverCurrent, canDrop}, drop] = useDrop(()=>({
        accept:[ItemTypePP.NEWPLAGE],
        canDrop:()=>true,
        drop:(item:{lieu:Lieu, acte:Acte})=>{
            DropMe(item)
        },
        collect:(monitor)=>({
            isOver: monitor.isOver(),
            isOverCurrent: monitor.isOver({shallow:true}),
            canDrop: monitor.canDrop()
        })
    }), [props.TabCol, props.Plages])

    return (
        <div className={`one_day_planning_pp ${props.className}`} ref={drop}>
            {listPlage.map((item:PlagePG)=>(
                <OnePlagePP
                    key={`onePlage${item.plage.id}`}
                    PlagePG={item}
                    CGI={props.CGI}
                    overring={props.overring}
                    actionContext={props.actionContext}
                />
            ))}
            {isOverCurrent && canDrop &&
                <div className={`plage_shadow`}/>
            }
        </div>
    )
}

export const OneDayPlanningPP = styled(OneDayPlanningPPCtrl)`
  width: 100%;
  height: 100%;
  min-height: 28px;
  .plage_shadow{
    width: 100%;
    background: ${props => props.theme.NeutreExtraLight};
    height: 29px;
    border-radius: 4px;
    margin: 2px;
  }
`
import {useMutation, useQuery, useQueryClient, UseQueryResult} from "react-query";
import {getAllCentre, getOneCentre, setLittParams, setMobParameters} from "./centre.services";

export const useGetAllCentre = ():UseQueryResult<Centre[], Error>=>{
    return useQuery<Centre[], Error>(["centres"], getAllCentre, {
        staleTime:6000,
        refetchOnWindowFocus:false
    })
}

export const useGetCentreActuel = ():UseQueryResult<CentreComplet, Error>=>{
    const queryClient = useQueryClient();
    const userConnected:User|undefined = queryClient.getQueryData(["user_connected"]);
    const id = userConnected ? userConnected.Centre.id : 0;
    return useQuery<CentreComplet, Error>(["centre", id], ()=>getOneCentre(id), {
        staleTime:6000,
        refetchOnWindowFocus:false,
        enabled:id!==0
    })
}

export const useGetOneCentre = (id:number|undefined):UseQueryResult<CentreComplet, Error>=>{
    return useQuery<CentreComplet, Error>(["centre", id], ()=>getOneCentre(id ||-1), {
        staleTime:6000,
        refetchOnWindowFocus:false,
        enabled:id!==undefined && id>0
    })
}

export const useSetLitts = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (data:{idCentre:number, litt:any})=>{
            return setLittParams(data.idCentre, data.litt);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["centre"]);
                queryClient.invalidateQueries(["centres"]);
            }
        }
    )
}

export const useSetMobParameters = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (data:{idCentre:number, params:any})=>{
            return setMobParameters(data.idCentre, data.params);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["centre"]);
                queryClient.invalidateQueries(["centres"]);
                queryClient.invalidateQueries(["user_connected"]);
                queryClient.invalidateQueries(["user_real"]);
                queryClient.invalidateQueries(["users"]);
                queryClient.invalidateQueries(["user"]);
            }
        }
    )
}
import React, {useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import {useGetAllHoraires} from "../../features/horaires/horaires.hooks";
import {useGetAllLieu} from "../../features/lieu/lieu.hooks";
import {useGetAllActes} from "../../features/acte/acte.hooks";
import {useGetAllStatutPlage} from "../../features/statutPlage/statutPlage.hooks";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {ConvertBase5, ConvertToBase5} from "../../components/functions/TimesFunctions";
import {useGetAllUser, useGetUser} from "../../features/user/user.hooks";
import TimedSelectUserAC from "../../components/atoms/TimedSelectUser/TimedSelectUserAC";
import TimedAutoCompletionLieu from "../../components/atoms/TimedAutoCompletion/TimedAutoCompletionLieu";
import TimedAutoCompletionActe from "../../components/atoms/TimedAutoCompletion/TimedAutoCompletionActe";
import TimedInputTextH from "../../components/atoms/TimedInputText/TimedInputTextH";
import TimedSkeleton from "../../components/atoms/TimedSkeleton/TimedSkeleton";
import {OnQuickHorStyleTwo, WrapSender} from "../../assets/styles/elements";
import TimedTimeChoice from "../../components/atoms/TimedTimeChoice/TimedTimeChoice";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import {MenuItem, TextField} from "@mui/material";
import {useAddPlage, useGetPlageDate, useModifyPlage, useTrashPlage} from "../../features/plage/plage.hooks";
import {getIsHorse, getIsHorseV2, getSegmentation2} from "../../components/functions/PlageFunctions";
import {GestionConflitPlage} from "./GestionConflitPlage";
import TimedLink from "../../components/atoms/TimedLink/TimedLink";
import {BsTrash} from "react-icons/bs";
import TimedButton from "../../components/atoms/TimedButton/TimedButton";
import {BiSave} from "react-icons/bi";
import TimedModal, {TimedModalCtrlProps} from "../../components/molecules/TimedModal/TimedModal";
import TimedRoundAvatar from "../../components/atoms/TimedRoundAvatar/TimedRoundAvatar";
import {TimedHelperText} from "../../components/atoms/TimedHelperText/TimedHelperText";
import TimedSkeletonFormVert from "../../components/atoms/TimedSkeleton/TimedSkeletonFormVert";
import {useCentreConnectedData, useGetCentreActeColor} from "../../services/hooks";

interface FormGerePlageCtrlProps extends TimedModalCtrlProps{
    className?:string;
    Plage?:Plage;
    plageAt?:Date;
    idLieu:number|null;
    idActe:number|null;
    idHoraire:number|null;
    idUser:number|null;
    heureDeb?:number;
    heureFin?:number;
    isTempo?:boolean;
    CloseTempo?:Function;
    ChangePlageTempo?:(pt:PlageTempo)=>void;
    supervisor?:boolean;

}

interface ValueFor{
    User: string ,
    Lieu: string,
    acte:string,
    plageAt:string,
    heureDebut:string,
    heureFin:string,
    memo:string,
    statut:number,
    jun:boolean
}

interface OneHoraireQuick{
    lib:string;
    HD:string;
    HF:string;
}

const FormGerePlageCtrl = ({className, Plage, plageAt, idLieu, idActe, idHoraire, idUser, heureFin, heureDeb, isTempo, CloseTempo,ChangePlageTempo, supervisor, ...props}:FormGerePlageCtrlProps)=>{
    const centre = useCentreConnectedData();
    const MyUserQuery = useGetUser();
    const isActeColor = useGetCentreActeColor(supervisor ? centre : null);
    const [idUserSelect, setIdUserSelect] = useState(idUser ? idUser : 0);
    const [loadingReset, setLoadingReset] = useState(false);
    const [datePlageCurrent, setDatePlageCurrent] = useState<Date|null>(null);
    const PlageDayQuery = useGetPlageDate(datePlageCurrent ? datePlageCurrent.toISOString().slice(0,10) : '', idUserSelect)
    const [plageConflits, setPlageConflits] = useState<Plage[]>([]);
    const [alerteContrat, setAlertContrat] = useState<boolean>(false);
    const [statutOpenHF, setStatutOpenHF] = useState(false);
    const HorairesQuery = useGetAllHoraires(supervisor ? centre : null);
    const LieuxQuery = useGetAllLieu(supervisor ? centre : null);
    const ActesQuery = useGetAllActes(supervisor ? centre : null);
    const StatutQuery = useGetAllStatutPlage();
    const UserQuery = useGetAllUser(supervisor ? centre : null);
    const mutationAdd = useAddPlage();
    const mutationChange = useModifyPlage();
    const mutationTrash = useTrashPlage();
    const defaultValue:ValueFor={
        User: '' ,
        Lieu: '',
        acte:'',
        plageAt:``,
        heureDebut:'--:--',
        heureFin:'--:--',
        memo:'',
        statut:0,
        jun:false,
    }
    const methods = useForm({
        mode:'onChange',
        defaultValues:defaultValue
    })
    useEffect(()=>{
        setLoadingReset(true)
        if(Plage){
            const newDefault = {
                User: Plage.User.fullName ,
                Lieu: Plage.Lieu.libelle,
                acte:Plage.Acte.libelle,
                plageAt:(new Date(Plage.plageAt.slice(0,10))).toISOString().slice(0,10),
                heureDebut:ConvertBase5(Plage.heureDebut),
                heureFin:ConvertBase5(Plage.heureFin),
                memo:Plage.Memo ? Plage.Memo : '',
                statut:Plage.Statut ? Plage.Statut.id : 0
            }
            methods.reset(newDefault);
            setLoadingReset(false)
        } else {
            let nameUser = '';
            if(idUser && UserQuery.data){
                const UserPresume = UserQuery.data.find(u=>u.id === idUser);
                if(UserPresume) nameUser = UserPresume.fullName;
            }
            let nameLieu = '';
            if(idLieu && LieuxQuery.data){
                const LieuPresume = LieuxQuery.data.find(l=>l.id === idLieu);
                if(LieuPresume) nameLieu = LieuPresume.libelle
            }
            let nameActe = '';
            if(idActe && ActesQuery.data){
                const ActePresume = ActesQuery.data.find(a=>a.id === idActe)
                if(ActePresume) nameActe = ActePresume.libelle
            }
            let myHeureDebut = '--:--';
            let myHeureFin = '--:--';
            if(idHoraire && HorairesQuery.data){
                const HorairePresume = HorairesQuery.data.find(h=>h.id === idHoraire)
                if(HorairePresume){
                    myHeureDebut = ConvertBase5(HorairePresume.start);
                    myHeureFin = ConvertBase5(HorairePresume.end);
                }
            }
            if(myHeureDebut === '--:--'){
                if(heureDeb){
                    myHeureDebut = ConvertBase5(heureDeb);
                }
            }
            if(myHeureFin === '--:--'){
                if(heureFin){
                    myHeureFin = ConvertBase5(heureFin);
                }
            }
            const newDefault = {
                User: nameUser,
                Lieu: nameLieu,
                acte:nameActe,
                plageAt:plageAt ? plageAt.toISOString().slice(0,10) : '',
                heureDebut:myHeureDebut,
                heureFin:myHeureFin,
                memo: '',
                statut:2
            }
            methods.reset(newDefault);
            setLoadingReset(false)
        }
    }, [Plage, plageAt, idUser, idHoraire, idActe, idLieu, methods.reset, UserQuery.data, LieuxQuery.data, ActesQuery.data, HorairesQuery.data, heureDeb, heureFin])

    const watchUser = methods.watch("User");
    const watchPlageAt = methods.watch("plageAt");
    const watchHeureDeb = methods.watch("heureDebut");
    const watchHeureFin = methods.watch("heureFin");
    const watchStatut = methods.watch("statut");
    const watchLieu = methods.watch("Lieu")
    const watchActe = methods.watch("acte")
    const watchJun = methods.watch("jun")
    const setError = methods.setError;
    const clearErrors = methods.clearErrors;

    useEffect(()=>{
        console.log('capté')
        const LieuPresume = LieuxQuery.data?.find(l=>l.libelle === watchLieu)
        if(LieuPresume && LieuPresume.defaultActe){
            console.log(LieuPresume);
            methods.setValue("acte", LieuPresume.defaultActe.libelle)
        } else {
            console.log('louipé');
        }
    }, [watchLieu])

    useEffect(()=>{
        if(idUserSelect!==0 && watchPlageAt!=='' && UserQuery.data){
            const datePlage = new Date(watchPlageAt);
            const myUser = UserQuery.data.find(u=>u.id === idUserSelect);
            if(myUser){
                const contrats = myUser.contrats.filter(c=>new Date(c.startAt)<=datePlage && (c.endAt === null || (c.endAt && new Date(c.endAt) > datePlage)));
                if(contrats.length === 0 && myUser.Fonction.id !==1){
                    setAlertContrat(true);
                } else {
                    setAlertContrat(false);
                }
            }
        }
    }, [idUserSelect, watchPlageAt, UserQuery.data])
    useEffect(()=>{
        const HeureDebut = ConvertToBase5(watchHeureDeb);
        const HeureFin = ConvertToBase5(watchHeureFin);
        if((HeureFin<=HeureDebut) && watchJun==false){
            setError("heureDebut", {type:"custom", message:"Erreur"})
            setError("heureFin", {type:"custom", message:"Erreur"})
        } else {
            clearErrors("heureDebut");
            clearErrors("heureFin");
        }
    }, [watchHeureDeb, watchHeureFin, watchJun, setError, clearErrors])
    useEffect(()=>{
        if(ChangePlageTempo && LieuxQuery.data && ActesQuery.data && UserQuery.data){
            const myLieu = LieuxQuery.data.find(l=>l.libelle === watchLieu);
            const myActe = ActesQuery.data.find(a=>a.libelle === watchActe);
            const myUser = UserQuery.data.find(u=>u.fullName === watchUser);
            let colorBack = myLieu ? myLieu.backColor : "#ccc";
            if(isActeColor){
                colorBack = myActe ? myActe.backColor : "#ccc";
            }
            const RealEnd = (watchHeureFin!=='--:--' && watchJun) ? 288 : watchHeureFin!=='--:--' ? ConvertToBase5(watchHeureFin) : 96+36;
            const myNPlTempo:PlageTempo={
                iduser:myUser ? myUser.id : 0,
                plageAt: watchPlageAt,
                LieuLib:myLieu ? myLieu.libelle : '',
                ActeLib:myActe ? myActe.libelle :'',
                heureDebut:watchHeureDeb!=='--:--' ? ConvertToBase5(watchHeureDeb) : 96,
                heureFin:RealEnd,
                colorBack:colorBack
            }
            ChangePlageTempo(myNPlTempo);
        }

    }, [watchPlageAt, watchHeureDeb, watchHeureFin, watchJun, watchLieu, watchActe, ActesQuery.data, LieuxQuery.data, watchUser, UserQuery.data])
    useEffect(()=>{
        if(watchStatut === 5 || watchHeureDeb === '--:--' || watchHeureFin === '--:--' || !PlageDayQuery.data){
            setPlageConflits([]);
        } else{
            const myHD = ConvertToBase5(watchHeureDeb);
            const myHF = ConvertToBase5(watchHeureFin);
            const PlagesConcerned = !Plage ? PlageDayQuery.data : PlageDayQuery.data.filter(p=>p.id !== Plage.id);
            console.log(PlagesConcerned);
            setPlageConflits(getIsHorseV2(myHD, myHF, PlagesConcerned))
        }

    }, [PlageDayQuery.data, watchHeureDeb, watchHeureFin, watchStatut])

    const ListHorairesQuick = useMemo(()=>{
        let listHQ:OneHoraireQuick[] = [];
        if(HorairesQuery.data){
            const HorairesSorted = HorairesQuery.data.sort((a:Horaires, b:Horaires)=>{
                return a.start < b.start ? -1 : 1;
            })
            const HoraireStart = HorairesSorted[0];
            const HoraireEnd = HorairesSorted[HorairesSorted.length - 1];
            listHQ = HorairesSorted.map(h=>{
                return {
                    lib:h.libelle,
                    HD:`${ConvertBase5(h.start).slice(0,2)}:${ConvertBase5(h.start).slice(-2)}`,
                    HF:`${ConvertBase5(h.end).slice(0,2)}:${ConvertBase5(h.end).slice(-2)}`,
                }
            })
            listHQ.push({
                lib:'Journée',
                HD:`${ConvertBase5(HoraireStart.start).slice(0,2)}:${ConvertBase5(HoraireStart.start).slice(-2)}`,
                HF:`${ConvertBase5(HoraireEnd.end).slice(0,2)}:${ConvertBase5(HoraireEnd.end).slice(-2)}`,
            })
        }
        return listHQ;
    }, [HorairesQuery.data])
    useEffect(()=>{
        if(watchPlageAt !== ''){
            setDatePlageCurrent(new Date(watchPlageAt));
        }
    }, [watchPlageAt])

    useEffect(()=>{
        if(watchUser!=='' && UserQuery.data){
            const UserPresume = UserQuery.data.find(u=>u.fullName === watchUser);
            setIdUserSelect(UserPresume ? UserPresume.id : 0);
        }
    }, [watchUser, UserQuery.data])

    const onSubmit = (data:any)=>{
        if(Plage){
            HandlePut(data);
        } else{
            HandleAdd(data);
        }
    }
    const HandleErrors = (data:any)=>{
        const UserPresume = UserQuery.data?.find(u=>u.fullName === data.User)
        const LieuPresume = LieuxQuery.data?.find(l=>l.libelle === data.Lieu)
        const ActePresume = ActesQuery.data?.find(a=>a.libelle === data.acte)
        const nbError:string[] = [];
        if(!UserPresume){
            setError("User", {type:"custom", message:'xx'});
            nbError.push("erreur user");
        }
        if(!LieuPresume){

            setError("Lieu", {type:"custom", message:'xx'});
            nbError.push("erreur lieu");
        }
        if(!ActePresume){
            setError("acte", {type:"custom", message:'xx'});
            nbError.push("erreur acte");
        }
        if((data.heureFin<data.heureDebut && !data.jun) || data.heureDebut === '--:--' || data.heureFin === '--:--'){
            setError("heureFin", {type:"custom", message:'xx'});
            setError("heureDebut", {type:"custom", message:'xx'});
            nbError.push("Erreur dates")
        }
        return {nbError, LieuPresume, ActePresume, UserPresume};
    }
    const HandlePut = (data:any)=>{
        const {nbError, LieuPresume, ActePresume} = HandleErrors(data);
        if(Plage && nbError.length === 0 && LieuPresume && ActePresume){
            const SegPlage = getSegmentation2(HorairesQuery.data, Plage.heureDebut, Plage.heureFin);
            const segData = getSegmentation2(HorairesQuery.data, ConvertToBase5(data.heureDebut), ConvertToBase5(data.heureFin));
            const longPlage = Plage.heureFin - Plage.heureDebut;
            const longData = ConvertToBase5(data.heureFin) - ConvertToBase5(data.heureDebut);
            const DatyPlage = Plage ? new Date(Plage.plageAt).toLocaleDateString() : null;
            const DatyData = new Date(data.plageAt).toLocaleDateString();
            const Rang = (segData!==SegPlage || DatyData!==DatyPlage || !Plage) ? null : Plage.rang;
            const isTreatPointeuse = (DatyData!==DatyPlage || longData !== longPlage) ? false : true;

            let datas: ModifyPlageFormData = {
                id: Plage.id,
                plageAt:data.plageAt,
                Lieu:`/api/lieus/${LieuPresume.id}`,
                Acte:`/api/actes/${ActePresume.id}`,
                heureDebut:ConvertToBase5(`${data.heureDebut}`),
                heureFin:ConvertToBase5(`${data.heureFin}`),
                Statut:`/api/statut_plages/${data.statut}`,
                Memo:data.memo,
                rang:Rang,
                jPlusUn:data.jun
            }
            const SegModif:string[]=[];
            if(ConvertToBase5(`${data.heureDebut}`)<156) SegModif.push('A.M')
            if(ConvertToBase5(`${data.heureFin}`)>156) SegModif.push('P.M')
            const SegPlage2:string[] = []
            if(Plage.heureDebut<156) SegPlage2.push('A.M')
            if(Plage.heureFin>156) SegPlage2.push('P.M')
            let nbCommun = 0;
            for(let i=0;i<=SegPlage2.length-1;i++){
                if(SegPlage2[i] === SegModif[i]) nbCommun++;
            }

            if(!isTreatPointeuse){
                datas.isTreatPointeuse = false;
            }
            if(LieuPresume.id !== Plage.Lieu.id || nbCommun !== SegPlage2.length){
                datas.equipe = null;
                datas.numEquipe = null;
            }
            mutationChange.mutate((datas), {
                onSuccess:()=>{
                    if(!!props.Close) {
                        props.Close();
                    }
                }
            })
        }
    }
    const HandleAdd = (data:any)=>{
        const UserPresume = UserQuery.data?.find(u=>u.fullName === data.User)
        const LieuPresume = LieuxQuery.data?.find(l=>l.libelle === data.Lieu)
        const ActePresume = ActesQuery.data?.find(a=>a.libelle === data.acte)
        const nbError:string[] = [];
        if(!UserPresume){
            setError("User", {type:"custom", message:'xx'});
            nbError.push("erreur user");
        }
        if(!LieuPresume){

            setError("Lieu", {type:"custom", message:'xx'});
            nbError.push("erreur lieu");
        }
        if(!ActePresume){
            setError("acte", {type:"custom", message:'xx'});
            nbError.push("erreur acte");
        }
        if((data.heureFin<data.heureDebut && !data.jun) || data.heureDebut === '--:--' || data.heureFin === '--:--'){
            setError("heureFin", {type:"custom", message:'xx'});
            setError("heureDebut", {type:"custom", message:'xx'});
            nbError.push("Erreur dates")
        }
        if(nbError.length === 0 && UserPresume && LieuPresume && ActePresume){

            const datas: AddPlageFormData = {
                User:`/api/users/${UserPresume.id}`,
                plageAt:data.plageAt,
                Lieu:`/api/lieus/${LieuPresume.id}`,
                Acte:`/api/actes/${ActePresume.id}`,
                heureDebut:ConvertToBase5(`${data.heureDebut}`),
                heureFin:ConvertToBase5(`${data.heureFin}`),
                Statut:`/api/statut_plages/${data.statut}`,
                Memo:data.memo,
                rang:null,
                isTreatPointeuse:false,
                UserCreate:`/api/users/${MyUserQuery.data ? MyUserQuery.data.id : 0}`,
                Centre:`/api/centres/${MyUserQuery.data ? MyUserQuery.data.Centre.id : 0}`,
                jPlusUn:data.jun
            }
            mutationAdd.mutate((datas), {
                onSuccess:()=>{
                    if(!!props.Close) {
                        if(isTempo && CloseTempo){
                            CloseTempo()
                        } else {
                            props.Close();
                        }
                    }
                }
            })
        }

    }
    const handleQuickHor = (h:OneHoraireQuick)=>{
        methods.setValue("heureDebut", h.HD)
        methods.setValue("heureFin", h.HF)
    }
    const handleTrash = ()=>{
        if(Plage){
            mutationTrash.mutate((Plage.id), {
                onSuccess:()=>{
                    if(!!props.Close) {
                        props.Close();
                    }
                }
            })
        }
    }
    const handleValidHeureStart = (val:boolean)=>{
        setStatutOpenHF(val);
    }
    const setValHeure = (id:"heureDebut"|"heureFin", heureStr:string, jUn:boolean=false)=>{
        methods.setValue(id, heureStr, { shouldDirty: true });
        if(id === "heureFin") {
            console.log(jUn ? 'oui '+id : 'non '+id)
            methods.setValue("jun", jUn);
        }
    }
    return (
        <TimedModal {...props} footer={<WrapSender>
            {Plage &&
                <TimedLink
                    size={"md"}
                    icon={<BsTrash/>}
                    themeColor={"Error"}
                    onClick={handleTrash}
                    isDisabled={mutationAdd.isLoading || mutationChange.isLoading || mutationTrash.isLoading}
                    text={"supprimer"}
                />
            }
            {plageConflits.length === 0 &&
                <TimedButton
                    size={"md"}
                    themeColor={"Primary"}
                    children={<span className={`spanIc`}><BiSave/> Enregistrer</span>}
                    type={"button"}
                    onClick={methods.handleSubmit(onSubmit)}
                    disabled={mutationAdd.isLoading || mutationChange.isLoading || mutationTrash.isLoading}
                    isPending={mutationAdd.isLoading || mutationChange.isLoading || mutationTrash.isLoading}
                />
            }

        </WrapSender>}>
        <div className={`form-gere-plage ${className}`}>
            {HorairesQuery.isLoading || UserQuery.isLoading || MyUserQuery.isLoading || StatutQuery.isLoading || LieuxQuery.isLoading || ActesQuery.isLoading || loadingReset ?
                <TimedSkeletonFormVert margTop={"20px"}/>:
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        {!Plage ?
                            <div className={`wrap-auto-completion`}>
                                <TimedSelectUserAC
                                    ChoicesUser={UserQuery.data ? UserQuery.data.filter(u=>u.isActif).sort((a:User, b:User)=>a.nom > b.nom ? 1 : -1) : []}
                                    setFieldValue={methods.setValue}
                                    Current={watchUser}
                                    label={"Collaborateur"}
                                    placeHolder={"Choix Collaborateur"}
                                    id={"User"}
                                    isReq={true}
                                    MaxList={"250px"}
                                    isError={!!methods.formState.errors.User}
                                    clearError={methods.clearErrors}
                                />
                                {methods.formState.errors.User &&
                                    <TimedHelperText text={"Collaborateur obligatoire"}/>

                                }
                            </div>:
                            <div className={`wrap-infos-collab`}>
                                <div className={`av-place`}>
                                    <TimedRoundAvatar BoxShadow={"1px 1px 3px rgba(0,0,0,.7)"} Border={true} src={Plage.User.avatarUrl!==undefined ? Plage.User.avatarUrl : '' } size={"other"} sizeP={"28px"}/>
                                </div>
                                <div className={`name-collab`}>({Plage.User.initials}) <strong>{Plage.User.prenom} {Plage.User.nom}</strong> - {Plage.User.Fonction.libelle} </div>
                            </div>
                        }
                        <div className={`wrap-auto-completion`}>
                            <TimedAutoCompletionLieu
                                Lieux={LieuxQuery.data ? LieuxQuery.data.filter(l=>!l.isLieuAbs) : []}
                                setFieldValue={methods.setValue}
                                Current={methods.watch("Lieu")}
                                label={"Lieu"}
                                placeHolder={"Choix Lieu"}
                                id={"Lieu"}
                                isReq={true}
                                MaxList={"250px"}
                                isError={!!methods.formState.errors.Lieu}
                                clearError={clearErrors}
                            />
                            {methods.formState.errors.Lieu &&
                                <TimedHelperText text={"Lieu obligatoire"}/>
                            }
                        </div>
                        <div className={`wrap-auto-completion`}>
                            <TimedAutoCompletionActe
                                Actes={ActesQuery.data ? ActesQuery.data.filter(a=>a.isDemAbs === false && a.isProduction===true) : []}
                                setFieldValue={methods.setValue}
                                Current={methods.watch("acte")}
                                label={"Acte"}
                                placeHolder={"Choix Acte"}
                                id={"acte"}
                                isReq={true}
                                MaxList={"250px"}
                                isError={!!methods.formState.errors.acte}
                                clearError={clearErrors}
                            />
                            {methods.formState.errors.acte &&
                                <TimedHelperText text={"Acte obligatoire"}/>
                            }
                        </div>
                        <div className={"line-form-full"}>
                            <TimedInputTextH
                                label={"Date"}
                                type={"date"}
                                isRequired={true}
                                register={methods.register}
                                optionRegister={{required:true}}
                                getValue={methods.getValues}
                                id={"plageAt"}
                                size={"small"}
                                isError={!!methods.formState.errors.plageAt}
                                helperText={methods.formState.errors.plageAt && methods.formState.errors.plageAt.type === "required" ? "Champ obligatoire" : ""}
                                isFull={true}
                            />
                        </div>
                        <div className={`wrap-quick-horaires`}>
                            {HorairesQuery.isLoading ?
                                <div className={"pad-ske"}>
                                    <TimedSkeleton width={"100%"} type={"rect"} nbOccurence={1}/>
                                </div> :
                                <div className={`wrap-btn-quick`}>

                                    {ListHorairesQuick.map((h:OneHoraireQuick, idx:number)=>(
                                        <OnQuickHorStyleTwo key={`oneH${idx}`} onClick={()=>handleQuickHor(h)}>
                                            {h.lib}
                                        </OnQuickHorStyleTwo>
                                    ))}

                                </div>
                            }
                        </div>
                        <div className={`wrap-horaires-setting`}>
                            <div className={`wrap-one-horaire`}>
                                <TimedTimeChoice
                                    label={"Heure Debut"}
                                    isFullH={true}
                                    setFieldValue={setValHeure}
                                    isRequired={true}
                                    isError={!!methods.formState.errors.heureDebut}
                                    helperText={!!methods.formState.errors.heureDebut ? "Non valide" : ""}
                                    getValue={methods.getValues}
                                    value={methods.watch("heureDebut")}
                                    id={"heureDebut"}
                                    hourStart={7}
                                    hourStop={22}
                                    posTop={"-100%"}
                                    posLeft={"50%"}
                                    isBig={true}
                                    setForceStatut={handleValidHeureStart}
                                    isGereJun={true}

                                />
                            </div>
                            <div className={`wrap-one-horaire`}>
                                <TimedTimeChoice
                                    label={"Heure Fin"}
                                    isFullH={true}
                                    setFieldValue={setValHeure}
                                    isRequired={true}
                                    isError={!!methods.formState.errors.heureFin}
                                    helperText={!!methods.formState.errors.heureFin ? "Non valide" : ""}
                                    getValue={methods.getValues}
                                    value={methods.watch("heureFin")}
                                    id={"heureFin"}
                                    hourStart={7}
                                    hourStop={24}
                                    posTop={"-100%"}
                                    posLeft={"-50%"}
                                    isBig={true}
                                    forceStatut={statutOpenHF}
                                    jun={watchJun}
                                    isGereJun={true}

                                />
                            </div>
                        </div>
                        {methods.formState.errors.heureDebut &&
                            <TimedHelperText text={"Heure de début non valide"}/>

                        }
                        {methods.formState.errors.heureFin &&
                            <TimedHelperText text={"Heure de fin non valide"}/>
                        }
                        <div className={"line-form-full"}>
                            <Controller
                                name="statut"
                                control={methods.control}
                                render={({ field }) => <>
                                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small" fullWidth={true}>
                                        <InputLabel id="demo-simple-select-label">Statut</InputLabel>
                                        <Select
                                            label="Statut"
                                            {...field}

                                        >
                                            <MenuItem value={0}>Choix du statut</MenuItem>
                                            {StatutQuery.data?.map((S:StatutPlage, idx)=>(
                                                <MenuItem value={S.id} key={`oneActe${idx}`}>{S.libelle}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </>}
                            />
                        </div>
                        <div className={"line-form-full"}>
                            <Controller
                                name="memo"
                                control={methods.control}
                                render={({ field }) => <>
                                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small" fullWidth={true}>
                                        <TextField
                                            multiline={true}
                                            maxRows={8}
                                            label="Memo"
                                            {...field}

                                        />
                                    </FormControl>
                                </>}
                            />
                        </div>
                        <div className={`wrap-conflit`}>
                            <GestionConflitPlage plageConflit={plageConflits} conflictPending={PlageDayQuery.isLoading}/>
                        </div>
                        {alerteContrat &&
                            <div className={`alert-contrat`}>
                                <div className={"in-alert-contrat"}>
                                    Attention : aucun contrat pour cette personne pour la date sélectionnée
                                </div>
                            </div>
                        }
                    </form>
                </FormProvider>
            }
        </div>
        </TimedModal>
    )

}

export const FormGerePlage = styled(FormGerePlageCtrl)`
  padding-top: 15px;
  .alert-contrat{
    padding: 1rem 0.75rem;
    .in-alert-contrat{
      background: ${props => props.theme.ComplementaryExtraLight};
      color: ${props=>props.theme.ComplementaryDark};
      padding: 0.25rem 0.5rem;
      border-radius: 8px;
      font-size: 14px;
    }
  }
  .wrap-infos-collab{
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
    align-items: center;
    justify-content: center;
  }
  .wrap-horaires-setting{
    margin-top: 15px;
    padding: 0.25rem;
    display: flex;
    justify-content: space-between;
  }
  .wrap-auto-completion{
    margin-bottom: 15px;
  }
  .line-form-full{
    width: 96%;
    position: relative;
    margin-bottom: 10px;
  }
  .wrap-quick-horaires{
    padding: 0.5rem;
    height: 32px;
    .wrap-btn-quick{
      display: flex;
      justify-content: center;
      gap:5px;
    }
  }
`
import styled from "styled-components";

interface PlageAdd_draggyCtrlProps{
    className?:string;
    lieu:Lieu;
    acte:Acte;
}

const PlageAdd_draggyCtrl = (props:PlageAdd_draggyCtrlProps)=>{
    return (
        <div className={`plagepp_draggy ${props.className}`} style={{background:props.acte.backColor}}>
            <div className={`lieu`}>{props.lieu.libelle}</div>
            <div className={`acte`}>{props.acte.libelle}</div>
        </div>
    )
}

export const PlageAddDraggy = styled(PlageAdd_draggyCtrl)`
  width: max-content;
  gap: 10px;
  padding: 0.25rem 0.5rem;
  display: flex;
  border-radius: 4px;
`
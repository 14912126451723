import {useQuery, useQueryClient, UseQueryResult} from "react-query";
import {getDayOffByDate, getDayOffOneDate} from "./dayOff.services";

export const useGetDayOffByDD = (date1:string, date2:string, idCentre:number):UseQueryResult<DayOff[], Error>=>{
    return useQuery<DayOff[], Error>(["day_off", date1, date2, idCentre], ()=>getDayOffByDate(date1, date2, idCentre), {
        staleTime:60000,
        refetchOnWindowFocus:false
    })
}

export const useGetDayOffByDDAuto = (date1:string, date2:string, idCentre:number|null=null):UseQueryResult<DayOff[], Error>=>{
    const queryClient = useQueryClient();
    if(!idCentre) {
        const userConnected: UserSimple | undefined = queryClient.getQueryData("user_connected");
        idCentre = userConnected ? userConnected.Centre.id : 0;
    }
    return useQuery<DayOff[], Error>(["day_off", date1, date2, idCentre], ()=>getDayOffByDate(date1, date2, idCentre||0), {
        staleTime:60000,
        refetchOnWindowFocus:false,
        enabled:date1!=='' && date2 !==''
    })
}

export const useDayOffByD = (date:Date):UseQueryResult<DayOff[], Error>=>{
    const queryClient = useQueryClient();
    const UserConnected:User|undefined = queryClient.getQueryData("user_connected");
    let idCentre = 0;
    if(UserConnected){
        if(UserConnected){
            idCentre = UserConnected.Centre.id;
        }
    }
    return useQuery<DayOff[], Error>(["day_off", date], ()=>getDayOffOneDate(date, idCentre), {
        staleTime:60000,
        refetchOnWindowFocus:false
    })
}
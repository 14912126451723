import {OneDayWrap} from "../../pages/TimedApplication/Plannings/PlanningGlobalLieu/PlanningGlobalLieuMain";

export const TableDays:OneDayWrap[] = [
    {libelle:'Lundi', numDay:1},
    {libelle:'Mardi', numDay:2},
    {libelle:'Mercredi', numDay:3},
    {libelle:'Jeudi', numDay:4},
    {libelle:'Vendredi', numDay:5},
    {libelle:'Samedi', numDay:6},
    {libelle:'Dimanche', numDay:0},
]
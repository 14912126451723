import styled from "styled-components";
import {TColumn, TypeDrop} from "../PlanningGlobalLieu/PlanningGlobalLieu3";
import TimedRoundAvatar from "../../../../components/atoms/TimedRoundAvatar/TimedRoundAvatar";
import React, {useEffect, useState} from "react";
import {GetGoodContractDate, GetToDoWeek, GetToDoWeekByContrat} from "../../../../components/functions/ContratFunction";
import {getWeekByDate} from "../../../../components/functions/TimesFunctions";
import {CiWarning} from "react-icons/ci";

interface CellPersonneCtrlProps{
    className?:string;
    user:User;
    TabCol:TypeDrop[];
    datePick:Date;
    setPlages:(Plages:Plage[])=>void;
    Plages:Plage[];
    supervisor?:boolean;
}

const CellPersonneCtrl = (props:CellPersonneCtrlProps)=>{
    const [nbHeure, setNbHeure] = useState(0)
    const [nbHeureToDo, setNbHeureToDo] = useState(0)
    const [backColorSlidy, setBackColorSlidy] = useState("#d9f8fb")
    const [fontColorCt, setFontColorCt] = useState({color:"black", fontWeight:"normal", fontStyle:"normal", textDecoration:"none"})
    const [haveContrat, setHaveContrat] = useState(true);

    useEffect(()=>{
        const {Monday, Sunday} = getWeekByDate(props.datePick);
        const contrats = props.user.contrats;
        if(!contrats || !GetGoodContractDate(props.user.contrats, props.datePick)){
            setHaveContrat(false);
        } else {
            setHaveContrat(true);
            setNbHeureToDo(GetToDoWeekByContrat(GetGoodContractDate(props.user.contrats, props.datePick)))
        }
    }, [props.user, props.datePick])
    useEffect(()=>{
        const NB =  Math.round(props.Plages.filter(p=>p.User.id === props.user.id).reduce((acc, obj)=>(acc+(obj.Time === undefined ? 0 : obj.Time)), 0)*100)/100;
        setNbHeure(NB);
    }, [props.user, props.Plages])
    useEffect(()=>{
        const Pc = (nbHeure/nbHeureToDo)*100;
        let color:string = '';
        let fw:string = "normal"
        let colorF:string="#1F2041";
        let fSty:string = "normal"
        let Tdec:string = "none"
        if(Pc<25){
            color = "#FFE2E2"
            fw = "bold"
            colorF="red"
        } else if(Pc<50){
            color = "#FFE2E2"
            fSty = "italic"
        } else if(Pc<80){
            color = "#F8E6C2"
            fSty = "italic"
        } else if(Pc>100){
            color = "#EDDCEB"
            Tdec = "underline"
        } else {
            color = " #E4EAD3"
            fSty = "italic"
        }
        setBackColorSlidy(color);
        setFontColorCt({color:colorF, fontWeight:fw, fontStyle:fSty, textDecoration:Tdec})
    }, [nbHeure, nbHeureToDo])
    const getWidthText = (text:string)=>{
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if(context){
            context.font = getComputedStyle(document.body).font;
            return context.measureText(text).width;
        } else {
            return 0;
        }

    }
    return (
        <div className={`cell_personne ${props.className}`}>
            {props.user.Fonction.id!==1 &&
                <div className={"avc_contrat"}>
                    <div className={`slidy`} style={{width:`${Math.min((nbHeure/nbHeureToDo)*100, 100)}%`, background:backColorSlidy}}/>
                </div>
            }
            <div className={`wrap_u`}>
                <div className={`avatar_place`}>
                    <TimedRoundAvatar src={props.user.avatarUrl!==undefined ? props.user.avatarUrl : '' } size={"other"} sizeP={"40px"} isSquare={true}/>
                </div>
                <div className={`name_fx`}>
                    <span>{props.user.prenom} {props.user.nom}</span>
                    <span className={`fx`}>{props.user.Fonction.libelle}</span>
                    {props.user.Fonction.id !== 1 &&
                        <div className={`wrap_ct`} style={fontColorCt}>
                            {!haveContrat ?
                                <div className={"NoContract"}><CiWarning style={{marginRight:"5px"}}/>Aucun contrat</div>:
                                <div className={`nbHeures`}>{nbHeure}/{Math.round(nbHeureToDo*100)/100}</div>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export const CellPersonne = styled(CellPersonneCtrl)`
  position: relative;
  width: 100%;
  height: 100%;
  .NoContract{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: ${props => props.theme.WarningDark};
    font-weight: bold;
  }
  .avc_contrat{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    .slidy{
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      
    }
  }
    .wrap_u{
      position: absolute;
      left: 5px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      display: flex;
      justify-content: flex-start;
      gap: 5px;
      align-items: center;
      span{
        display: block;
        &.fx{
          font-size: 12px;
          color:${props => props.theme.Dark};
          font-weight: lighter;
        }
      }
    }
`

import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {useDrag} from "react-dnd";
import {ItemTypes} from "../../../Modaux/ModalGereDayPointage";

interface OneHoraireDragCtrlProps{
    className?:string;
    Horaire:string;
    jUn:boolean;
}

const OneHoraireDragCtrl = ({className, Horaire, jUn}:OneHoraireDragCtrlProps)=>{
    const [{isDragging}, drag] = useDrag(() => ({
        type: ItemTypes.Horaire,
        item:{horaire:Horaire, jUn:jUn},
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    }))
    return (
        <div className={`oneHoraireDrag ${className} ${isDragging ? 'onDrag' : ''}`} ref={drag}>{`${Horaire}${jUn ? '(+1)' : ''}`}</div>
    )
}

export const OneHoraireDrag = styled(OneHoraireDragCtrl)`
  padding: 0.25rem 1rem;
  margin: auto;
  border-radius: 12px;
  text-align: center;
  width: max-content;
  border:solid ${props=>props.theme.Primary} 1px;
  margin: 5px;
  font-size: 13px;
  &:hover{
    cursor: pointer;
  }
  &.onDrag{
    //background: red;
  }
`
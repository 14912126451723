import styled from "styled-components";
import {PlagePG} from "../PlanningGlobalLieu/OneDayPlagePG";
import {useEffect, useState} from "react";
import {BsDot} from "react-icons/bs";
import {ConvertBase5} from "../../../../components/functions/TimesFunctions";
import {AiOutlineTeam} from "react-icons/ai";

interface OnePlagePPCtrlProps{
    className?:string;
    PlagePG:PlagePG;
    CGI:ConfigPlanningG|null;
    overring:{over:(e:any, p:Plage)=>void, out:()=>void};
    actionContext:(e:any, p:Plage)=>void
}

const OnePlagePPCtrl = (props:OnePlagePPCtrlProps)=>{
    const [hasActe, setHasActe] = useState(true)
    const [hasLieu, setHasLieu] = useState(true)
    const [hasHD, setHasHD] = useState(true)
    const [hasHF, setHasHF] = useState(true)
    useEffect(()=>{
        if(props.CGI){
            setHasActe(!!(props.CGI.hasActe && props.CGI.hasActe === 1))
            setHasLieu(!!(props.CGI.hasLieu && props.CGI.hasLieu === 1))
            const FxHD = props.CGI.HeureDebutFx;
            const FxHF = props.CGI.HeureFinFx;
            setHasHD(FxHD.indexOf(props.PlagePG.plage.User.Fonction.id)!==-1)
            setHasHF(FxHF.indexOf(props.PlagePG.plage.User.Fonction.id)!==-1)
        }
    }, [props.CGI, props.PlagePG])
    return (
        <div className={`one_plage_pp ${props.className}`}>
            <div  onContextMenu={(e)=> {
                if(props.PlagePG.plage.User.id !== 0 && props.PlagePG.plage.Acte.isPresent) {
                    props.actionContext(e, props.PlagePG.plage)
                }
            }} className={`in_plage_pp`} style={{background:props.PlagePG.backColor, color:props.PlagePG.fontColor}} onMouseOver={(e)=>props.overring.over(e,props.PlagePG.plage)} onMouseOut={()=>props.overring.out()}>
                <div className={`name-place`}>
                    <div className={`in-name`}>
                        {hasLieu &&
                            <span>{props.PlagePG.plage.Lieu.libelle}</span>
                        }
                        {hasActe &&
                            <>
                                {hasLieu && <BsDot style={{margin:"0 1px"}}/>}
                                <span>{props.PlagePG.plage.Acte.libelle}</span>
                            </>
                        }
                        {hasHD &&
                            <>
                                {(hasActe || hasLieu) && <BsDot style={{margin:"0 1px"}}/>}
                                <span>{ConvertBase5(props.PlagePG.plage.heureDebut)}</span>
                            </>
                        }
                        {hasHF &&
                            <>
                                {((hasActe || hasLieu) && !hasHD) && <BsDot style={{margin:"0 1px"}}/>}
                                {hasHD && <span style={{margin:"0 1px"}}>-</span>}
                                <span>{ConvertBase5(props.PlagePG.plage.heureFin)}</span>
                            </>
                        }

                    </div>
                </div>
                {props.PlagePG.plage.numEquipe &&
                    <div><AiOutlineTeam/></div>
                }
            </div>
        </div>
    )
}

export const OnePlagePP = styled(OnePlagePPCtrl)`
   width: 100%;
   padding: 0.15rem;
    .in_plage_pp{
      padding: 0.5rem;
      border-radius: 25px;
      font-size: 11px;
      display: flex;
      justify-content: flex-start;
      gap: 3px;
    }
  .name-place {
    //padding: 0.4rem 0.4rem 0.4rem 0.7rem;
    flex-grow: 1;
    overflow: hidden;
    width: 100%;

    .in-name {
      font-size: 11px;
      //color:white;
      span{
        margin-right: 3px;
      }
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      display: block;
      overflow: hidden;

      svg {
        padding-top: 3px;
      }
    }

  }
`


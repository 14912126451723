import React, {useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import OneDayDetails from "./OneDayDetails";
import {useGetPlagesDateToDateOne} from "../../../../../../features/plage/plage.hooks";
import {TimedSkeletonRoundDay} from "../../../../../../components/atoms/TimedSkeleton/TimedSkeletonRoundDay";
import {UseGetPointeuseTreatByParams} from "../../../../../../features/pointeuseTreat/pointeuseTreat.hooks";
import {TimedRoundDay} from "../../../../../../components/molecules/TimedRoundDay/TimedRoundDay";
import {useCentreConnectedData} from "../../../../../../services/hooks";

interface DetailsDailyReleveCtrlProps{
    className?:string;
    dateUseEnd:string;
    idUser:number;
    setPointeurTreat:Function;
    isAbsent:boolean;
    setFerie:Function;
    supervisor?:boolean;
}
interface OneRoundDay{
    date:Date,
    hasObject:boolean,
    isAno:boolean,
}

const DetailsDailyReleveCtrl = ({className, idUser, dateUseEnd, setPointeurTreat, isAbsent, setFerie, supervisor}:DetailsDailyReleveCtrlProps)=>{
    const centre = useCentreConnectedData();
    const [dateStart, setDateStart] = useState<string>('')
    const [dateEnd, setDateEnd] = useState<string>('')
    const [params, setParams] = useState<any|undefined>(undefined)
    const PTQuery = UseGetPointeuseTreatByParams(params);
    const [dateChoice, setDateChoice] = useState<Date|null>(null)
    useEffect(()=>{
        const now = new Date();
        let dateS = new Date(new Date(dateUseEnd).getFullYear(), new Date(dateUseEnd).getMonth(), 1);
        dateS = new Date(dateS.getTime() - dateS.getTimezoneOffset()*60000);
        setDateChoice(dateS);
        const dateE = new Date(dateUseEnd).getMonth() === now.getMonth() ? new Date(now.getTime() - 86400000) : new Date(dateUseEnd);
        setDateStart(dateS.toISOString().slice(0,10))
        setDateEnd(dateE.toISOString().slice(0,10))
        let paramsW:any = {"dateAT[after]":dateS.toISOString().slice(0,10), "dateAT[before]":dateE.toISOString().slice(0,10), User:idUser}
        if(supervisor){
            paramsW.Centre = centre;
        }
        setParams(paramsW)
    }, [dateUseEnd, idUser, supervisor, centre])

    const listRoundDay = useMemo(()=>{
        let listD:OneRoundDay[] = [];
        if(PTQuery.data){
            let current = new Date(dateStart);
            const dateE = new Date(dateEnd);
            while(current<=dateE){

                const MyDate = current;
                if(!isAbsent) {
                    const PTConc = PTQuery.data.filter(pt => new Date(pt.dateAT).toLocaleDateString() === MyDate.toLocaleDateString() && !pt.MotifReguleTemps && !pt.isAbsent);
                    let nbAno = 0;
                    PTConc.map(pt => {
                        const Ano = pt.AnomaliePointages;
                        if (Ano.E === 1 || Ano.S === 1) {
                            nbAno++;
                        }
                        return pt;
                    })

                    listD.push({
                        date: MyDate,
                        hasObject: PTConc.length > 0,
                        isAno: nbAno > 0
                    })
                } else {
                    const PTConc = PTQuery.data.filter(pt => new Date(pt.dateAT).toLocaleDateString() === MyDate.toLocaleDateString() &&  pt.isAbsent);
                    listD.push({
                        date: MyDate,
                        hasObject: PTConc.length > 0,
                        isAno: false
                    })
                }
                current = new Date(current.getTime() + 86400000)
            }
        }
        return listD;
    }, [PTQuery.data, dateStart, dateEnd, isAbsent])
    const setNewDate = (dat:Date)=>{
        setDateChoice(dat);
    }

    return (
        <div className={`dailyReleve ${className}`}>
            <div className={'time-line'}>
                {PTQuery.isLoading ?
                    <TimedSkeletonRoundDay nbRound={10}/>:
                    listRoundDay.map((item:OneRoundDay, idx:number)=>(
                        <TimedRoundDay
                            key={`one-choice-date${idx}`}
                            isAnomalie={item.isAno}
                            setDate={setNewDate}
                            hasObject={item.hasObject}
                            date={item.date}
                            isSelected={item.date.toLocaleDateString() === dateChoice?.toLocaleDateString()}
                        />
                    ))
                }
            </div>
            <div className={`content-day`}>
                {dateChoice!==null &&
                    <OneDayDetails
                        idUser={idUser}
                        dateUse={dateChoice}
                        setPointeurTreat={setPointeurTreat}
                        setFerie={setFerie}
                        isAbsent={isAbsent}
                        supervisor={supervisor}
                    />
                }
            </div>

        </div>
    )
}

const DetailsDailyReleve = styled(DetailsDailyReleveCtrl)`
  .content-day{
    margin: 20px auto;
  }
    .time-line{
      display: flex;
      gap: 5px;
    }
`

export default DetailsDailyReleve;
import styled from "styled-components";
import React, {useEffect, useState} from "react";
import {OnePlageSingle} from "./OnePlageSingle";

interface OneColumnDayCtrlProps{
    className?:string;
    myDate:Date;
    Plages:Plage[];
    hdep:number;
    hfin:number;
    heightHour:number;
    setIsCurrent:(e:any, plage:Plage, id:string)=>void;
}

const OneColumnDayCtrl = React.forwardRef((props:OneColumnDayCtrlProps, ref:React.ForwardedRef<HTMLDivElement>)=>{
    const [myPlages, setMyPlages] = useState<Plage[]>([]);
    useEffect(()=>{
        setMyPlages(props.Plages.filter(p=>new Date(p.plageAt.slice(0,10)).toISOString().slice(0,10) === props.myDate.toISOString().slice(0,10)))
    }, [props.Plages, props.myDate])
    let cellGrids:React.ReactNode[] = [];
    let myHD = props.hdep;
    while(myHD<=props.hfin){
        cellGrids.push(<div className={`cellGrid`} key={`cG${myHD}`}/>)
        myHD++;
    }
    const getTop = (p:Plage)=>{
        const heureDebut = p.heureDebut;
        const HdepBase5 = props.hdep*12;
        const diff = heureDebut-HdepBase5;
        return (diff/12)*props.heightHour;

    }
    return (
        <div className={`one_columns ${props.className}`}>
            <div className={`grid`}>{cellGrids.map(item=>(item))}</div>
            <div className={`wrapper_plages`} ref={ref}>
                {myPlages.map((item:Plage)=>(
                    <OnePlageSingle
                         key={`oneP${item.id}`}
                         plage={item}
                         Plages={props.Plages}
                         setIsCurrent={props.setIsCurrent}
                         hdep={props.hdep}
                         hfin={props.hfin}
                         heightHour={props.heightHour}
                    />
                ))}
            </div>
        </div>
    )
})

export const OneColumnDay = styled(OneColumnDayCtrl)`
  width: 14.28571%;
  height: 100%;
  position: relative;
  
  .one_p{
    position: absolute;
    left: 0;
    height: 30px;
    background: #ccc;
    width: 100%;
  }
  .wrapper_plages{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .grid{
    border-right: solid 1px ${props=>props.theme.NeutreLight};
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    .cellGrid{
      width: 100%;
      height: ${props=>props.heightHour+"px"};
      border-bottom: ${props=>props.theme.NeutreLight} solid 1px;
    }
  }
`
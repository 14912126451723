import {dataFetcher} from "../../services/dataGuetter/dataFetcher";

export async function getAllModule():Promise<Module[]>{
    const res:any = await dataFetcher(`skeleton_modules`, {method:"get"})
    return res["hydra:member"];
}

export async function getAllSection():Promise<Section[]>{
    const res:any = await dataFetcher(`skeleton_sections`, {method:"get"})
    return res["hydra:member"];
}
import {useQuery, useQueryClient, UseQueryResult} from "react-query";
import {getAllPeriode, getPeriodeByType} from "./periode.services";

export const useGetAllPeriodes = (idCentre:number|null=null):UseQueryResult<Periode[], Error>=>{
    const queryClient = useQueryClient();
    if(!idCentre) {
        const userConnected: User | undefined = queryClient.getQueryData("user_connected");
        if (userConnected) {
            idCentre = userConnected.Centre.id;
        }
    }
    return useQuery(['periodes', idCentre], ()=>getAllPeriode(idCentre||0), {
        staleTime:60000,
        refetchOnWindowFocus:false,
        enabled:idCentre!==0
    })
}

export const useGetAllPeriodesByCentre = (idCentre:number):UseQueryResult<Periode[], Error>=>{
    return useQuery(['periodes', idCentre], ()=>getAllPeriode(idCentre), {
        staleTime:60000,
        refetchOnWindowFocus:false,
        enabled:idCentre!==0
    })
}

export const useGetPeriodesByType = (id:number):UseQueryResult<Periode[], Error>=>{
    const queryClient = useQueryClient();
    const userConnected:User|undefined = queryClient.getQueryData("user_connected");
    let idCentre = 0;
    if(userConnected){
        idCentre = userConnected.Centre.id;
    }
    return useQuery(["periodes", id], ()=>getPeriodeByType(id, idCentre), {
        staleTime:60000,
        refetchOnWindowFocus:false,
        enabled:idCentre!==0
    })
}
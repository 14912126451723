import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {UseGetPointeuseTreatByParams} from "../../../../features/pointeuseTreat/pointeuseTreat.hooks";
import {OneHoraireDrag} from "./OneHoraireDrag";
import {ConvertBase5} from "../../../../components/functions/TimesFunctions";
import {useGetPointeuseByParams} from "../../../../features/pointeuse/pointeuse.hooks";
import {useCentreConnectedData} from "../../../../services/hooks";

interface BasketToDropCtrlProps{
    className?:string;
    PointeuseTreat:PointeuseTreat;
    supervisor?:boolean;
}
const ConvertHTimeToTime = (date:string, addSec=false)=>{
    const Dat = new Date(new Date(date).getTime());
    const Heure = Dat.getHours() < 10 ? `0${Dat.getHours()}` : Dat.getHours();
    let Minutes = Dat.getMinutes()<10 ? `0${Dat.getMinutes()}` : Dat.getMinutes();
    if(addSec){
        const secondes = Dat.getSeconds();
        if(secondes>0){
            Minutes = (Dat.getMinutes()+1)<10 ? `0${(Dat.getMinutes()+1)}` : (Dat.getMinutes()+1);
        }
    }
    return `${Heure}:${Minutes}`;
}



const BasketToDropCtrl = ({className, PointeuseTreat, supervisor}:BasketToDropCtrlProps)=>{
    const centre = useCentreConnectedData();
    const [params, setParams] = useState<any|undefined>(undefined);
    const PointeusesTreatQuery = UseGetPointeuseTreatByParams(params)
    const [paramsP, setParamsP] = useState<any|undefined>(undefined);
    const PointeuseQuery = useGetPointeuseByParams(paramsP);
    useEffect(()=>{
        const dateAt = new Date(PointeuseTreat.dateAT.slice(0,10));
        const dateAtTom = new Date(dateAt.getTime() + 86400_000);
        const dateStr = dateAt.toISOString().slice(0,10);
        const dateAtTomStr = dateAtTom.toISOString().slice(0,10);
        const user = PointeuseTreat.User;
        let paramsXP:any = {"dateAT":dateStr, User:user.id}
        if(supervisor){
            paramsXP.Centre = centre;
        }
        setParams(paramsXP);
        setParamsP({"pointAt[after]":dateStr+' 00:00:00', "pointAt[before]":dateAtTomStr+' 04:00:00', User:user.id})
    }, [PointeuseTreat, supervisor, centre])
    const getIsJUn = (item:Pointeuse)=>{
        return new Date(item.pointAt.slice(0,10)).toISOString().slice(0,10) !== new Date(PointeuseTreat.dateAT.slice(0,10)).toISOString().slice(0,10);
    }
    return (
        <div className={`BasketToDrop ${className}`}>
            <div className={"title"}>Horaires de la journée</div>
            <div className={"wrap-horaires"}>
                <div className={`BaskPointeuse col-horaires`}>
                    <div className={`title-bask`}>Heures pointées</div>
                    {PointeuseQuery.isLoading ? 'chargement...' : PointeuseQuery.isError ? 'error' :
                        PointeuseQuery.data?.map((item:Pointeuse, idx:number)=>(
                            <OneHoraireDrag key={`pDr${idx}`} jUn={getIsJUn(item)}  Horaire={ConvertHTimeToTime(item.pointAt, item.type === 'S')}/>
                        ))
                    }
                </div>
                <div className={`BaskPlanning col-horaires`}>
                    <div className={`title-bask`}>Heures planning</div>
                    {PointeusesTreatQuery.isLoading ? 'chargement...' : PointeusesTreatQuery.isError ? 'error':
                        PointeusesTreatQuery.data?.map((pt:PointeuseTreat, idx:number)=>(
                            <div className={`wrap-pt`} key={`onePT${idx}`}>
                                {pt.plages.map((item:Plage, idxA:number)=>(
                                    <div className={`onePlageToDrag`} key={`onPTDrag${idxA}`}>
                                        <OneHoraireDrag jUn={false} Horaire={ConvertBase5(item.heureDebut)} />
                                        <OneHoraireDrag jUn={item.jPlusUn === true} Horaire={ConvertBase5(item.heureFin)} />
                                    </div>
                                ))}
                            </div>
                    ))
                    }
                </div>
            </div>
        </div>
    )
}

export const BasketToDrop = styled(BasketToDropCtrl)`
    background: white;
  padding: .5rem;
  margin-bottom: 15px;
  height: 35%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 8px;
  .title{
    font-size: 13px;
    color:${props => props.theme.Primary}
  }
  .wrap-horaires{
    flex-grow: 1;
    height: 0;
    overflow-y: auto;
    display: flex;
    justify-content: flex-start;
    padding: 1rem;
    gap: 5px;
  }
  .col-horaires{
    flex-grow: 1;
    padding: 0.25rem;
  }
  .title-bask{
    font-size: 13px;
    text-align: center;
    border-bottom: solid ${props=>props.theme.Primary} 1px;
    padding-bottom: 0.25rem;
    margin-bottom: 12px;
  }
  
`


import React from "react";
import styled from "styled-components";
import {Outlet} from "react-router-dom";
import TimedHeader from "../../molecules/TimedHeader/TimedHeader";
import TimedFullSideBar from "../../molecules/TimedFullSideBar/TimedFullSideBar";
import {useModal} from "../../../hooks/useModal";
import TimedNotificationCurtain from "../../molecules/TimedNotificationsCenter/TimedNotificationCurtain";
import {UseQueryResult} from "react-query";

interface MainLayoutCtrlProps{
    className?:string;
    UserQuery:UseQueryResult<UserSimple, Error>
    FonctionsQuery:UseQueryResult<Fonction[], Error>,
    UsersQuery:UseQueryResult<User[], Error>,
    LieuxQuery:UseQueryResult<Lieu[], Error>
    ModulesQuery:UseQueryResult<Module[], Error>
    VuesQuery:UseQueryResult<VuePlanning[], Error>
}

const MainLayoutCtrl = ({className, UserQuery, UsersQuery, LieuxQuery, VuesQuery, ModulesQuery, FonctionsQuery}:MainLayoutCtrlProps)=>{
    const {open, toggle} = useModal();
    return (
        <div className={`MainLayout ${className}`}>
            <TimedFullSideBar UserQuery={UserQuery}/>
            <div className={`MainLay`}>
                <TimedHeader
                    UserQuery={UserQuery}
                    OpenNotif={open}
                    SwitchNotif={toggle}
                    FonctionsQuery={FonctionsQuery}
                    UsersQuery ={UsersQuery}
                    LieuxQuery ={LieuxQuery}
                    ModulesQuery={ModulesQuery}
                    VuesQuery={VuesQuery}
                />
                <div className={`MainContent`}>
                    <Outlet/>
                </div>
                <TimedNotificationCurtain Open={open} SwitchOpen={toggle}/>
            </div>
        </div>
    )
}

const MainLayout = styled(MainLayoutCtrl)`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: stretch;
  
  .MainLay{
    overflow: hidden;
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    .MainContent{
      overflow-y: auto;
      height: calc(100% - 90px);
    }
  }
`

export default MainLayout;
import React, {useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import {useGetUsersByFonction, useGetUsersByFonctionFAdm} from "../../../../features/user/user.hooks";
import {useGetAllLieu} from "../../../../features/lieu/lieu.hooks";
import {Controller, useForm} from "react-hook-form";
import TimedTimeChoice from "../../../../components/atoms/TimedTimeChoice/TimedTimeChoice";
import TimedSelectUser from "../../../../components/atoms/TimedSelectUser/TimedSelectUser";
import TimedSkeleton from "../../../../components/atoms/TimedSkeleton/TimedSkeleton";
import FormControl from "@mui/material/FormControl";
import {Autocomplete, MenuItem, TextField} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import {useGetAllHoraires} from "../../../../features/horaires/horaires.hooks";
import {OnQuickHor, OnQuickHorStyleTwo} from "../../../../assets/styles/elements";
import {ConvertBase5, ConvertToBase5} from "../../../../components/functions/TimesFunctions";
import TimedInputTextH from "../../../../components/atoms/TimedInputText/TimedInputTextH";
import {BiSave} from "react-icons/bi";
import TimedButton from "../../../../components/atoms/TimedButton/TimedButton";
import {useQueryClient} from "react-query";
import {useAddCreneau} from "../../../../features/creneau/creneau.hooks";
import {useCentreConnectedData} from "../../../../services/hooks";

interface FormNewCreneauCtrlProps{
    className?:string;
    Creneau:Creneau|null
    supervisor?:boolean;
}

interface DefaultCreneau{
    MedAReplace:string;
    MedReplace:string;
    niveau:number;
    Lieu: {
        id:number
        libelle:string,
        fontColor:string,
        backColor:string,
        isLieuAbs:boolean
    }|null;
    creneauAt:string;
    heureDebut:string;
    heureFin:string;
    flexiDeb:number;
    flexiFin:number;
    aide:string;
}
const EmptyValues:DefaultCreneau={
    MedAReplace:'',
    MedReplace:'',
    niveau:1,
    Lieu:null,
    creneauAt:'',
    heureDebut:'--:--',
    heureFin:'--:--',
    flexiDeb:0,
    flexiFin:0,
    aide:'oui'
}

interface OneHoaireQuick{
    lib:string;
    HD:string;
    HF:string;
}

const FormNewCreneauCtrl = ({className, Creneau, supervisor}:FormNewCreneauCtrlProps)=>{
    const centre = useCentreConnectedData();
    const mutation = useAddCreneau();
    const dateMin = new Date(new Date().getTime()-86400000*7).toISOString().slice(0,10);
    const queryClient = useQueryClient();
    const userConnected:UserSimple|undefined = queryClient.getQueryData(["user_connected"]);
    const MedecinRemplacantQuery = useGetUsersByFonctionFAdm(1, 3, supervisor ? centre : null);
    const MedecinARemplacerQuery = useGetUsersByFonction(["1"], supervisor ? centre : null);
    const [onSubmitting, setOnSubmitting] = useState(false);
    const HorairesQuery = useGetAllHoraires(supervisor ? centre : null);
    const LieuxQuery = useGetAllLieu(supervisor ? centre : null);
    const { register, handleSubmit, formState: { errors }, getValues, control, setValue, setError, watch, reset, clearErrors } = useForm({
        mode: 'onChange',
        defaultValues:EmptyValues
    })
    const ListHorairesQuick = useMemo(()=>{
        let listHQ:OneHoaireQuick[] = [];
        if(HorairesQuery.data){
            const HorairesSorted = HorairesQuery.data.sort((a:Horaires, b:Horaires)=>{
                return a.start < b.start ? -1 : 1;
            })
            const HoraireStart = HorairesSorted[0];
            const HoraireEnd = HorairesSorted[HorairesSorted.length - 1];
            listHQ = HorairesSorted.map(h=>{
                return {
                    lib:h.libelle,
                    HD:`${ConvertBase5(h.start).slice(0,2)}:${ConvertBase5(h.start).slice(-2)}`,
                    HF:`${ConvertBase5(h.end).slice(0,2)}:${ConvertBase5(h.end).slice(-2)}`,
                }
            })
            listHQ.push({
                lib:'Journée',
                HD:`${ConvertBase5(HoraireStart.start).slice(0,2)}:${ConvertBase5(HoraireStart.start).slice(-2)}`,
                HF:`${ConvertBase5(HoraireEnd.end).slice(0,2)}:${ConvertBase5(HoraireEnd.end).slice(-2)}`,
            })
        }
        return listHQ;
    }, [HorairesQuery.data])
    useEffect(()=>{
        if(Creneau === null){
            setValue("MedAReplace", "");
            reset()
        } else {
            const creneauAt = Creneau ? `${new Date(Creneau.creneauAt).getFullYear()}-${(`0` + (new Date(Creneau.creneauAt).getMonth() + 1)).slice(-2)}-${(`0` + new Date(Creneau.creneauAt).getDate()).slice(-2)}` : ''
            console.log('MEEED ', Creneau.UserReplaced.fullName);
            const myL = {
                id:Creneau.Lieu.id,
                libelle:Creneau.Lieu.libelle,
                fontColor:Creneau.Lieu.fontColor,
                backColor:Creneau.Lieu.backColor,
                isLieuAbs:Creneau.Lieu.isLieuAbs
            }
            const newDatas:DefaultCreneau={
                MedAReplace:Creneau.UserReplaced.fullName,
                MedReplace:Creneau.UserAffected ? Creneau.UserAffected.fullName : '',
                niveau:Creneau.niveau,
                Lieu:myL,
                creneauAt:creneauAt,
                heureDebut:ConvertBase5(Creneau.heureDebut),
                heureFin:ConvertBase5(Creneau.heureFin),
                flexiDeb:Creneau.flexiDebut,
                flexiFin:Creneau.flexiFin,
                aide:Creneau.aide ? 'oui' : 'non'
            }
            reset(newDatas)
        }
    }, [Creneau, reset, setValue])
    const watchHD = watch("heureDebut");
    const watchHF = watch("heureFin");
    useEffect(()=>{
        const HeureDebut = ConvertToBase5(watchHD);
        const HeureFin = ConvertToBase5(watchHF);
        if(HeureFin<=HeureDebut){
            setError("heureDebut", {type:"custom", message:"Erreur"})
            setError("heureFin", {type:"custom", message:"Erreur"})
        } else {
            clearErrors("heureDebut");
            clearErrors("heureFin");
        }
    }, [watchHD, watchHF, setError, clearErrors])
    const listLieux = useMemo(()=>{
        if(!LieuxQuery.isLoading && !LieuxQuery.isError && LieuxQuery.data){
            return LieuxQuery.data.map(l=>{
                return {
                    id:l.id,
                    libelle:l.libelle,
                    fontColor:l.fontColor,
                    backColor:l.backColor,
                    isLieuAbs:l.isLieuAbs
                }
            })
        }
    }, [LieuxQuery])

    const onSubmit = (data:any)=>{
        const myMedToReplace = MedecinARemplacerQuery.data?.find(m=>m.fullName === data.MedAReplace);
        let idMedRepl = null;
        if(data.MedReplace !== ''){
            const myMedRempla = MedecinRemplacantQuery.data?.find(m=>m.fullName === data.MedReplace);
            if(myMedRempla){
                idMedRepl = myMedRempla.id;
            }
        }

        const myAide = data.aide === 'oui';
        const HeureDebut = ConvertToBase5(data.heureDebut);
        const HeureFin = ConvertToBase5(data.heureFin);
        if (!myMedToReplace) {
            alert('erreur medecin');
        } else {
            const myCreneau: CreneauFD = {
                UserReplaced: `/api/users/${myMedToReplace.id}`,
                Lieu: `/api/lieus/${data.Lieu.id}`,
                creneauAt: data.creneauAt,
                heureDebut: HeureDebut,
                flexiDebut: parseInt(data.flexiDeb.toString()),
                heureFin: HeureFin,
                flexiFin: parseInt(data.flexiFin.toString()),
                aide: myAide,
                niveau: data.niveau,
                UserCreate: `/api/users/${userConnected ? userConnected.id : 3}`,
            }
            if (data.MedReplace !== '') {
                myCreneau.UserAffected = `/api/users/${idMedRepl}`;
            }
            setOnSubmitting(true);
            mutation.mutate((myCreneau), {
                onSuccess: () => {
                    setOnSubmitting(false);
                }, onError: () => {
                    setOnSubmitting(false);
                }
            });
        }
    }
    const handleQuickHor = (h:OneHoaireQuick)=>{
        setValue("heureDebut", h.HD)
        setValue("heureFin", h.HF)
    }
    return (
        <div className={`wrap-form-new-creneau ${className}`}>
            {MedecinARemplacerQuery.isLoading || MedecinRemplacantQuery.isLoading || LieuxQuery.isLoading ?
                <div className={"pad-ske"}><TimedSkeleton width={"100%"} type={"rect"} nbOccurence={4}/></div> :
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={"line-form"}>
                        <div className={`wrapMedChoice`}>
                            <TimedSelectUser
                                ChoicesUser={MedecinARemplacerQuery.data ? MedecinARemplacerQuery.data.filter(m=>m.FonctionAdm.id!==3) : []}
                                setFieldValue={setValue}
                                Current={watch("MedAReplace")}
                                label={"Medecin à Remplacer"}
                                placeHolder={"Choix médecin"}
                                id={"MedAReplace"}
                                isReq={true}
                            />

                        </div>
                        <div className={"line-form"}>
                            <Controller
                                name="Lieu"
                                control={control}
                                render={({ field:{onChange, value} }) =>(
                                    <FormControl sx={{ m: 1, minWidth: 180 }} size="small" fullWidth={true}>
                                        <Autocomplete
                                            id={"Lieu"}
                                            value={value}
                                            size="small"
                                            onChange={(e, data) => onChange(data)}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            options={listLieux === undefined ? [] : listLieux}
                                            getOptionLabel={(l)=>l.libelle}
                                            renderInput={(params)=>(
                                                <TextField
                                                    {...params}
                                                    label={"Lieu*"}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                )}

                            />
                            {!!errors.Lieu && <div className={"helper-text"}>Champ obligatoire</div>}
                        </div>
                        <div className={"line-form"}>
                            <Controller
                                name="niveau"
                                control={control}
                                render={({ field }) => <>
                                    <FormControl sx={{ m: 1,  width: "100%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">Niveau</InputLabel>
                                        <Select
                                            label="Niveau"
                                            {...field}

                                        >
                                            {[1,2,3].map((n:number, idx:number)=>(
                                            <MenuItem key={`onTC${idx}`} value={n}>{n}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </>}
                            />
                        </div>
                        <div className={`wrapMedChoice`}>
                            <TimedSelectUser
                                ChoicesUser={MedecinRemplacantQuery.data ? MedecinRemplacantQuery.data : []}
                                setFieldValue={setValue}
                                Current={watch("MedReplace")}
                                label={"Medecin remplaçant"}
                                placeHolder={"laisser en créneau à prendre"}
                                id={"MedReplace"}
                                isReq={false}
                            />
                        </div>
                        <div className={`line-form-v`} style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
                            <div className={`wrap-choix-aide`}>
                                <label htmlFor="aide-sans" style={{marginRight:"10px"}}>
                                    <input
                                        {...register("aide")}
                                        type="radio"
                                        value={"oui"}
                                        id={"aide-oui"}
                                    />
                                    <span style={{marginLeft:"5px"}}>Avec aide</span>
                                </label>
                                <label htmlFor="aide-sans">
                                    <input
                                        {...register("aide")}
                                        type="radio"
                                        value={"non"}
                                        id={"aide-sans"}
                                    />
                                    <span  style={{marginLeft:"5px"}}>Sans aide</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={`line-form`} style={{alignItems:"center"}}>
                        <div className={`wrap-date`}>
                            <TimedInputTextH
                                type={"date"}
                                min={dateMin}
                                id={"creneauAt"}
                                label={"Date du créneau"}
                                isRequired={true}
                                register={register}
                                optionRegister={{required:true, min:dateMin}}
                                getValue={getValues}
                                isError={!!errors.creneauAt}
                                helperText={(!!errors.creneauAt && errors.creneauAt.type === "min") ? "minimum "+new Date(dateMin).toLocaleDateString().slice(0,10) : !!errors.creneauAt ? "Champs obligatoire" : ""}
                                size={"small"}
                            />
                        </div>
                        <div className={`wrap-quick-horaires`}>
                            {HorairesQuery.isLoading ?
                                <div className={"pad-ske"}>
                                    <TimedSkeleton width={"100%"} type={"rect"} nbOccurence={1}/>
                                </div> :
                                <div className={`wrap-btn-quick`}>

                                    {ListHorairesQuick.map((h:OneHoaireQuick, idx:number)=>(
                                        <OnQuickHorStyleTwo key={`oneH${idx}`} onClick={()=>handleQuickHor(h)}>
                                            {h.lib}
                                        </OnQuickHorStyleTwo>
                                    ))}

                                </div>
                            }
                        </div>
                        <TimedTimeChoice
                            label={"Heure Debut"}
                            setFieldValue={setValue}
                            isRequired={true}
                            isError={!!errors.heureDebut}
                            helperText={!!errors.heureDebut ? "Non valide" : ""}
                            getValue={getValues}
                            value={watch("heureDebut")}
                            id={"heureDebut"}
                            hourStart={8}
                            hourStop={21}
                            posTop={"-100%"}
                            posLeft={"50%"}

                        />
                        <div className={`wrap-flex`} style={{width:"80px", marginRight:"10px"}}>
                            <TimedInputTextH
                                type={"number"}
                                id={"flexiDeb"}
                                label={"flex"}
                                isRequired={true}
                                register={register}
                                optionRegister={{required:true}}
                                getValue={getValues}
                                isError={!!errors.flexiDeb}
                                helperText={!!errors.flexiDeb ? "Non valide" : ""}
                                size={"small"}
                            />
                        </div>
                        <TimedTimeChoice
                            label={"Heure Fin"}
                            setFieldValue={setValue}
                            isRequired={true}
                            isError={!!errors.heureFin}
                            helperText={!!errors.heureFin ? "Non valide" : ""}
                            getValue={getValues}
                            value={watch("heureFin")}
                            id={"heureFin"}
                            hourStart={9}
                            hourStop={22}
                            posTop={"-100%"}
                            posLeft={"50%"}
                        />
                        <div className={`wrap-flex`} style={{width:"80px"}}>
                            <TimedInputTextH
                                type={"number"}
                                id={"flexiFin"}
                                label={"flex"}
                                isRequired={true}
                                register={register}
                                optionRegister={{required:true}}
                                getValue={getValues}
                                isError={!!errors.flexiFin}
                                helperText={!!errors.flexiFin ? "champ obligatoire" : ""}
                                size={"small"}
                            />
                        </div>
                        <div className={`wrap-send`} style={{display:"flex", justifyContent:"end", flexGrow:1, paddingRight:"0.5rem"}}>
                            <TimedButton
                                size={"md"}
                                themeColor={"Primary"}
                                children={<span className={`spanIc`}><BiSave/> Enregistrer</span>}
                                type={"button"}
                                onClick={handleSubmit(onSubmit)}
                                disabled={onSubmitting}
                                isPending={onSubmitting}
                            />
                        </div>

                    </div>
                </form>
            }

        </div>
    )
}

const FormNewCreneau = styled(FormNewCreneauCtrl)`
  background: white;
  padding: 1.5rem 0;
  .wrap-quick-horaires{
    margin: 0 20px;
    height: 100%;
    .wrap-btn-quick{
      display: flex;
      justify-content: start;
      gap:5px;
    }
  }
    .wrapMedChoice{
      width: 300px;
    }
`

export default FormNewCreneau;
import React, {useEffect, useState} from "react";
import styled, {useTheme} from "styled-components";
import {useUserConnected} from "../../../../../../services/hooks";
import TimedBlockGreyHead from "../../../../../../components/molecules/TimedBlockGreyHead/TimedBlockGreyHead";
import {BsFillCalendar2WeekFill} from "react-icons/bs";
import {AiOutlineFieldTime} from "react-icons/ai";
import {UseQueryResult} from "react-query";
import TimedSkeleton from "../../../../../../components/atoms/TimedSkeleton/TimedSkeleton";
import { GiUnbalanced } from "react-icons/gi";

interface DetailsHeuresCtrlProps{
    className?:string;
    libDate:string;
    CptQuery:UseQueryResult<CompteurTempsDateDate[], Error>;
    UserQuery:UseQueryResult<User, Error>;
    dateUseStart:string;
}
const heightWait = "120px"

const DetailsHeuresCtrl = ({className, libDate, CptQuery, UserQuery, dateUseStart}:DetailsHeuresCtrlProps)=>{
    const theme = useTheme();
    const [CptData, setCptData] = useState<CompteurTempsDateDate|null>(null);
    const {userConnected} = useUserConnected();
    const [isAnnualisation, setIsAnnualisation] = useState<boolean>(false);
    const [comMoisCurrent, setComMoisCurrent] = useState('')
    useEffect(()=>{
        if(CptQuery.data){
            setCptData(CptQuery.data[0])
        }
    }, [CptQuery])
    useEffect(()=>{
        const Today = new Date();
        const DateStartDate = new Date(dateUseStart);
        if(DateStartDate.getMonth() === Today.getMonth()){
            const DateVeille = new Date(Today.getTime() - 86400000)
            setComMoisCurrent("(Mois en cours, date arrêt des compteurs au " + DateVeille.toLocaleDateString() + ")")
        } else {
            setComMoisCurrent('');
        }
    }, [dateUseStart])

    useEffect(()=>{
        if(userConnected){
            const CentreParameters = userConnected.Centre.parameters;
            const isAnnualisation = CentreParameters.find(c=>c.Parameter.id === 6)
            if(isAnnualisation){
                setIsAnnualisation(isAnnualisation.statut)
            }
        }
    }, [userConnected])
    return (
        <div className={`details-heures ${className}`}>
            <div className={`title`}>details heures : {libDate} {comMoisCurrent}</div>
            <div className={`wrap-blocks`}>
                <div className={"one-block"}>
                    <TimedBlockGreyHead icon={<BsFillCalendar2WeekFill/>} title={"Heures planning"}>
                        {(CptQuery.isLoading || UserQuery.isLoading) ?
                            <TimedSkeleton width={"100%"} heightPerso={heightWait} nbOccurence={1} type={"perso"}/> :
                            !CptData ? 'No Data':
                            <div className={`wrap-number`}>
                                <div className={"info-number"}>
                                    <div className={`lib`}>Heures<br/>de travail</div>
                                    <div className={`number`} style={{color:theme.PrimaryExtraDark}}>{Math.round(CptData.heuresFaitesPlanning*100)/100}</div>
                                </div>
                                <div className={"info-number"}>
                                    <div className={`lib`}>Heures<br/>Non travaillées</div>
                                    <div className={`number`} style={{color:theme.TertiaryDark}}>{Math.round(CptData.heuresNoWPlanning*100)/100}</div>
                                </div>
                                <div className={"info-number"}>
                                    <div className={`lib`}>Total</div>
                                    <div className={`number ${(CptData.heuresFaitesPlanning+CptData.heuresNoWPlanning)>=CptData.heuresAFaire ? 'success' : 'warning'}`}>
                                        {Math.round((CptData.heuresFaitesPlanning+CptData.heuresNoWPlanning)*100)/100}
                                    </div>
                                </div>
                                <div className={"info-number"}>
                                    <div className={`lib`}>Ecart</div>
                                    <div className={`number ${CptData.SoldePlanning>=0 ? 'success' : 'warning'}`}>{Math.round(CptData.SoldePlanning*100)/100}</div>
                                </div>
                            </div>
                        }
                    </TimedBlockGreyHead>
                </div>
                <div className={"one-block"}>
                    <TimedBlockGreyHead icon={<BsFillCalendar2WeekFill/>} title={"Heures relevées"}>
                        {(CptQuery.isLoading || UserQuery.isLoading) ?

                            <TimedSkeleton width={"100%"} heightPerso={heightWait} nbOccurence={1} type={"perso"}/> :
                            !CptData ? 'noData':
                            <div className={`wrap-number`}>
                                <div className={"info-number"}>
                                    <div className={`lib`}>Heures<br/>de travail</div>
                                    <div className={`number`} style={{color:theme.PrimaryExtraDark}}>{Math.round(CptData.heuresFaitesRelevees*100)/100}</div>
                                </div>
                                <div className={"info-number"}>
                                    <div className={`lib`}>Heures<br/>Non travaillées</div>
                                    <div className={`number`} style={{color:theme.TertiaryDark}}>{Math.round(CptData.heuresNoWRelevees*100)/100}</div>
                                </div>
                                <div className={"info-number"}>
                                    <div className={`lib`}>Total</div>
                                    <div className={`number ${(CptData.heuresFaitesRelevees + CptData.heuresNoWRelevees+CptData.regules)>=CptData.heuresAFaire ? 'success' : 'warning'}`}>
                                        {Math.round((CptData.heuresFaitesRelevees + CptData.heuresNoWRelevees+CptData.regules)*100)/100}
                                    </div>
                                </div>
                                <div className={"info-number"}>
                                    <div className={`lib`}>Ecart</div>
                                    <div className={`number ${(CptData.SoldeRelevees + CptData.heuresNoWRelevees)>=CptData.heuresAFaire ? 'success' : 'warning'}`}>{Math.round(CptData.SoldeRelevees*100)/100}</div>
                                </div>

                            </div>
                        }
                    </TimedBlockGreyHead>
                </div>
                {CptData && CptData.regules!==0 &&
                    <div className={"one-block"}>
                        <TimedBlockGreyHead icon={<GiUnbalanced/>} title={"Régules"}>
                            <div className={"info-number"}>
                                <div className={`lib`}>Régules</div>
                                <div className={`number`} style={{color:theme.PrimaryExtraDark}}>
                                    {Math.round(CptData.regules*100)/100}
                                </div>
                            </div>
                        </TimedBlockGreyHead>
                    </div>
                }
                <div className={"one-block"}>
                    <TimedBlockGreyHead icon={<AiOutlineFieldTime/>} title={"Compteurs"}>
                        {(CptQuery.isLoading || UserQuery.isLoading) ?
                            <TimedSkeleton width={"100%"} heightPerso={heightWait} nbOccurence={1} type={"perso"}/> :
                            !CptData ? 'NoData':
                            <div className={`wrap-number`}>
                                {isAnnualisation &&
                                    <div className={"info-number"}>
                                        <div className={`lib`}>Compteur<br/>Début mois</div>
                                        <div className={`number ${CptData.soldeInRelevees > 0 ? 'success' : 'warning'}`}>
                                            {`${CptData.soldeInRelevees > 0 ? '+' : ''}${Math.round(CptData.soldeInRelevees*100)/100}`}
                                        </div>
                                    </div>
                                }
                                <div className={"info-number"}>
                                    <div className={`lib`}>Compteur<br/>du mois</div>
                                    <div className={`number ${CptData.SoldeRelevees>0 ? 'success' : 'warning'}`}>
                                        {`${CptData.SoldeRelevees>0 ? '+' : ''}${Math.round(CptData.SoldeRelevees*100)/100}`}
                                    </div>
                                </div>
                                {isAnnualisation &&
                                    <div className={"info-number"}>
                                        <div className={`lib`}>Compteur<br/>fin du mois</div>
                                        <div className={`number ${CptData.soldeOutRelevees > 0 ? 'success' : 'warning'}`}>
                                            {`${CptData.soldeOutRelevees > 0 ? '+' : ''}${Math.round(CptData.soldeOutRelevees*100)/100}`}
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </TimedBlockGreyHead>
                </div>
            </div>
        </div>
    )
}

const DetailsHeures = styled(DetailsHeuresCtrl)`
  padding: 20px;
  .info-number{
    flex-grow: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    .lib{
      font-size: 12px;
      text-align: center;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      
    }
    .number{
      text-align: center;
      height: 21px;
      font-weight: bold;
      &.success{
        color: ${props=>props.theme.SuccessDark};
      }
      &.warning{
        color: ${props=>props.theme.WarningDark};
      }
    }
  }
  .wrap-number{
    display: flex;
    justify-content: start;
    align-items: stretch;
    gap: 0.5rem;
    .info-number{
      height: auto;
    }
  }
  .title{
    font-size: 14px;
    color:${props=>props.theme.Dark};
    font-weight: bold;
    margin-bottom: 10px;
  }
    .wrap-blocks{
      width: 100%;
      display: flex;
      gap: 20px;
      justify-content: start;
      align-items: stretch;
      .one-block{
        flex-grow: 1;
      }
    }
`

export default DetailsHeures;
import React, {useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import {UseGetPointeuseTreatByParams} from "../../../../../../features/pointeuseTreat/pointeuseTreat.hooks";
import {MinutesToHHMM} from "../../../../../../components/functions/TimesFunctions";
import TimedTable from "../../../../../../components/molecules/TimedTable/TimedTable";
import {useCentreConnectedData} from "../../../../../../services/hooks";

interface TabDailyCtrlProps{
    className?:string;
    dateUseEnd:string;
    idUser:number;
    setPointeurTreat:Function;
    setFerie:Function;
    supervisor?:boolean;
}

const TitlesTab:TitleTable[]=[
    {libelle:'Date', field:'dateAT', fieldSort:'dateAT', date:true, textAlign:'text-center', StyleHead:{textAlign:"center"}},
    {libelle:'Lieu', field:'lieu', fieldSort:'lieu', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
    {libelle:'Acte', field:'acte', fieldSort:'acte', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
    {libelle:'Heures Planning', field:'heures_pl', fieldSort:'heuresNB', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
    {libelle:'Temps Planning', field:'time_pl', fieldSort:'timeNB', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
    {libelle:'Heures Relevées', field:'heures_rel', fieldSort:'heuresrelNB', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
    {libelle:'Temps Relevé', field:'time_rel', fieldSort:'timerelNB', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
    {libelle:'Diff.', field:'diff', fieldSort:'diff', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}}

]

const TabDailyCtrl = ({className, dateUseEnd, idUser, setPointeurTreat, setFerie, supervisor}:TabDailyCtrlProps)=>{
    const centre = useCentreConnectedData();
    const [params, setParams] = useState<any|undefined>(undefined)
    const PTQuery = UseGetPointeuseTreatByParams(params);
    useEffect(()=>{
        const now = new Date();
        let dateS = new Date(new Date(dateUseEnd).getFullYear(), new Date(dateUseEnd).getMonth(), 1);
        dateS = new Date(dateS.getTime() - dateS.getTimezoneOffset()*60000);
        const dateE = new Date(dateUseEnd).getMonth() === now.getMonth() ? new Date(now.getTime() - 86400000) : new Date(dateUseEnd);
        let paramsX:any = {"dateAT[after]":dateS.toISOString().slice(0,10), "dateAT[before]":dateE.toISOString().slice(0,10), User:idUser};
        if(supervisor){
            paramsX.Centre = centre;
        }
        setParams(paramsX)
    }, [dateUseEnd, idUser, supervisor, centre])
    const handleClickLine = (data:any)=>{
        setPointeurTreat(data.pt)
    }
    const lines = useMemo(()=>{
        let myLines:any[] = [];
        if(PTQuery.data){
            console.log(PTQuery.data.length);
            myLines = PTQuery.data.sort((a:PointeuseTreat, b:PointeuseTreat)=>(new Date(a.dateAT).getTime()+a.planningDebut) > (new Date(b.dateAT).getTime()+b.planningDebut) ? 1 : -1).map(pt=>{
                const lieux = pt.MotifReguleTemps ? pt.MotifReguleTemps.libelle :  pt.plages.map(p=>p.Lieu.libelle).join(', ')
                const actes = pt.MotifReguleTemps ? pt.MotifReguleTemps.libelle : pt.plages.map(p=>p.Acte.libelle).join(', ')

                const heuresPLDeb = pt.MotifReguleTemps ? '--' : MinutesToHHMM(pt.planningDebut);
                const heuresPLFin = pt.MotifReguleTemps ? '--' : MinutesToHHMM(pt.planningFin)
                const heuresPL = heuresPLDeb + ' - ' + heuresPLFin
                const time_pl = pt.plages.reduce((acc:number, p:Plage)=>acc+p.myTime, 0)

                const heuresREL_deb = pt.pointageEntreeFinal ? MinutesToHHMM(pt.pointageEntreeFinal) : '--'
                const heuresREL_fin = pt.pointageSortieFinal ? MinutesToHHMM(pt.pointageSortieFinal) : '--'
                const heuresREL = heuresREL_deb + ' - ' + heuresREL_fin
                const timeRel = pt.timeFinal ? pt.timeFinal : 0
                const diff = Math.round((timeRel - time_pl)*100)/100;
                return {
                    id:pt.id,
                    pt:pt,
                    dateAT:pt.dateAT,
                    lieu:lieux,
                    acte:actes,
                    heures_pl:heuresPL,
                    heuresNB:pt.planningDebut,
                    time_pl:Math.round(time_pl*100)/100,
                    timeNB:Math.round(time_pl*100)/100,
                    heures_rel:heuresREL,
                    time_rel:Math.round(timeRel*100)/100,
                    timerelNB:Math.round(timeRel*100)/100,
                    diff:diff
                }
            })
        }
        return myLines;
    }, [PTQuery.data])
    return (
        <div className={`tab-daily ${className}`}>
            <TimedTable
                Titles={TitlesTab}
                sortable={true}
                themeColor={"Primary"}
                Outline={true}
                Lines={lines ? lines : []}
                scrollable={true}
                loading={PTQuery.isLoading}
                maxHeight={"600px"}
                onClickLine={handleClickLine}
            />
        </div>
    )
}

export const TabDaily = styled(TabDailyCtrl)`
    height: 610px;
`
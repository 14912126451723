import React from 'react';
import './App.css';
import {ThemeProvider} from "styled-components";
import TimedTheme from "../assets/styles/TimedTheme";
import GlobalStyle from '../assets/styles/Global';
import {QueryClient, QueryClientProvider} from "react-query";
import {Routes, Route, Navigate} from "react-router-dom";
import PrivateRoute from "../components/security/PrivateRoute";
import ApplicationController from "../pages/TimedApplication/ApplicationController";
import PublicRoute from "../components/security/PublicRoute";
import Login from '../pages/Login/Login';
import NotFound from '../pages/NotFound/NotFound';
import { ReactQueryDevtools } from "react-query/devtools";
import MainTest from "../pages/Tests/MainTest";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Confidential from "../pages/Confidential/Confidential";
import WebmasterRoute from "../components/security/WebmasterRoute";
import {WebMasterApplication} from "../pages/WebMasterApplication/WebMasterApplication";
import {ViewportProvider} from "../services/providers/ViewportProvider";
import {LoginPre} from "../pages/Login/LoginPre";
import RestimeTheme from "../assets/styles/RestimeTheme";
import SupervisorRoute from "../components/security/SupervisorRoute";
import SupervisorController from "../pages/SupervisorApplication/SupervisorController";

const queryClient = new QueryClient();

function App() {
  return (
    <ThemeProvider theme={RestimeTheme}>
      <GlobalStyle />
        <QueryClientProvider client={queryClient}>
            <ViewportProvider>
              <div className="App" style={{height:"100%", userSelect:"none"}}>
                  <Routes>
                      <Route element={<PrivateRoute from={"App"}/>}>
                          <Route path="/timed_application/*" element={<ApplicationController />} />
                      </Route>
                      <Route element={<WebmasterRoute from={"App"}/>}>
                          <Route path="/webmaster_application/*" element={<WebMasterApplication />} />
                      </Route>
                      <Route element={<SupervisorRoute from={"App"}/>}>
                          <Route path={"/supervisor_application/*"} element={<SupervisorController/>} />
                      </Route>
                      <Route element={<PublicRoute/>}>
                          <Route path="/" element={<Navigate to="/login" />} />
                          <Route path="/login" element={<LoginPre />} />
                          <Route path="/confidential" element={<Confidential/>} />
                      </Route>
                      <Route path="/tests" element={<MainTest />}/>
                      <Route path="*" element={<NotFound />} />
                  </Routes>
                  <ToastContainer
                      position="bottom-right"
                      autoClose={5000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                  />
              </div>
            </ViewportProvider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;

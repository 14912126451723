import React, {lazy, Suspense} from "react";
import {Routes, Route, Navigate} from "react-router-dom";
import styled from "styled-components";
import MainLayout from "../../components/organisms/layouts/MainLayout";



import {useGetAllUser, useGetUser} from "../../features/user/user.hooks";

import TimedWaitUser from "../../components/atoms/TimedWaitUser/TimedWaitUser";
import {useGetFonctions} from "../../features/fonction/fonction.hooks";
import {useGetAllLieu} from "../../features/lieu/lieu.hooks";
import {useGetModules} from "../../features/module/module.hooks";
import {useGetVuePl} from "../../features/vuePlanning/vuePlanning.hooks";
import TimedSuspense from "../../components/atoms/TimedWaitUser/TimedSuspense";
import {ModalOptIn} from "../Modaux/ModalOptIn";
import NotFound from "../NotFound/NotFound";
import {ErrorBoundary} from "../../services/ErrorBoundary";
import PlanningGlobalPersonne2 from "./Plannings/PlanningGlobalPersonne/PlanningGlobalPersonne2";
import TestPage from "./Plannings/PlanningIndiHebdo/TestPage";



interface ApplicationControllerCtrlProps{
    className?:string;

}
const CloturePeriode = lazy(()=>import("./TempsActivites/CloturePeriode/CloturePeriode"))
const PlanningDashboard = lazy(()=>import("./Plannings/PlanningDashboard/PlanningDashboard"))
const ListRemplacant = lazy(()=>import("./Remplacements/ListeRemplacant/ListRemplacant"))
const DashboardPointage = lazy(()=>import("./Pointages/Dashboard/DashboardPointage"));
const HistoriquePointage = lazy(()=>import("./Pointages/Historique/HistoriquePointage"));
const DashboardRH = lazy(()=>import("./AdministrationRH/DashboardRH/DashboardRH"));
const CompteursEtDroitsAbs = lazy(()=>import("./CongesAbsences/CompteursEtDroitsAbs/CompteursEtDroitsAbs"));
const Dashboard = lazy(()=>import("./MainStream/Dashboard"));
const FichePersonnel = lazy(()=>import("./AdministrationRH/FichePersonnel"));
const TicketRestaurant = lazy(()=>import("./AdministrationRH/TicketRestaurant"));
const EtatPreparatoirePaie = lazy(()=>import("./AdministrationRH/EtatPreparatoirePaie/EtatPreparatoirePaie"));
const Trombinoscope = lazy(()=>import("./AdministrationRH/Trombinoscore/Trombinoscope"));
const GenerationPlanning = lazy(()=>import("./Plannings/GenerationPlanning/GenerationPlanning"));
const SuppressionPlanning = lazy(()=>import("./Plannings/SuppressionPlanning/SuppressionPlanning"));
const ModificationPlanning = lazy(()=>import("./Plannings/ModificationPlanning/ModificationPlanning"));
const PlanningIndiHebdo = lazy(()=>import("./Plannings/PlanningIndiHebdo/PlanningIndiHebdo"));
const PlanningIndiMensuel = lazy(()=>import("./Plannings/PlanningIndiMensuel/PlanningIndiMensuel"));
const SemaineType = lazy(()=>import("./Plannings/SemaineType/SemaineType"));
const PlanningGlobalLieu = lazy(()=>import("./Plannings/PlanningGlobalLieu/PlanningGlobalLieu"));
const EtatVisuelAbsence = lazy(()=>import("./Plannings/EtatVisuelAbsence/EtatVisuelAbsence"));
const DashboardTps = lazy(()=>import("./TempsActivites/DashboardTps/DashboardTps"));
const TempsMedecin = lazy(()=>import("./TempsActivites/TempsMedecin/TempsMedecin"));
const EquivalentTempsPlein = lazy(()=>import("./TempsActivites/EquivalentTempsPlein/EquivalentTempsPlein"));
const TableauCroiseDemiJours = lazy(()=>import("./TempsActivites/TableauCroiseDemiJours/TableauCroiseDemiJours"));
const RechercheEvenements = lazy(()=>import("./TempsActivites/RechercheEvenements/RechercheEvenements"));
const StatsAbsences = lazy(()=>import("./TempsActivites/StatsAbsences/StatsAbsences"));
const GestionPointages = lazy(()=>import("./Pointages/GestionPointages/GestionPointages"));
const CreneauxEnCours = lazy(()=>import("./Remplacements/CreneauxEnCours/CreneauxEnCours"));
const DashboardAbs = lazy(()=>import("./CongesAbsences/DashboardAbs/DashboardAbs"));
const DemandesAbs = lazy(()=>import("./CongesAbsences/DemandesAbs/DemandesAbs"));
const Messagerie = lazy(()=>import("./Messagerie/MessagerieIn/Messagerie"));
const News = lazy(()=>import("./VieCentre/News/News"));
const Support = lazy(()=>import("./VieCentre/Support/Support"));
const Documents = lazy(()=>import("./VieCentre/Documents/Documents"));
const DashboardSetting = lazy(()=>import("./Settings/DashboardSetting/DashboardSetting"));
const ProfilCentre = lazy(()=>import("./Settings/ProfilCentre/ProfilCentre"));
const SettingGeneral = lazy(()=>import("./Settings/SettingGeneral/SettingGeneral"));
const SettingFonctions = lazy(()=>import("./Settings/SettingFonctions/SettingFonctions"));
const SettingActes = lazy(()=>import("./Settings/SettingActes/SettingActes"));
const SettingLieux = lazy(()=>import("./Settings/SettingLieux/SettingLieux"));
const SettingVuePlanning = lazy(()=>import("./Settings/SettingVuePlanning/SettingVuePlanning"));
const PlanningGlobalPersonne = lazy(()=>import("./Plannings/PlanningGlobalPersonne/PlanningGlobalPersonne"));
const TauxAffectation = lazy(()=>import("./Plannings/TauxAffectation/TauxAffectation"));
const DashboardCreneaux = lazy(()=>import("./Remplacements/DashCreneaux/DashboardCreneaux"));
const CoherencePlanningCurve = lazy(()=>import("./Plannings/CoherencePlanning/CoherencePlanningCurve"));
const TempsTravaille = lazy(()=>import("./TempsActivites/TempsTravaille/TempsTravaille"));
const Exports = lazy(()=>import("./TempsActivites/Exports/Exports"));
const PlanningGlobalLieu3 = lazy(()=>import("./Plannings/PlanningGlobalLieu/PlanningGlobalLieu3"));
const EventGroup = lazy(()=>import("./Plannings/EventGroup/EventGroup"));
const GestionHeuresSupp = lazy(()=>import("./TempsActivites/GestionHeuresSupp/GestionHeuresSupp"));

const ApplicationControllerCtrl = ({className}:ApplicationControllerCtrlProps)=>{
    const Test = false;
    const UserQuery = useGetUser();
    const FonctionsQuery = useGetFonctions();
    const UsersQuery = useGetAllUser();
    const LieuxQuery = useGetAllLieu();
    const ModulesQuery = useGetModules();
    const VuesQuery = useGetVuePl();
    return (
        <div className={`AppController ${className}`}>
            {(UserQuery.isLoading || UserQuery.data === undefined || FonctionsQuery.isLoading || LieuxQuery.isLoading || ModulesQuery.isLoading || VuesQuery.isLoading || Test) ?
                <TimedWaitUser
                    UserLoading={UserQuery.isLoading || Test}
                    FonctionsQuery={FonctionsQuery.isLoading}
                    LieuxLoading={LieuxQuery.isLoading}
                    VuesLoading={VuesQuery.isLoading}
                />:
                <Suspense fallback={<TimedSuspense/>}>
                    <ErrorBoundary>
                        <Routes>
                            <Route element={
                                <MainLayout
                                    UserQuery={UserQuery}
                                    FonctionsQuery={FonctionsQuery}
                                    UsersQuery ={UsersQuery}
                                    LieuxQuery ={LieuxQuery}
                                    ModulesQuery={ModulesQuery}
                                    VuesQuery={VuesQuery}

                                />
                            }>
                                <Route path="" element={<Navigate to="dashboard" />} />
                                <Route path="/testPage" element={<TestPage/>}/>
                                <Route path="/planning_personne_beta" element={<PlanningGlobalPersonne2/>}/>
                                <Route path="/dashboard" element={<Dashboard/>}/>
                                <Route path={`/rh_dashboard`} element={<DashboardRH/>}/>
                                <Route path="/fiche_personnel" element={<FichePersonnel/>}/>
                                <Route path="/tickets_restaurant" element={<TicketRestaurant/>}/>
                                <Route path="/etat_preparatoire_paie" element={<EtatPreparatoirePaie/>}/>
                                <Route path="/trombinoscope" element={<Trombinoscope/>}/>
                                <Route path="/generation_planning" element={<GenerationPlanning/>}/>
                                <Route path="/group_event" element={<EventGroup/>}/>
                                <Route path="/suppression_planning" element={<SuppressionPlanning/>}/>
                                <Route path="/modification_planning" element={<ModificationPlanning/>}/>
                                <Route path="/planning_indi_hebdo" element={<PlanningIndiHebdo/>}/>
                                <Route path="/planning_indi_mensuel" element={<PlanningIndiMensuel/>}/>
                                <Route path="/semaine_type" element={<SemaineType/>}/>
                                <Route path="/planning_dashboard" element={<PlanningDashboard/>}/>
                                <Route path="/planning_global_lieu" element={<PlanningGlobalLieu3/>}/>
                                <Route path="/planning_global_equipe" element={<PlanningGlobalLieu3/>}/>
                                <Route path="/planning_global_personne" element={<PlanningGlobalPersonne/>}/>

                                <Route path="/etat_visuel_absence" element={<EtatVisuelAbsence/>}/>
                                <Route path="/etat_visuel_absence2" element={<EtatVisuelAbsence/>}/>
                                <Route path="/taux_affectation" element={<TauxAffectation/>}/>
                                <Route path="/taux_affectation2" element={<TauxAffectation/>}/>
                                <Route path="/coherence_planning" element={<CoherencePlanningCurve/>}/>
                                <Route path="/tps_dashboard" element={<DashboardTps/>}/>
                                <Route path="/temps_medecin" element={<TempsMedecin/>}/>
                                <Route path="/temps_travaille" element={<TempsTravaille/>}/>
                                <Route path="/heures_supp" element={<GestionHeuresSupp/>}/>
                                <Route path="/exports" element={<Exports/>}/>
                                <Route path="/equivalent_temps_plein" element={<EquivalentTempsPlein/>}/>
                                <Route path="/stats_activites" element={<TableauCroiseDemiJours/>}/>
                                <Route path="/recherche_evenements" element={<RechercheEvenements/>}/>
                                <Route path="/stats_abs" element={<StatsAbsences/>}/>
                                <Route path="/stats_absence" element={<StatsAbsences/>}/>
                                <Route path="/dashboard_pointages" element={<DashboardPointage/>}/>
                                <Route path="/historique_pointages" element={<HistoriquePointage/>}/>
                                <Route path="/gestion_pointages" element={<GestionPointages/>}/>
                                <Route path="/creneaux" element={<CreneauxEnCours/>}/>
                                <Route path="/creneaux_dashboard" element={<DashboardCreneaux/>}/>
                                <Route path="/list_remplacants" element={<ListRemplacant/>}/>
                                <Route path="/abs_dashboard" element={<DashboardAbs/>}/>
                                <Route path="/demandes_abs" element={<DemandesAbs/>}/>
                                <Route path="/compteurs_droits_abs" element={<CompteursEtDroitsAbs/>}/>
                                <Route path="/messagerie" element={<Messagerie  UserQuery={UserQuery}/>}/>
                                <Route path="/news" element={<News/>}/>
                                <Route path="/support" element={<Support/>}/>
                                <Route path="/autres_document" element={<Documents/>}/>
                                <Route path="/setting_dashboard" element={<DashboardSetting/>}/>
                                <Route path="/profil_centre" element={<ProfilCentre/>}/>
                                <Route path="/setting_general" element={<SettingGeneral/>}/>
                                <Route path="/setting_fonctions" element={<SettingFonctions/>}/>
                                <Route path="/setting_actes" element={<SettingActes/>}/>
                                <Route path="/setting_lieux" element={<SettingLieux/>}/>
                                <Route path="/setting_vue_planning" element={<SettingVuePlanning/>}/>
                                <Route path="/cloture_periode" element={<CloturePeriode/>}/>
                                <Route path="*" element={<NotFound />} />
                            </Route>
                        </Routes>
                    </ErrorBoundary>
                </Suspense>

            }
            {UserQuery.data && UserQuery.data.optIn === null &&
                <ModalOptIn user={UserQuery.data}/>
            }
        </div>
    )
}

const ApplicationController = styled(ApplicationControllerCtrl)``

export default ApplicationController;
import React, {useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import {UseQueryResult} from "react-query";
import TimedSkeletonTable from "../../../../../components/atoms/TimedSkeleton/TimedSkeletonTable";
import TimedRoundAvatar from "../../../../../components/atoms/TimedRoundAvatar/TimedRoundAvatar";
import TimedTable from "../../../../../components/molecules/TimedTable/TimedTable";
import {useNavigate} from "react-router-dom";

interface TableTempsTravailleMultiCtrlProps{
    className?:string;
    CptQuery:UseQueryResult<CompteurTempsDateDate[], Error>
    dateStart:Date;
    dateEnd:Date;
    setCsvData:(data:any)=>void;
    isAnnualisation:boolean;
}

const TitlesTabAnnualisation:TitleTable[]=[
    {libelle:'Pic', field:'avatar', fieldSort:'nom', date:false, textAlign:'text-center', width:"80px", StyleHead:{textAlign:"center"}},
    {libelle:'Prénom et nom', field:'fullName', fieldSort:'nom', date:false, width:"15%", textAlign:'', StyleHead:{textAlign: "left"}},
    {libelle:'Fonction', field:'fonctionLib', fieldSort:'fonctionLib', date:false, textAlign:'', StyleHead:{textAlign: "left"}},
    {libelle:'Contrat', field:'contratLib', fieldSort:'contratLib', date:false, textAlign:'', StyleHead:{textAlign: "left"}},
    {libelle:'Solde début mois', field:'SoldeInObj', fieldSort:'SoldeInNb', date:false, textAlign:'text-right', StyleHead:{textAlign:"right"}},
    {libelle:'A réaliser', field:'heuresAFaire', fieldSort:'heuresAFaire', date:false, textAlign:'text-right', StyleHead:{textAlign:"right"}},
    {libelle:'travaillé', field:'heuresFaites', fieldSort:'heuresFaites', date:false, textAlign:'text-right', StyleHead:{textAlign:"right"}},
    {libelle:'Non travaillé', field:'heuresNoWork', fieldSort:'heuresNoWork', date:false, textAlign:'text-right', StyleHead:{textAlign:"right"}},
    {libelle:'Régules', field:'heuresRegules', fieldSort:'heuresRegules', date:false, textAlign:'text-right', StyleHead:{textAlign:"right"}},
    {libelle:'Compteur mois', field:'CompteurObj', fieldSort:'CompteurMonth', date:false, textAlign:'text-right', StyleHead:{textAlign:"right"}},
    {libelle:'Cumul fin mois', field:'SoldeOutObjet', fieldSort:'SoldeOut', date:false, textAlign:'text-right', StyleHead:{textAlign:"right"}},
]
const TitlesTabNonAnnualisation:TitleTable[]=[
    {libelle:'Pic', field:'avatar', fieldSort:'nom', date:false, textAlign:'text-center', width:"80px", StyleHead:{textAlign:"center"}},
    {libelle:'Prénom et nom', field:'fullName', fieldSort:'nom', date:false, textAlign:'', StyleHead:{textAlign: "left"}},
    {libelle:'Fonction', field:'fonctionLib', fieldSort:'fonctionLib', date:false, textAlign:'', StyleHead:{textAlign: "left"}},
    {libelle:'Contrat', field:'contratLib', fieldSort:'contratLib', date:false, textAlign:'', StyleHead:{textAlign: "left"}},
    {libelle:'A réaliser', field:'heuresAFaire', fieldSort:'heuresAFaire', date:false, textAlign:'text-right', StyleHead:{textAlign:"right"}},
    {libelle:'travaillé', field:'heuresFaites', fieldSort:'heuresFaites', date:false, textAlign:'text-right', StyleHead:{textAlign:"right"}},
    {libelle:'Non travaillé', field:'heuresNoWork', fieldSort:'heuresNoWork', date:false, textAlign:'text-right', StyleHead:{textAlign:"right"}},
    {libelle:'Régules', field:'heuresRegules', fieldSort:'heuresRegules', date:false, textAlign:'text-right', StyleHead:{textAlign:"right"}},
    {libelle:'Compteur mois', field:'CompteurObj', fieldSort:'CompteurMonth', date:false, textAlign:'text-right', StyleHead:{textAlign:"center"}},
]

const LineFootDefault={
    avatar:'',
    fullName:'',
    fonctionLib:'',
    contratLib:'',
    SoldeInObj:0,
    heuresAFaire:0,
    heuresFaites:0,
    heuresNoWork:0,
    heuresRegules:0,
    CompteurObj:0,
    SoldeOutObjet:0
}
const LineFootDefaultNA={
    avatar:'',
    fullName:'',
    fonctionLib:'',
    contratLib:'',
    heuresAFaire:0,
    heuresFaites:0,
    heuresNoWork:0,
    heuresRegules:0,
    CompteurObj:0,
}


const TableTempsTravailleMultiCtrl = ({className, CptQuery, dateStart, dateEnd, setCsvData, isAnnualisation}:TableTempsTravailleMultiCtrlProps)=>{
    const navigate = useNavigate();
    const [TabTitles, setTabTitles] = useState(TitlesTabNonAnnualisation);
    const [lineFoot, setLineFoot] = useState<any>(LineFootDefault)
    useEffect(()=>{
        if(isAnnualisation){
            setTabTitles(TitlesTabAnnualisation);
        }
    }, [isAnnualisation])
    const lines = useMemo(()=>{
        let myLines:any[] = [];
        if(CptQuery.data){
            CptQuery.data.map((Cpt:CompteurTempsDateDate)=>{
                const user = Cpt.User;
                const Contrats = user.contrats;
                const ContratsConc = Contrats.filter(c=>new Date(c.startAt)<=dateEnd && (!c.endAt || new Date(c.endAt.slice(0,10))>=dateStart))
                if(ContratsConc.length>0) {
                    const TabContratLib = ContratsConc.map(c => {
                        const ContratLib = c.TypeContrat.libelle
                        const ContratVolume = c.quantite
                        const ContratUnite = c.uniteTps
                        const ContratRef = c.RefTempsTravail.libelle
                        return `${ContratLib} ${ContratVolume}${ContratUnite}/${ContratRef}`
                    })
                    const ContratLib = ContratsConc.length === 0 ? 'x' : TabContratLib.join(', ');
                    const SoldeInNb = Math.round(Cpt.soldeIn * 100) / 100;
                    const HeureContrat = Cpt.heuresAFaire;
                    const SoldeMois = Math.round(Cpt.Solde * 100) / 100;
                    const HeureFaites = Cpt.heuresFaites;
                    const Pc = HeureFaites / HeureContrat > 1 ? '100%' : `${Math.round(HeureFaites / HeureContrat * 10000) / 100}%`
                    const ClassSoldeM = (Cpt.heuresFaites + Cpt.heuresNoW + Cpt.regules) >= Cpt.heuresAFaire ? 'success' : 'warning';
                    const SoldeOut = Math.round(Cpt.soldeOut * 100) / 100;
                    myLines.push({
                        id: user.id,
                        user: user,
                        avatar: <TimedRoundAvatar src={user.avatarUrl !== undefined ? user.avatarUrl : ''}
                                                  size={"xs"}/>,
                        prenom: user.prenom,
                        nom: user.nom,
                        fullName: user.prenom + ' ' + user.nom,
                        fonctionLib: user.Fonction ? user.Fonction.libelle : '',
                        contratLib: ContratLib,
                        SoldeInObj: <div
                            className={`SoldeIn ${SoldeInNb > 0 ? 'success' : 'warning'}`}>{SoldeInNb > 0 ? '+' : ''} {SoldeInNb}</div>,
                        SoldeInNb: SoldeInNb,
                        heuresAFaire: Math.round(Cpt.heuresAFaire * 100) / 100,
                        heuresFaites: Math.round(Cpt.heuresFaites * 100) / 100,
                        heuresNoWork: Math.round(Cpt.heuresNoW * 100) / 100,
                        heuresRegules: Math.round(Cpt.regules * 100) / 100,
                        CompteurObj: <div
                            className={`CptMois ${ClassSoldeM}`}>{ClassSoldeM === 'success' ? '+' : ''} {SoldeMois}</div>,
                        CompteurMonth: SoldeMois,
                        SoldeOutObjet: <div
                            className={`SoldeOut ${SoldeOut > 0 ? 'success' : 'warning'}`}>{SoldeOut > 0 ? '+' : ''} {SoldeOut}</div>,
                        SoldeOut: SoldeOut
                    })
                }
                return Cpt
            })
        }
        return myLines;
    }, [CptQuery.data, dateStart, dateEnd])

    useEffect(()=>{
        let tabCSV:any[]=[];
        let TotalSoldeInObj = 0;
        let TotalAFaire = 0;
        let TotalCompteurObj = 0;
        let TotalheuresNoWork = 0;
        let TotalheuresWork = 0;
        let TotalheuresRegules = 0;
        let TotalSoldeOutObjet = 0;

        lines.map(item=>{
            TotalSoldeInObj+=item.SoldeInNb;
            TotalAFaire+=item.heuresAFaire
            TotalCompteurObj+=item.CompteurMonth;
            TotalheuresNoWork+=item.heuresNoWork;
            TotalheuresWork+=item.heuresFaites;
            TotalheuresRegules+=item.heuresRegules;
            TotalSoldeOutObjet+=item.SoldeOut;
            if(isAnnualisation){
                tabCSV.push({
                    Collaborateur:item.user.fullName,
                    Matricule:item.user.Matricule,
                    Fonction:item.user.Fonction.libelle,
                    Contrat:item.contratLib,
                    Solde_Debut_mois: item.SoldeInNb,
                    Heures_A_Realiser:item.heuresAFaire,
                    Heures_Faites:item.heuresFaites,
                    Heures_Non_Travaillees : item.heuresNoWork,
                    Heures_Regules : item.heuresRegules,
                    Compteur_Mois: item.CompteurMonth,
                    Solde_Fin_Mois: item.SoldeOut
                })
            } else {
                tabCSV.push({
                    Collaborateur:item.user.fullName,
                    Matricule:item.user.Matricule,
                    Fonction:item.user.Fonction.libelle,
                    Contrat:item.contratLib,
                    Heures_A_Realiser:item.heuresAFaire,
                    Heures_Faites:item.heuresFaites,
                    Heures_Non_Travaillees : item.heuresNoWork,
                    Heures_Regules : item.heuresRegules,
                    Compteur_Mois: item.CompteurMonth,
                })
            }
            return item;
        })
        setCsvData(tabCSV)
        if(isAnnualisation){
            setLineFoot({...LineFootDefault,
                SoldeInObj:Math.round(TotalSoldeInObj*100)/100,
                heuresAFaire:Math.round(TotalAFaire*100)/100,
                heuresFaites:Math.round(TotalheuresWork*100)/100,
                heuresNoWork:Math.round(TotalheuresNoWork*100)/100,
                heuresRegules:Math.round(TotalheuresRegules*100)/100,
                CompteurObj:Math.round(TotalCompteurObj*100)/100,
                SoldeOutObjet:Math.round(TotalSoldeOutObjet*100)/100,
            })
        } else {
            setLineFoot({...LineFootDefaultNA,
                heuresAFaire:Math.round(TotalAFaire*100)/100,
                heuresFaites:Math.round(TotalheuresWork*100)/100,
                heuresNoWork:Math.round(TotalheuresNoWork*100)/100,
                heuresRegules:Math.round(TotalheuresRegules*100)/100,
                CompteurObj:Math.round(TotalCompteurObj*100)/100,
            })
        }

    }, [lines, isAnnualisation])
    const handleClickLine = (data:any)=>{
        navigate(`../temps_travaille?idCollab=${data["id"]}&from=${dateStart.toLocaleDateString()}&to=${dateEnd.toLocaleDateString()}`);
    }
    return (
        <div className={`TableTempsTrMulti ${className}`}>
            {CptQuery.isLoading || CptQuery.isFetching ?
                <TimedSkeletonTable nbColumns={10} nbLines={10}/>:
                <>
                    <TimedTable
                        Titles={TabTitles}
                        isTable={true}
                        sortable={true}
                        themeColor={"Primary"}
                        Outline={true}
                        Lines={lines ? lines : []}
                        scrollable={true}
                        loading={CptQuery.isLoading}
                        maxHeight={"auto"}
                        onClickLine={handleClickLine}
                        lineFoot={[lineFoot]}
                    />
                </>
            }
        </div>
    )
}

const TableTempsTravailleMulti = styled(TableTempsTravailleMultiCtrl)`
  font-size: 13px;
  height: 100%;
  flex-grow: 1;
  height: 0;
  .SoldeOut{
    width: 65px;
    margin: 0 0 0 auto;
    text-align: right;
    border-radius: 15px;
    padding: 0.25rem 0.5rem;
    &.success{
      background: ${props => props.theme.SecondaryExtraLight};
      color:${props => props.theme.SecondaryExtraDark};
    }
    &.warning{
      background: ${props => props.theme.ComplementaryExtraLight};
      color:${props => props.theme.ComplementaryExtraDark};
    }
  }
  .CptMois{
    width: 70px;
    padding: 0.25rem 0.5rem;
    font-size: 13px;
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    margin: 0 0 0 auto;
    font-weight: bold;
    &.success{
      border:solid 1px ${props=>props.theme.Secondary};
      color: ${props=>props.theme.Secondary};
    }
    &.warning{
      border:solid 1px ${props=>props.theme.Complementary};
      color: ${props=>props.theme.Complementary};
    }
    
  }
    .SoldeIn{
      width: 80px;
      padding: 0.25rem 0.5rem;
      font-size: 13px;
      border-radius: 15px;
      position: relative;
      overflow: hidden;
      margin: 0 0 0 auto;
      &.success{
        background: ${props=>props.theme.SecondaryExtraLight};
      }
      &.warning{
        background: ${props=>props.theme.ComplementaryExtraLight};
      }
    }
`

export default TableTempsTravailleMulti;
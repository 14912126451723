import {
    useInfiniteQuery,
    UseInfiniteQueryResult,
    useMutation,
    useQuery,
    useQueryClient,
    UseQueryResult
} from "react-query";
import {
    addReguleTemps, changeFerie,
    getOneDayOneUser,
    getOnePointeuseTreat,
    getPointeuseTreatPage,
    getPTbyParams,
    SavePointeuseTreat
} from "./pointeuseTreat.services";
import {toast} from "react-toastify";


export const useGetPointeuseTreatOneDayOneUser = (idUser:number, dateFor:Date, idCentre:number|null=null):UseQueryResult<PointeuseTreat[], Error>=>{
    const dateForStr = `${dateFor.getFullYear()}-${(`0${dateFor.getMonth()+1}`).slice(-2)}-${(`0${dateFor.getDate()}`).slice(-2)}`
    return useQuery<PointeuseTreat[], Error>(["pointeuse_treat", idUser, dateFor, idCentre], ()=>getOneDayOneUser(idUser, dateForStr, idCentre), {
        staleTime:6000,
        enabled:idUser!==0
    })
}

export const UseGetPointeuseTreatByParams = (params:any|null|undefined):UseQueryResult<PointeuseTreat[], Error>=>{
    return useQuery<PointeuseTreat[], Error>(["pointeuse_treat", params], ()=>getPTbyParams(params), {
        staleTime:5000,
        enabled:params!==undefined
    })
}

export const useGetPointeuseTreatInfinite = (params:any|null|undefined=null):UseInfiniteQueryResult<PointeuseTreatPage, Error>=>{
    return useInfiniteQuery<PointeuseTreatPage, Error>(["pointeuse_treat", params], ({pageParam=`pointeuse_treats?page=1`})=>getPointeuseTreatPage(pageParam, params),  {
        getNextPageParam: (lastPage, allPages) =>{return lastPage.nextPage},
        enabled:params!==undefined
    })
}

export const useGetOnePointeuseTreat = (id:number):UseQueryResult<PointeuseTreat, Error>=>{
    return useQuery<PointeuseTreat, Error>(["pointeuse_treat", id], ()=>getOnePointeuseTreat(id), {
        staleTime:60000,
        refetchOnWindowFocus:false
    })
}

export const useModifyPointeuseTreat = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:PointeuseTreatModifyFormData)=>{
            return SavePointeuseTreat(datas);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["pointeuse_treat"]);
            }, onError:()=>{
                toast.error("Une erreur est survenue...");
            }
        }
    )
}

export const useAddReguleTemps = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:ReguleTpsFD)=>{
            return addReguleTemps(datas);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["pointeuse_treat"])
                toast.success("Régulation ajoutée !")
            }, onError:()=>{
                toast.error("Une erreur est survenue...");
            }
        }
    )
}

export const useChangeFerie = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas: { fd:ChangeFerieFD, id:number })=>{
            return changeFerie(datas.fd, datas.id);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["pointeuse_treat"])
                queryClient.invalidateQueries(["cpt_tps_dd"])
                queryClient.invalidateQueries(["plage_time"])
                toast.success("Jour férié modifié !")
            }, onError:()=>{
                toast.error("Une erreur est survenue...");
            }
        }
    )
}
import React, {useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import { WrapSender } from "../../assets/styles/elements";
import TimedModal, {TimedModalCtrlProps} from "../../components/molecules/TimedModal/TimedModal";
import {BiSave} from "react-icons/bi";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {useAddReguleTemps} from "../../features/pointeuseTreat/pointeuseTreat.hooks";
import TimedButton from "../../components/atoms/TimedButton/TimedButton";
import TimedRoundAvatar from "../../components/atoms/TimedRoundAvatar/TimedRoundAvatar";
import {useGetAllMotifsReguleTps} from "../../features/motifReguleTemps/motifReguleTemps.hooks";
import FormControl from "@mui/material/FormControl";
import {Autocomplete, TextField} from "@mui/material";
import TimedInputTextH from "../../components/atoms/TimedInputText/TimedInputTextH";

interface ModalNewReguleCtrlProps extends TimedModalCtrlProps{
    className?:string;
    month:number;
    year:number;
    user:User;
}

const ModalNewReguleCtrl = ({className, month, year, user, ...props}:ModalNewReguleCtrlProps)=>{
    const [dateMin, setDateMin] = useState<Date|null>(null)
    const [dateMax, setDateMax] = useState<Date|null>(null)

    const mutation = useAddReguleTemps();
    const MotifTps = useGetAllMotifsReguleTps();
    const MotifList = useMemo(()=>{
        let myList: { id:number, name:string }[] = [];
        if(MotifTps.data) {
            myList = MotifTps.data?.sort((a:MotifReguleTemps, b:MotifReguleTemps)=>{
                return a.libelle < b.libelle ? -1 : 1;
            }).map(a=>{
                return {id:a.id, name:a.libelle}
            });
        }
        return myList;
    }, [MotifTps.data])
    useEffect(()=>{
        let MdateMin = new Date(year, month-1, 1)
        MdateMin = new Date(MdateMin.getTime() - MdateMin.getTimezoneOffset()*60000);
        let MdateMax = new Date(MdateMin.getTime() + 86400000*32);
        MdateMax.setDate(1);
        MdateMax = new Date(MdateMax.getTime() - 86400000)
        MdateMax = new Date(MdateMax.getTime() - MdateMax.getTimezoneOffset()*60000);
        setDateMin(MdateMin)
        setDateMax(MdateMax)
    }, [month, year])
    const defaultValue = {
        User:user.id,
        dateAT:'',
        timeFinal:0,
        MotifReguleTemps:{id:0, name:""},
        comentFinalHour:'',
    }
    const methods = useForm({
        mode:'onChange',
        defaultValues:defaultValue
    })
    const onSubmit=(data:any)=>{
        const myDatas:ReguleTpsFD={
            User:`/api/users/${user.id}`,
            dateAT:data.dateAT,
            MotifReguleTemps:`/api/motif_regule_temps/${data.MotifReguleTemps.id}`,
            timeFinal:parseFloat(data.timeFinal),
            timeSystem:parseFloat(data.timeFinal),
            comentFinalHour:data.comentFinalHour
        }
        mutation.mutate((myDatas), {
            onSuccess:()=>{
                if(props.Close) props.Close();
            }, onError:()=>{
                if(props.Close) props.Close();
            }
        })
    }

    return (
        <TimedModal
            {...props}
            footer={<WrapSender>
                <TimedButton
                    size={"md"}
                    themeColor={"Primary"}
                    children={<span className={`spanIc`}><BiSave/> Enregistrer</span>}
                    type={"button"}
                    onClick={methods.handleSubmit(onSubmit)}
                    disabled={false}
                    isPending={mutation.isLoading}
                />
            </WrapSender>}
        >
            <FormProvider {...methods}>
                <form className={`form-add-regule ${className}`} onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className={`wrap-choice-user`}>
                        <div className={`item-choice-x`}>
                            <div className={`avat-place`}>
                                <TimedRoundAvatar BoxShadow={"1px 1px 3px rgba(0,0,0,.7)"} Border={true} src={user.avatarUrl!==undefined ? user.avatarUrl : '' } size={"other"} sizeP={"24px"}/>
                            </div>
                            <div className="name">{user.fullName}</div>
                        </div>
                    </div>
                    <div className={"line-form-full"}>
                        <Controller
                            name="MotifReguleTemps"
                            rules={{required:{value:true, message:"Motif requis"}}}
                            control={methods.control}
                            render={({ field:{onChange, value} }) =>(
                                <FormControl sx={{ m: 1, minWidth: 120 }} size="small" fullWidth={true}>
                                    <Autocomplete
                                        id={"Acte"}
                                        value={value}
                                        size="small"
                                        onChange={(e, data) => onChange(data)}
                                        isOptionEqualToValue={(option, value) => {
                                            if(value.id === 0){
                                                return true;
                                            } else {
                                                return option.id === value.id
                                            }

                                        }}
                                        options={MotifList === undefined ? [] : MotifList}
                                        getOptionLabel={(a)=>a.name}
                                        renderInput={(params)=>(
                                            <TextField
                                                {...params}
                                                label={"Motif de la régule"}
                                                error={!!methods.formState.errors.MotifReguleTemps}
                                                helperText={methods.formState.errors.MotifReguleTemps && "Champs Obligatoire"}
                                                required={true}
                                            />
                                        )}
                                    />
                                    {methods.formState.errors &&
                                        <div className={`helper-text`}>Motif requis</div>
                                    }
                                </FormControl>
                            )}

                        />
                    </div>
                    <div className={"line-form-full"}>
                        <TimedInputTextH
                            label={"Temps régulé (h decimal)"}
                            type={"number"}
                            isRequired={true}
                            register={methods.register}
                            optionRegister={{
                                required: {value:true, message:"Temps reguis"},
                                min: {value:0, message:"minimum 0"}
                            }}
                            getValue={methods.getValues}
                            id={"timeFinal"}
                            size={"small"}
                            isError={!!methods.formState.errors.timeFinal}
                            helperText={methods.formState.errors.timeFinal && methods.formState.errors.timeFinal.message}
                            isFull={true}
                            min={"0"}
                            step={0.1}
                        />
                    </div>
                    <div className={"line-form-full"}>
                        {dateMax && dateMin &&
                            <TimedInputTextH
                                label={"Date"}
                                type={"date"}
                                isRequired={true}
                                register={methods.register}
                                optionRegister={{
                                    required:true,
                                    min: {value:dateMin.getTime(), message:"date invalide"},
                                    max: {value:dateMax.getTime(), message:"date invalide"}
                                }}
                                getValue={methods.getValues}
                                id={"dateAT"}
                                size={"small"}
                                isError={!!methods.formState.errors.dateAT}
                                helperText={methods.formState.errors.dateAT && methods.formState.errors.dateAT.message}
                                isFull={true}
                                min={dateMin.toISOString().slice(0,10)}
                                max={dateMax.toISOString().slice(0,10)}
                            />
                        }
                    </div>
                    <div className={"line-form-full"}>
                        <Controller
                            name="comentFinalHour"
                            control={methods.control}
                            render={({ field }) => <>
                                <FormControl sx={{ m: 1, minWidth: 120 }} size="small" fullWidth={true}>
                                    <TextField
                                        multiline={true}
                                        maxRows={8}
                                        label="Commentaires"
                                        {...field}
                                    />
                                </FormControl>
                            </>}
                        />
                    </div>
                </form>
            </FormProvider>
        </TimedModal>
    )
}

export const ModalNewRegule = styled(ModalNewReguleCtrl)`
  .line-form-full{
    width: 96%;
    position: relative;
    margin-bottom: 10px;
  }
  .item-choice-x{
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0.25rem 0.5rem;
    .name{
      font-weight: bold;
      font-size: 14px;
    }
  }
  .helper-text {
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin: 3px 14px 0;
    &.onError{
      color: red;
    }
  }
`
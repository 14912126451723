import React from "react";
import styled from "styled-components";
import {Shine} from "../../../assets/styles/elements";

interface TimedSkeletonFormVertCtrlProps{
    className?:string;
    margTop?:string;
}


const TimedSkeletonFormVertCtrl = ({className}:TimedSkeletonFormVertCtrlProps)=>{
    return (
        <div className={`TSFV ${className}`}>
            <div className="body-form">
                <div className={`line-form-ske`}/>
                <div className={`line-form-ske`}/>
                <div className={`line-form-ske is-space`}/>
                <div className={`line-form-ske`}/>
                <div className={`line-form-ske`}/>
            </div>
            <div className="foot-form">
                <div className={`btn-ske`}/>
            </div>
        </div>
    )
}

const TimedSkeletonFormVert = styled(TimedSkeletonFormVertCtrl)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: ${props => props.margTop ? props.margTop : 0};
  .btn-ske{
    width: 50%;
    border-radius: 8px;
    height: 40px;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s ${Shine} linear infinite;
  }
  .body-form{
    flex-grow: 1;
    padding: 0.75rem 1rem;
    .line-form-ske{
      width: 100%;
      border-radius: 8px;
      height: 40px;
      margin-bottom: 15px;
      background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
      background-size: 200% 100%;
      animation: 1.5s ${Shine} linear infinite;
      &.is-space{
        margin-bottom: 40px;
      }
    }
  }
  .foot-form{
    padding: 0.5rem;
    padding: 0.75rem 1rem;
    display: flex;
    justify-content: flex-end;
  }
`

export default TimedSkeletonFormVert;
import logo from '../images/logo.png';

const TimedTheme = {
    PrimaryMegaDark:"#071112",
    PrimaryExtraDark:"#163538",
    PrimarySuperDark:"#25595E",
    PrimaryDark:"#347D84",
    Primary:"#44A1AA",
    PrimaryLight:"#6EB7BE",
    PrimarySuperLight:"#98CDD2",
    PrimaryExtraLight:"#C2E3E6",
    PrimaryMegaLight:"#EDF7F8",

    TextPrimaryLight:"#EDF7F8",
    TextPrimaryDark:"#163538",


    SecondaryMegaDark:"#111306",
    SecondaryExtraDark:"#2F3611",
    SecondarySuperDark:"#4D591C",
    SecondaryDark:"#6B7C27",
    Secondary:"#899E32",
    SecondaryLight:"#A4B561",
    SecondarySuperLight:"#B7CC90",
    SecondaryExtraLight:"#DAE3BF",
    SecondaryMegaLight:"#e9f0d0",

    TextSecondaryLight:"#DAE3BF",
    TextSecondaryDark:"#2F3611",


    ComplementaryMegaDark:"#13060E",
    ComplementaryExtraDark:"#381228",
    ComplementarySuperDark:"#5D1E42",
    ComplementaryDark:"#7E2E5D",
    Complementary:"#A53577",
    ComplementaryLight:"#BA6396",
    ComplementarySuperLight:"#CF91B5",
    ComplementaryExtraLight:"#E4BFD4",
    ComplementaryMegaLight:"#F9ECF3",

    TextComplementaryLight:"#E4BFD4",
    TextComplementaryDark:"#381228",

    TertiaryMegaDark:"#0A0811",
    TertiaryExtraDark:"#1D1730",
    TertiarySuperDark:"#30264F",
    TertiaryDark:"#43356E",
    Tertiary:"#54438B",
    TertiaryLight:"#7B6EA6",
    TertiarySuperLight:"#A299C1",
    TertiaryExtraLight:"#C9C4DC",
    TertiaryMegaLight:"#F0EEF7",

    TextTertiaryLight:"#C9C4DC",
    TextTertiaryDark:"#1D1730",

    NeutreMegaDark:"#0C0D0D",
    NeutreExtraDark:"#2E3131",
    NeutreSuperDark:"#505555",
    NeutreDark:"#727979",
    Neutre:"#939E9E",
    NeutreLight:"#ABB3B3",
    NeutreSuperLight:"#C3C8C8",
    NeutreExtraLight:"#DBDDDD",
    NeutreMegaLight:"#F2F3F3",

    TextNeutreLight:"#DBDDDD",
    TextNeutreDark:"#2E3131",

    Light:"#F5FAFA",
    LightDark:"#DFF0F4",
    LightExtraLight:"#FFFFFF",

    Dark:"#1F2041",
    DarkDark:"#39304A",

    SuccessMegaDark:"#0C1109",
    SuccessExtraDark:"#27351C",
    SuccessSuperDark:"#42592F",
    SuccessDark:"#5D7D42",
    Success:"#78A255",
    SuccessLight:"#97B77B",
    SuccessSuperLight:"#B6CCA1",
    SuccessExtraLight:"#D5E1C7",
    SuccessMegaLight:"#F2F6EE",


    ErrorMegaDark:"#120808",
    ErrorExtraDark:"#3B1A1C",
    ErrorSuperDark:"#642C30",
    ErrorDark:"#8D3E44",
    Error:"#B44E57",
    ErrorLight:"#C5767D",
    ErrorSuperLight:"#D69EA3",
    ErrorExtraLight:"#E7C6C9",
    ErrorMegaLight:"#F7EDEE",

    WarningMegaDark:"#150904",
    WarningExtraDark:"#462113",
    WarningSuperDark:"#773922",
    WarningDark:"#A85131",
    Warning:"#D96940",
    WarningLight:"#E28A6B",
    WarningSuperLight:"#EBAB96",
    WarningExtraLight:"#F4CCC1",
    WarningMegaLight:"#FBEEEA",

    Info:"#509AB5",
    InfoDark:"#266880",
    InfoLight:"#98C7D9",

    BodyBackground:"#F5FAFA",
    MenuBackground:"#DBECED",
    MenuBackgroundGreyVersion:"#333333",
    TextMenuColor:"#347D84",

    colorDark:"#1F2041",
    colorLight:"#FFFFFF",

    logo:logo,
}

export default TimedTheme;
import styled from "styled-components";
import React, {useEffect} from "react";

interface TimedContextMenuAbsCtrlProps{
    className?:string;
    visible:boolean;
    children:React.ReactNode;
    posContext:{x:number, y:number}
}

const TimedContextMenuAbsCtrl = React.forwardRef(({className, posContext, visible, children}:TimedContextMenuAbsCtrlProps, ref:React.ForwardedRef<HTMLDivElement>)=>{

    return (
        <div ref={ref} className={`context ${visible ? 'visible' : ''} ${className}`} style={{left:`${posContext.x}px`, top:`${posContext.y}px`}}>
            {children}
        </div>
    )
})

export const TimedContextMenuAbs = styled(TimedContextMenuAbsCtrl)`
  display: none;
  width: 250px;
  padding: 0.5rem;
  font-size: 14px;
  height: auto;
  background: white;
  border-radius: 6px;
  position: fixed;
  z-index: 4;
  opacity: 0;
  transition: opacity 300ms;
  box-shadow: 1px 1px 2px 2px rgba(0,0,0,.1);
  
`
import styled from "styled-components";
import {useCentreConnectedData} from "../../../../services/hooks";
import {useGetAllUser, useGetUser} from "../../../../features/user/user.hooks";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
    ConvertBase5,
    DateToDateStrFR,
    getLastWeekByDate, getNextWeekByDate,
    getNumWeek,
    getWeekByDate
} from "../../../../components/functions/TimesFunctions";
import {useGetDayOffByDDAuto} from "../../../../features/dayOff/dayOff.hooks";
import {useGetPlageWeek} from "../../../../features/plage/plage.hooks";
import {useGetAllHoraires} from "../../../../features/horaires/horaires.hooks";
import {OneItemChips} from "../../Remplacements/CreneauxEnCours/CreneauxEnCours";
import {SegmentationThere, TColumn, TypeDrop} from "../PlanningGlobalLieu/PlanningGlobalLieu3";
import {useGetParamsTimed} from "../../../../hooks/useGetParamsTimed";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import {TitrePageNoMarg} from "../../../../assets/styles/elements";
import {BiChevronLeft, BiChevronRight} from "react-icons/bi";
import TimedIconButton from "../../../../components/atoms/TimedIconButton/TimedIconButton";
import {GrInfo} from "react-icons/gr";
import {FaUser, FaUsers} from "react-icons/fa";
import TimedChips from "../../../../components/atoms/TimedChips/TimedChips";
import {BsCalendar3, BsFillCalendar2WeekFill, BsFillGearFill} from "react-icons/bs";
import TimedSkeletonBigIcon from "../../../../components/atoms/TimedSkeleton/TimedSkeletonBigIcon";
import CustomDragLayer from "../PlanningGlobalLieu/CustomDragLayerPlage";
import {useNavigate, useSearchParams} from "react-router-dom";
import {TabJourISO} from "../../../../services/constantes/constantes";
import {OneDayPlanningPP} from "./OneDayPlanningPP";
import {CellSegmentation} from "./CellSegmentation";
import {CellPersonne} from "./CellPersonne";
import {PlageAdd_drag} from "./PlageAdd_drag";
import CustomDragLayerPlagePP from "./CustomDragLayerPlagePP";
import TimedRoundAvatar from "../../../../components/atoms/TimedRoundAvatar/TimedRoundAvatar";
import {TiWarning} from "react-icons/ti";
import {TimedInfosSurvol} from "../../../../components/molecules/TimedInfosSurvol";
import {TimedContextMenuAbs} from "../../../../components/molecules/TimedContextMenu/TimedContextMenuAbs";
import {isContext} from "vm";
import TimedItemContext from "../../../../components/atoms/TimedItemContext/TimedItemContext";
import {useModal} from "../../../../hooks/useModal";
import {FormGerePlage} from "../../../Forms/FormGerePlage";




interface PlanningGlobalPersonne2CtrlProps{
    className?:string;
    supervisor?:boolean
}

const TabChipsSegW:OneItemChips[] = [
    {id:0, libelle:"A.M", themeColor:"Primary"},
    {id:1, libelle:"P.M", themeColor:"Primary"}
]

const TabChipsSegDefault:OneItemChips[] = [
    {id:0, libelle:"A.M", themeColor:"Primary"},
    {id:1, libelle:"P.M", themeColor:"Primary"}
]
const backColorOne = "#f4fbfb";

const PlanningGlobalPersonne2Ctrl = ({className, supervisor}:PlanningGlobalPersonne2CtrlProps)=>{

    console.log(new Date().getTimezoneOffset()*60000);
    const Today = new Date(new Date().getTime() - new Date().getTimezoneOffset()*60000);
    console.log(Today.toISOString().slice(0,10));
    const [plageCurrentAction, setPlageCurrentAction] = useState<Plage|null>(null);
    const [infosVis, setInfosVis] = useState(false);
    const [plageCurrent, setPlageCurrent] = useState<Plage|null>(null);
    const [posInfos, setPosInfos] = useState({x:0, y:0})
    const refSurvol = useRef<HTMLDivElement>(null);
    const refContext = useRef<HTMLDivElement>(null)
    const [searchParams, setSearchParams] = useSearchParams();
    const centre = useCentreConnectedData();
    const UserQuery = useGetUser();
    const [datePick, setDatePick] = useState<Date|null>(Today);
    const {Monday, Sunday} = getWeekByDate(datePick||Today);
    const DaysOffsQuery = useGetDayOffByDDAuto(Monday.toISOString().slice(0,10), Sunday.toISOString().slice(0,10), supervisor ? centre : null)
    const PlagesQuery = useGetPlageWeek(datePick ? datePick.toISOString().slice(0,10) : null, supervisor ? centre : null);
    const [CGI, setCGI] = useState<ConfigPlanningG|null>(null)
    const [libdays, setLibDays] = useState<string[]>([])
    const [listDates, setListeDates] = useState<string[]>([])
    const UsersQuery = useGetAllUser(supervisor ? centre : null);
    const HorairesQuery = useGetAllHoraires(supervisor ? centre : null);
    const [segFilter, setSegFilter] = useState<OneItemChips[]>(TabChipsSegDefault)
    const [TabChipsSeg, setTabChipsSeg] = useState<OneItemChips[]>(TabChipsSegW)
    const [segmentations, setSegmentation] = useState<SegmentationThere[]>([])
    const [Plages, setPlages] = useState<Plage[]>([]);
    const {From, TabLieu, TabFx, TabCollab} = useGetParamsTimed();
    const [openConfig, setOpenConfig] = useState(false);
    const [subMenuLeft, setSubMenuLeft] = useState(false);
    const [posContext, setPosContext] = useState({x:0, y:0})
    const [context, setIsContext] = useState(false);
    const {open:openEdit, toggle:toggleEdit} = useModal();
    const navigate = useNavigate();
    useEffect(()=>{
        console.log('xxxxxxxxxxxx')
        console.log(datePick);
        console.log('xxxxxxxxxxxx')
    }, [datePick])
    useEffect(()=>{
        const elt = document.getElementById("Main_pl");
        if(elt) {
            const Prt = elt.parentElement;
            if(Prt) {
                Prt.addEventListener('scroll', () => {
                    setIsContext(false);
                })
                Prt.onscroll = () => {
                    setIsContext(false)
                }
            }
        }
        return ()=>{
            if(elt){
                const Prt = elt.parentElement;
                if(Prt) {
                    Prt.removeEventListener('scroll', () => {
                        setIsContext(false);
                    })
                }
            }
        }
    },[])

    useEffect(()=>{
        if(datePick) {
            const {Monday, Sunday} = getWeekByDate(datePick);
            let dateSlidy = Monday
            const TabDay: string[] = [];
            const TabDates: string[] = [];
            while (dateSlidy <= Sunday) {
                const thisDate = dateSlidy;
                const thisNumDay = thisDate.getDay();
                const dayThis = (`0${thisDate.getDate()}`).slice(-2);
                const monthThis = (`0${thisDate.getMonth() + 1}`).slice(-2);
                const libDay = TabJourISO[thisDate.getDay()]
                let canPush = true;
                if (CGI) {
                    const TabDay = CGI.days;
                    if (TabDay.indexOf(thisNumDay) === -1) {
                        canPush = false;
                    }
                }
                if (canPush) {
                    TabDay.push(`${libDay.name} ${dayThis}/${monthThis}`)
                    TabDates.push(thisDate.toLocaleDateString())
                }
                dateSlidy = new Date(dateSlidy.getTime() + 86400000)
            }
            setLibDays(TabDay);
            setListeDates(TabDates);
        }
    }, [datePick, CGI])
    useEffect(()=>{
        if(UserQuery.data){
            setCGI(UserQuery.data.configPl.CGI)
        }
    }, [UserQuery.data])
    useEffect(()=>{
        console.log(From);
        let date = Today;
        if(From){
            date = new Date(From.getTime() - From.getTimezoneOffset()*60000);
        }
        setDatePick(date);
    }, [From])
    useEffect(()=>{
        if(PlagesQuery.data){
            setPlages(PlagesQuery.data);
        }
    }, [PlagesQuery.data])
    useEffect(()=>{
        if(HorairesQuery.data){
            setSegmentation(HorairesQuery.data.map(h=>{
                return {libelle:h.libelle, start:h.startSeg, end:h.endSeg}
            }))
            const MyTabChipsSeg = HorairesQuery.data.map((h:Horaires, idx:number)=>{
                return {id:idx, libelle:h.libelle, themeColor:"Primary"}
            })
            setTabChipsSeg(MyTabChipsSeg);
            setSegFilter(MyTabChipsSeg)
        }
    }, [HorairesQuery.data])
    const FiltrageParSegmentation = useCallback((plages:Plage[], precent:TColumn[]=[])=>{
        const TabReturn:TColumn[]=[];
        segmentations.map(s=>{
            const PlagesThisSeg = plages.filter(p=>{
                return !(p.heureDebut>=s.end || p.heureFin<=s.start);
            }).sort((a,b)=>a.heureDebut > b.heureDebut ? 1 : -1)
            if(segFilter.filter(sx=>sx.libelle === s.libelle).length>0){
                TabReturn.push({libelle:s.libelle,colB:[], type:'Seg', object:"seg", Plages:PlagesThisSeg, Manquement:[]})
            }
            return s;
        })
        return TabReturn;
    }, [segmentations, segFilter])
    const FiltrageParPersonne = useCallback((plages:Plage[], precent:TColumn[]=[])=>{
        const TabReturn:TColumn[]=[];
        if(UsersQuery.data){
            const UsersConcerned = UsersQuery.data.filter(u=>u.FonctionAdm.id!==3).sort((a, b)=>a.Fonction.id > b.Fonction.id ? 1 : -1);
            UsersConcerned.map(u=>{
                const PlagesConcerned = plages.filter(p=>p.User.id === u.id);
                TabReturn.push({libelle: `${u.prenom} ${u.nom}`, colB:[], type:'User', object:u, Plages:PlagesConcerned, Manquement:[]})
                return u;
            })
        }
        return TabReturn;
    }, [UsersQuery.data])
    const lines=useMemo(()=>{
        let list:TColumn[] = []
        if(CGI && CGI.Columns && Plages.length>0) {
            const Columns = CGI?.Columns.sort((a: Arbo, b: Arbo) => a.ordre > b.ordre ? 1 : -1).filter(c => c.affiche).map(c => c.libelle);
            let TabloNiv0:TColumn[] = [];
            Columns.map((col:string, idx:number)=>{
                if(idx === 0){
                    switch (col){
                        case 'Segmentation':
                            TabloNiv0 = FiltrageParSegmentation(Plages);
                            break;
                        default:
                            TabloNiv0 = FiltrageParPersonne(Plages);
                            break;
                    }
                } else{
                    switch (col){
                        case 'Segmentation':
                            TabloNiv0.map((T, index:number)=>{
                                TabloNiv0[index].colB = FiltrageParSegmentation(T.Plages, [T]);
                                return T;
                            })
                            break;
                        default:
                            TabloNiv0.map((T, index:number)=>{
                                TabloNiv0[index].colB = FiltrageParPersonne(T.Plages, [T]);
                                return T;
                            })
                            break;
                    }
                }
                return col;
            })
            list = TabloNiv0;
        }
        return list;
    }, [CGI, Plages])
    const MoveWeek = (sens:-1|1)=>{
        const params:any = {};
        if(datePick){
            searchParams.forEach((key, value) => {
                params[value] = key;
            });
            if(sens === -1) {
                const {prevMonday, prevSunday} = getLastWeekByDate(datePick);
                setSearchParams({...params, from:DateToDateStrFR(prevMonday), to:DateToDateStrFR(prevSunday)})
            } else{
                const {NextMonday, NextSunday} = getNextWeekByDate(datePick);
                setSearchParams({...params, from:DateToDateStrFR(NextMonday), to:DateToDateStrFR(NextSunday)})
            }
        }
    }
    const SwitchSegmentation = (item:OneItemChips, db:boolean=false)=>{
        if(db){
            setSegFilter([item]);
        }else{
            const Exist = segFilter.find(s=>s.id === item.id);
            if(Exist){
                setSegFilter([...segFilter].filter(s=>s.id === item.id))
            } else {
                setSegFilter([...segFilter, item])
            }
        }
    }
    const switchConfig = ()=>{
        setOpenConfig(o=>!o);
    }
    const getWidthText = (text:string)=>{
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if(context){
            context.font = getComputedStyle(document.body).font;
            return context.measureText(text).width;
        } else {
            return 0;
        }

    }
    const Mesurement = (libelle:string)=>{
        if(libelle === 'Segmentation'){
            let maxWidth = 0;
            segmentations.map(s=>{
                if(getWidthText(s.libelle) > maxWidth){
                    maxWidth = getWidthText(s.libelle);
                }
                return s;
            })
            return (maxWidth+20);
        } else {
            let maxWidth = 0;
            UsersQuery.data?.map(u=> {
                if(getWidthText(`${u.prenom} ${u.nom}`)>maxWidth){
                    maxWidth = getWidthText(`${u.prenom} ${u.nom}`);
                }
                return u;
            })
            return (maxWidth + 30)
        }
    }
    const handleHover = (e:any, item:Plage)=> {
        let X = e.clientX + 15;
        let Y = e.clientY + 15;
        const winWid = window.innerWidth;
        const winHeight = window.innerHeight;
        if(refSurvol.current) {
            console.log('buono received')
            const elt = refSurvol.current.getBoundingClientRect();
            const Hei =elt.height;
            if ((X + 250) > winWid) {
                X = X - 250;
            }
            if ((Y + Hei) > winHeight) {
                Y = Y - Hei - 60;
            }
            setPlageCurrent(item);
            setPosInfos({x: X, y: Y})
            setInfosVis(true);
        } else {
            console.log('pfff')
        }
    }
    const handleOut = ()=>{
        setPosInfos({x:0, y:0})
        setInfosVis(false);
    }
    const HandleContext = (e:any, item:Plage)=>{
        e.preventDefault();
        setInfosVis(false);
        let X = e.clientX + 15;
        let Y = e.clientY + 15;
        const winWid = window.innerWidth;
        const winHeight = window.innerHeight;
        setPlageCurrentAction(item);
        if(refContext.current) {
            const elt = refContext.current.getBoundingClientRect();
            const Hei =elt.height;
            if ((X + 350) > winWid) {
                X = X - 350;
                setSubMenuLeft(true);
            } else {
                setSubMenuLeft(false);
            }
            if ((Y + Hei) > winHeight) {
                Y = Y - Hei - 60;
            }

            setPosContext({x: X, y: Y})
            setIsContext(true);
        }
    }
    useEffect(() => {
        function handleClickOutside(event:any) {
            if (refContext.current && !refContext.current.contains(event.target)) {
                setIsContext(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refContext]);
    const LineTab = useMemo(()=>{
        const myLines:React.ReactNode[] = [];
        const MakeTabTDPlages = (plages:Plage[], l:string, TabOffs:string[], key:string, TabCol:TypeDrop[], backy:boolean=false)=>{
            const TabDate = l.split('/');
            const day:number = parseInt(TabDate[0]);
            const month:number = parseInt(TabDate[1])-1;
            const year:number = parseInt(TabDate[2])
            const myDate = new Date(year, month, day);
            myDate.setHours(18,0,0);
            const PlSync = plages.filter(p=>new Date(p.plageAt).toLocaleDateString() === l)
            return <td className={`cell_plage ${TabOffs.indexOf(l)!==-1 ? 'off' : ''}`} key={`Date_${key}${l.slice(0,2)}`} style={{padding:"0", verticalAlign:"top",background:backy ? backColorOne : "white"}}>
                <OneDayPlanningPP
                    PlagesSync={PlSync}
                    TabCol={TabCol}
                    date={myDate}
                    Plages={Plages}
                    CGI={CGI}
                    setPlages={setPlages}
                    overring={{over:handleHover, out:handleOut}}
                    actionContext={HandleContext}
                />
            </td>
        }
        console.log(lines);
        if(listDates && CGI && Plages && DaysOffsQuery.data && datePick) {
            const Columns = CGI.Columns.filter(c=>c.affiche).sort((a, b)=>a.ordre>b.ordre ? 1 : -1);
            const TabOffs = DaysOffsQuery.data.map(doff=>new Date(doff.offAt).toLocaleDateString());
            lines.map((item:TColumn, idxI:number) => {
                const nbCells = item.colB.length;
                const firstElt = item.colB.find(()=>true)
                item.colB.map((sec:TColumn, idxB:number)=>{
                    const CellTD: React.ReactNode[] = [];
                    const myBacky = item?.type === "Seg" ? idxB%2 === 0 : idxI%2 === 0;
                    const ThisLib = sec.libelle;
                    if(firstElt?.libelle === ThisLib){
                        CellTD.push(<td className={`colA`} key={`ColA_${idxI}`} rowSpan={nbCells}>
                            {item.type === "Seg" ?
                                <CellSegmentation
                                    TabCol={[{type:item.type, libelle:item.libelle, object:item.object, Manquement:item.Manquement ? item.Manquement : []}, {type:sec.type, libelle:sec.libelle, object:sec.object, Manquement:sec.Manquement ? sec.Manquement : []}]}
                                    Plages={Plages} datePick={datePick} setPlages={setPlages} segmentation={item.libelle}/>:
                                <CellPersonne
                                    TabCol={[{type:item.type, libelle:item.libelle, object:item.object, Manquement:item.Manquement ? item.Manquement : []}, {type:sec.type, libelle:sec.libelle, object:sec.object, Manquement:sec.Manquement ? sec.Manquement : []}]}
                                    Plages={Plages} datePick={datePick} setPlages={setPlages} user={item.object as User}/>
                            }
                        </td>)
                    }
                    CellTD.push(<td style={{background:myBacky ? backColorOne : 'white'}} className={`colC`} key={`ColC_${idxB}`}>
                        {sec.type === "Seg" ?
                            <CellSegmentation
                                TabCol={[{type:item.type, libelle:item.libelle, object:item.object, Manquement:item.Manquement ? item.Manquement : []}, {type:sec.type, libelle:sec.libelle, object:sec.object, Manquement:sec.Manquement ? sec.Manquement : []}]}
                                Plages={Plages} datePick={datePick} setPlages={setPlages} segmentation={sec.libelle}/>:
                            <CellPersonne
                                TabCol={[{type:item.type, libelle:item.libelle, object:item.object, Manquement:item.Manquement ? item.Manquement : []}, {type:sec.type, libelle:sec.libelle, object:sec.object, Manquement:sec.Manquement ? sec.Manquement : []}]}
                                Plages={Plages} datePick={datePick} setPlages={setPlages} user={sec.object as User}/>
                        }
                    </td>)
                    listDates.map((l:string, idxL:number)=>{
                        CellTD.push(MakeTabTDPlages(sec.Plages, l, TabOffs,`${idxI}${idxB}`, [{type:item.type, libelle:item.libelle, object:item.object, Manquement:item.Manquement ? item.Manquement : []}, {type:sec.type, libelle:sec.libelle, object:sec.object, Manquement:sec.Manquement ? sec.Manquement : []}], myBacky))
                        return l;
                    })
                    myLines.push(<tr className={`lines_entry`} key={`line_${idxI}${idxB}`}>{CellTD}</tr>)
                    return sec;
                })

            })
        }
        return myLines;
    }, [DaysOffsQuery.data, lines, Plages, CGI, listDates])

    const GetMesurementCol = ()=>{
        const nbDay = CGI?.days.length || 7;
        const elt = document.getElementById("table_pp");
        if(elt){
            const myElt = elt.getBoundingClientRect();
            const width = myElt.width;
            const WidthTitles = Mesurement("Segmentation") + Mesurement("Personne");
            const widthRestant = width - WidthTitles;
            return (widthRestant/nbDay)+"px";
        } else {
            return "20%"
        }
    }
    const GetMeasurementFirst = (idx:0|1)=>{
        const Columns = CGI?.Columns;
        if(Columns){
            const firstCol = Columns.find(c=>c.ordre === idx);
            if(firstCol){
                return Mesurement(firstCol.libelle)+"px"
            }
            return "auto"
        }
        return "auto"
    }
    const handleGoPlInd = ()=>{
        if(plageCurrentAction) {
            const daty = (new Date(plageCurrentAction.plageAt)).toLocaleDateString();
            navigate('../planning_indi_hebdo?idCollab=' + plageCurrentAction?.User.id + '&from=' + daty);
        }
    }
    const handleGoFiche = ()=>{
        if(plageCurrentAction) {
            const daty = (new Date(plageCurrentAction.plageAt)).toLocaleDateString();
            navigate('../fiche_personnel?idCollab=' + plageCurrentAction?.User.id + '&From=' + daty);
        }
    }
    return (
        <div className={`pl_personne ${className}`}>
            <DndProvider backend={HTML5Backend}>
                <div className={"actions_bar"}>
                    <div className={`left_part part_actions`}>
                        <TitrePageNoMarg>Planning global par personne</TitrePageNoMarg>
                        {datePick &&
                            <>
                                <div className={"name-week"}>
                                    <div className={'in-name-week'}>
                                        <span className={"trans"}>|</span><span><strong>Semaine {getNumWeek(datePick)}</strong></span>
                                    </div>
                                </div>
                                <div className="moveWeek">
                                    <div className={`Move isRew`} onClick={()=>MoveWeek(-1)}><BiChevronLeft/></div>
                                    <div className={`Move isForw`} onClick={()=>MoveWeek(1)}><BiChevronRight/></div>
                                </div>
                            </>
                        }
                    </div>
                    <div className={`right_part part_actions`}>
                        <div className={`wrap_actions`}>

                            <div style={{display:"flex", justifyContent:"flex-start", gap:"3px"}}>
                                {TabChipsSeg.map((item, idx:number)=>(
                                    <TimedChips
                                        key={`oneFilterSeg${idx}`}
                                        themeColor={"Primary"}
                                        choices={segFilter}
                                        MyChoice={item}
                                        setChoice={SwitchSegmentation}
                                        dbClickChoice={SwitchSegmentation}
                                    />
                                ))}
                            </div>
                        </div>
                        <div className={`wrap_actions`}>
                            <TimedIconButton
                                size={"sm"}
                                themeColor={"Primary"}
                                Icon={<BsFillGearFill/>}
                                toolTip={"Configuration"}
                                onClick={switchConfig}
                                BackAlw={true}
                                isActive={true}
                            />
                        </div>
                    </div>
                </div>
                <div className={"second_bar"}>
                    <PlageAdd_drag/>
                </div>
                <div className={`wrapper_table`} id={"Main_pl"}>
                    {PlagesQuery.isLoading /*&& LineTab.length ===0*/ ?
                        <div style={{height:"350px"}}>
                            <TimedSkeletonBigIcon
                                Icon={<BsCalendar3/>}
                                width={"100%"}
                                height={"500px"}
                            />
                        </div>:
                        <table className={`table_pl`} id={"table_pp"}>
                            <thead>
                            <tr>
                                <th style={{width:GetMeasurementFirst(0)}}></th>
                                <th style={{width:GetMeasurementFirst(1)}}></th>
                                {libdays.map((item:string, idx:number)=>(
                                    <th className={`one_head_day`} key={`oHd${idx}`} style={{width:GetMesurementCol()}}>{item}</th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            {LineTab}
                            </tbody>
                        </table>
                    }
                </div>
                <CustomDragLayerPlagePP/>
            </DndProvider>
            <TimedInfosSurvol
                Plages={Plages}
                PlageCurrent={plageCurrent}
                visible={infosVis}
                posInfos={posInfos}
                ref={refSurvol}
            />
            <TimedContextMenuAbs visible={context} posContext={posContext} ref={refContext}>
                <TimedItemContext fontSize={"14px"} label={"Gérer la plage"} Icon={<BsFillGearFill/>} Action={()=> {
                    setIsContext(false);
                    toggleEdit()
                }}/>
                <TimedItemContext fontSize={"14px"} label={"Planning individuel"} Icon={<BsFillCalendar2WeekFill/>} Action={handleGoPlInd}/>
                <TimedItemContext fontSize={"14px"} label={"Fiche du collaborateur"} Icon={<FaUser/>} Action={handleGoFiche}/>
            </TimedContextMenuAbs>
            {openEdit && plageCurrentAction &&

                <FormGerePlage
                    idUser={null}
                    idLieu={null}
                    size={"regular"}
                    title={"Edition de la plage"}
                    position={"right"}
                    topPosition={"Top"}
                    Close={toggleEdit}
                    idActe={null}
                    idHoraire={null}
                    Plage={plageCurrentAction}
                    supervisor={supervisor}
                />
            }
        </div>
    )
}

const PlanningGlobalPersonne2 = styled(PlanningGlobalPersonne2Ctrl)`
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  .survol_infos, .context{
    display: none;
    width: 250px;
    padding: 0.5rem;
    font-size: 14px;
    height: auto;
    background: white;
    border-radius: 6px;
    position: fixed;
    z-index: 4;
    opacity: 0;
    transition: opacity 300ms;
    box-shadow: 1px 1px 2px 2px rgba(0,0,0,.1);
    &.visible{
      display: block;
      opacity: 1;
      transition: opacity 300ms;
    }
  }
  .second_bar{
    margin-bottom: 5px;
  }
  .actions_bar{
    margin-bottom: 16px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    .part_actions{
      display: flex;
      gap:20px;
      align-items: center;
      &.left_part{
        justify-content: flex-start;
        flex-grow: 1;
      }
      &.right_part{
        flex-grow: 1;
        justify-content: flex-end;
      }
    }
  }
  .moveWeek{
    display: flex;
    gap: 5px;
    .Move{
      &.isRew{
        border-top-left-radius: 18px;
        border-bottom-left-radius: 18px;
      }
      &.isForw{
        border-top-right-radius: 18px;
        border-bottom-right-radius: 18px;
      }
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      background: ${props=>props.theme.PrimaryExtraLight};
      svg{
        margin: auto;
      }
      &:hover{
        filter:brightness(90%);
        cursor: pointer;
      }
    }
  }
  .name-week{
    display: flex;
    flex-direction: column;
    justify-content: center;
    .trans{
      margin-right: 10px;
    }
  }
  .wrapper_table{
    flex-grow: 1;
    height: 0;
    overflow-y: auto;
  }
  .table_pl{
    height: 1px;
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    border-radius: 12px;
    overflow: hidden;
    th{
      padding: 0.25rem;
      font-size: 14px;
      background: white;
      border-right: solid ${props => props.theme.NeutreSuperLight} 1px;
      border-bottom: solid ${props => props.theme.NeutreSuperLight} 1px;
      &:last-child{
        border-right: none;
      }
    }
    tr.lines_entry{
      td{
        //padding: 0 0.35rem;
        font-size: 13px;
        font-weight: bold;
        &:first-child{
          border-left: none;
        }
        &:last-child{
          border-right: none;
        }
      }
    }
    td{
      height: 100%;
      border-left:solid ${props=>props.theme.NeutreSuperLight} 1px;
      //border-top:solid ${props=>props.theme.NeutreSuperLight} 1px;
      border-right:solid ${props=>props.theme.NeutreSuperLight} 1px;
      border-bottom:dashed ${props=>props.theme.NeutreSuperLight} 1px;
      background: white;
      &.cell_plage{
        
      }
      &.colA{
        color:${props=>props.theme.TertiaryDark};
      }
      &.colB{
        color:${props=>props.theme.ComplementaryDark};
      }
      &.colC{
        color:${props=>props.theme.PrimaryDark};
      }
      &.off{
        background: ${props=>props.theme.NeutreMegaLight};
      }
    }
  }
`

export default PlanningGlobalPersonne2;
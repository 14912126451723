import React from "react";
import styled from "styled-components";

interface TimedLinkCtrlProps{
    className?:string;
    size:"xs" | "sm" | "md" | "l" | "xl";
    icon?:React.ReactNode;
    themeColor:"Primary" | "Warning" | "Error" | "Neutre" | "Success";
    onClick:Function
    text:string;
    iconPosition?:"left" | "right"
    isDisabled?:boolean;
}

const TimedLinkCtrl = ({className, icon, onClick, text, iconPosition, isDisabled}:TimedLinkCtrlProps)=>{
    const ActionClick = ()=>{
        if(!isDisabled){
            onClick();
        }
    }
    return (
        <div className={`linkTimed ${className}`} onClick={ActionClick}>
            {icon ?
                <div className={"wrap-for-icon"}>
                    {(iconPosition && iconPosition === "left") ?
                        <>
                            <div className="icon-place isLeft">{icon}</div>
                            <div className="text-place">{text}</div>
                        </> :
                        <>
                            <div className="text-place">{text}</div>
                            <div className="icon-place isRight">{icon}</div>
                        </>
                    }
                </div>:
                <span>{text}</span>
            }
        </div>
    )
}

const TimedLink = styled(TimedLinkCtrl)`
  font-size: ${props=>props.size === "xs" ? "12px" : props.size === "sm" ? "14px" : props.size==="md" ? "16px" : props.size==="l" ? "18px" : "20px"};
  font-weight: bold;
  color:${props=>
  props.themeColor==="Primary" ? props.theme.Primary :
   props.themeColor==="Warning" ? props.theme.Warning :
    props.themeColor==="Error" ? props.theme.Error:
    props.themeColor === "Success" ? props.theme.Success : props.theme.Neutre};
  &:hover{
    cursor: pointer;
    text-decoration: underline;
    filter: brightness(90%);
  }
  .wrap-for-icon{
    display: flex;
    justify-content: start;
    align-items: center;
    .icon-place{
      &.isLeft{
        margin-right: 5px;
      }
      &.isRight{
        margin-left: 5px;
      }
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`

TimedLink.defaultProps={
    text:"Mon Lien",
    size:"md",
    themeColor:"Primary",
    iconPosition:"left"
}

export default TimedLink;

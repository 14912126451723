import React from "react";
import styled from "styled-components";
import {useGetCentreActeColor} from "../../../services/hooks";
import TimedRoundAvatar from "../../atoms/TimedRoundAvatar/TimedRoundAvatar";
import {ConvertBase5} from "../../functions/TimesFunctions";

interface TimedStickersPlageCtrlProps{
    className?:string;
    Plage:Plage;
    is_avatar?:boolean;
    is_lieu?:boolean;
    is_acte?:boolean;
    is_horaire?:boolean;
    is_date?:boolean;
    padding?:string;
}

const TimedStickersPlageCtrl = ({className, Plage, is_acte, is_avatar, is_lieu, is_date, is_horaire}:TimedStickersPlageCtrlProps)=>{
    const isActeColor = useGetCentreActeColor();
    const getColor = (plage:Plage)=>{
        if(isActeColor){
            return {back:plage.Acte.backColor, font:plage.Acte.fontColor};
        } else{
            return {back:plage.Lieu.backColor, font:plage.Lieu.fontColor}
        }
    }
    return (
        <div className={`StickerPlage ${className}`}>
            <div className={`content-sticker`} style={{background:getColor(Plage).back, color:getColor(Plage).font}}>
                {is_avatar &&
                    <div className={`avatar-place`}>
                        <TimedRoundAvatar BoxShadow={"1px 1px 3px rgba(0,0,0,.7)"} Border={true} src={Plage.User.avatarUrl!==undefined ? Plage.User.avatarUrl : '' } size={"other"} sizeP={"28px"}/>
                    </div>
                }
                <div className={`infos-place`}>
                    {is_lieu &&
                        <div className={`lieu-place one-info`}>{Plage.Lieu.libelle}</div>
                    }
                    {is_acte &&
                        <div className={`acte-place one-info`}>{Plage.Acte.libelle}</div>
                    }
                    {is_date &&
                        <div className={`date-place one-info`}>{new Date(Plage.plageAt).toLocaleDateString()}</div>
                    }
                    {is_horaire &&
                        <div className={`hor-place one-info`}>{ConvertBase5(Plage.heureDebut)} - {ConvertBase5(Plage.heureFin)}</div>
                    }
                </div>
            </div>
        </div>
    )
}

export const TimedStickersPlage = styled(TimedStickersPlageCtrl)`
  width: 100%;
  padding: ${props => props.padding ? props.padding : "0.25rem"};
  .content-sticker{
      height: 28px;
      font-size: 14px;
      border-radius: 20px;
      display: flex;
      gap: 10px;
      .infos-place{
        height: 100%;
        flex-grow: 1;
        padding: 0.25rem 0.5rem;
        display: flex;
        justify-content: flex-start;
        gap: 6px;
        .one-info{
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
  }
  
`
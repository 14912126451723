import React, {useCallback, useEffect, useState} from "react";
import styled, {useTheme} from "styled-components";
import {BiChevronUp} from "react-icons/bi";
import {MdLogin, MdLogout, MdWarningAmber} from "react-icons/md";
import {BsCheck} from "react-icons/bs";
import {ConvertBase5, DecToHHMM, MinutesToHHMM} from "../../../../components/functions/TimesFunctions";
import {OneDropHour} from "./OneDropHour";
import TimedInputText from "../../../../components/atoms/TimedInputText/TimedInputText";
import {useModifyPointeuseTreat} from "../../../../features/pointeuseTreat/pointeuseTreat.hooks";
import {useDebounce} from "../../../../services/hooks";
import { GrPowerReset } from "react-icons/gr";
import {myBoxShadow} from "../../../../assets/styles/elements";
import { MutatingDots } from "react-loader-spinner";


interface OnePlageTreatEditCtrlProps{
    className?:string;
    PointeuseTreat:PointeuseTreat;
    nbPTS:number;
    index:number;
}


const OnePlageTreatEditCtlr = ({className, PointeuseTreat, nbPTS, index}:OnePlageTreatEditCtrlProps)=>{
    const theme = useTheme();
    const [open, setOpen] = useState<boolean>(true)
    const [inAno, setInAno] = useState<boolean>(false)
    const [heureDeb, setHeureDeb] = useState<number>(0)
    const [heureFin, setHeureFin] = useState<number>(0)
    const [isJUn, setIsJUn] = useState(PointeuseTreat.jPlusUnPointageSortieFinal)
    const [time, setTime] = useState<number>(0)
    const [comment, setComment] = useState<string>('')
    const ComentDebounce = useDebounce(comment, 900);
    const [isLate, setIsLate] = useState<boolean>(false)
    const [isSoon, setIsSoon] = useState<boolean>(false)
    const [isDoubtE, setIsDoubtE] = useState<boolean>(false)
    const [isDoubtS, setIsDoubtS] = useState<boolean>(false)
    const [isDirty, setIsDirty] = useState<boolean>(false)
    const [isDirtySL, setIsDirtySL] = useState<boolean>(false)
    const [isDirtyDoubt, setIsDirtyDoubt] = useState<boolean>(false)
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [isResetable, setIsResetable] = useState(false);
    const {mutate} = useModifyPointeuseTreat();
    useEffect(()=>{
        if(isDirtyDoubt){
            setIsDirtyDoubt(false);
            const myDoubtEDec = isDoubtE ? 1 : 0;
            const myDoubtSDec = isDoubtS ? 1 : 0;
            const datas:PointeuseTreatModifyFormData = {
                id:PointeuseTreat.id,
                doubtPointage:{"E":myDoubtEDec, "S":myDoubtSDec},
            }
            mutate((datas), {
                onSuccess: () => {
                    setIsDirtyDoubt(false);
                }
            })
        }
    }, [isDoubtE, isDoubtS, isDirtyDoubt, PointeuseTreat.id, mutate])
    useEffect(()=>{
        if(isDirtySL){
            setIsDirtySL(false);
            const mySoonDec = isSoon ? 1 : 0;
            const myLateDec = isLate ? 1 : 0;
            const datas: PointeuseTreatModifyFormData = {
                        id: PointeuseTreat.id,
                        AnomalieHoraires: {"E":myLateDec, "S":mySoonDec},
                    }
                    mutate((datas), {
                        onSuccess: () => {
                            setIsDirtySL(false);
                        }
                    })
        }
    }, [isSoon, isLate, isDirtySL, PointeuseTreat.id, mutate])
    const SaveMe = useCallback(()=>{
        if((heureFin > heureDeb) || isJUn) {
            const datas: PointeuseTreatModifyFormData = {
                id: PointeuseTreat.id,
                pointageEntreeFinal: heureDeb,
                pointageSortieFinal: heureFin,
                jPlusUnPointageSortieFinal:isJUn
            }
            mutate((datas), {
                onSuccess: () => {
                    setIsDirty(false);
                    setIsSaving(false);
                }
            })
        } else {
            setIsDirty(false);
        }
    }, [heureDeb, heureFin, PointeuseTreat.id, mutate, isJUn])
    useEffect(()=>{
        console.log('captation');
        if(heureDeb !==0 && heureFin !==0 && isDirty && !isSaving){
            setIsDirty(false);
            const HDStart = PointeuseTreat.pointageEntreeFinal ? PointeuseTreat.pointageEntreeFinal : 0;
            const HFStart = PointeuseTreat.pointageSortieFinal ? PointeuseTreat.pointageSortieFinal : 0;
            const isJunFirst = PointeuseTreat.jPlusUnPointageSortieFinal;
            console.log(HFStart, heureFin)
            console.log(isJunFirst, isJUn);
            if(heureDeb !== HDStart || HFStart !== heureFin || isJunFirst!==isJUn) {
                console.log('Change !!!!');
                setIsSaving(true);
                SaveMe();
            } else {
                console.log('non changing');
            }
        } else {
            console.log('non chang');
            console.log(isDirty);
            console.log(heureDeb);
            console.log(heureFin);
            setIsSaving(false);
        }
    }, [heureDeb, heureFin, PointeuseTreat, isSaving, isDirty, SaveMe, isJUn])
    const SaveComent = ()=>{
        if(ComentDebounce === comment) {
            const datas: PointeuseTreatModifyFormData = {
                id: PointeuseTreat.id,
                comentFinalHour: ComentDebounce
            }
            mutate((datas))
        }
    }
    useEffect(()=>{
        if(ComentDebounce){
            const datas: PointeuseTreatModifyFormData = {
                id: PointeuseTreat.id,
                comentFinalHour: ComentDebounce
            }
            mutate((datas))
        }
    }, [ComentDebounce])
    const SaveTimeFinal = ()=>{
        const datas: PointeuseTreatModifyFormData = {
            id: PointeuseTreat.id,
            timeFinal: time
        }
        mutate((datas))
    }
    useEffect(()=>{
        const AnoE = PointeuseTreat.AnomaliePointages.E;
        const AnoS = PointeuseTreat.AnomaliePointages.S;
        const AnoHE = PointeuseTreat.AnomalieHoraires.E;
        const AnoHS = PointeuseTreat.AnomalieHoraires.S;
        console.log(PointeuseTreat.doubtPointage);
        const DoubtE = PointeuseTreat.doubtPointage.E;
        const DoubtS = PointeuseTreat.doubtPointage.S;
        const isRes = (PointeuseTreat.pointageEntree !== PointeuseTreat.pointageEntreeFinal) ||
            (PointeuseTreat.pointageSortie !== PointeuseTreat.pointageSortieFinal) || (PointeuseTreat.timeSystem !== PointeuseTreat.timeFinal)
        setHeureDeb(PointeuseTreat.pointageEntreeFinal ? PointeuseTreat.pointageEntreeFinal : 0)
        setHeureFin(PointeuseTreat.pointageSortieFinal ? PointeuseTreat.pointageSortieFinal : 0)
        setTime(PointeuseTreat.timeFinal ? PointeuseTreat.timeFinal : 0);
        setInAno((AnoE + AnoS)>0)
        setIsJUn(PointeuseTreat.jPlusUnPointageSortieFinal);
        setIsLate(AnoHE===1);
        setIsSoon(AnoHS===1);
        setComment(PointeuseTreat.comentFinalHour ? PointeuseTreat.comentFinalHour : '')
        setIsResetable(isRes);
        setIsDoubtE(DoubtE===1);
        setIsDoubtS(DoubtS===1);

    }, [PointeuseTreat])
    const handleClickHead = ()=>{
        setOpen(_o=>!_o);
    }
    const setSoonLate = (type:"soon" | "late")=>{
        if(type==="soon"){
            setIsSoon(_o=>!_o);
        } else {
            setIsLate(_o=>!_o);
        }
        setIsDirtySL(true);
    }
    const HandleChangeHeures = (heureStr:string, type:"heureDebut"|"heureFin"|"time", jun:boolean)=>{
        setIsDirty(true);
        setIsDirtyDoubt(true);
        console.log('type>>'+type)
        if(type==='heureDebut') {
            setIsDoubtE(false);
            if(heureStr === ''){
                setHeureDeb(0)
            } else {
                const Tab = heureStr.split(":")
                setHeureDeb(parseInt(Tab[0]) * 60 + parseInt(Tab[1]))
            }
        }
        if(type==='heureFin') {
            console.log('ici fin xxxxx')
            setIsDoubtS(false);
            if(heureStr === ''){
                console.log('videeeeeeeeeeee')
                setHeureFin(0)
                setIsJUn(false);
            } else {
                const Tab = heureStr.split(":")
                console.log('SETJUN ', jun)
                setIsJUn(jun);
                setHeureFin(parseInt(Tab[0]) * 60 + parseInt(Tab[1]))

            }
        }
        if(type==='time'){
            if(heureStr === ''){
                setHeureFin(0)
            } else {
                const Tab = heureStr.split(":")
                setTime(parseInt(Tab[0]) + parseInt(Tab[1]) / 60)
                SaveTimeFinal();
            }
        }
    }
    const HandleCommentTime = (id:string, val:string)=>{
        if(id==="coment") {
            setComment(val)
           // SaveComent()
        } else {
            HandleChangeHeures(val, "time", false);
        }
    }
    const RAZ = ()=>{
        const datas:PointeuseTreatModifyFormData={
            id:PointeuseTreat.id,
            pointageEntreeFinal:PointeuseTreat.pointageEntree,
            pointageSortieFinal:PointeuseTreat.pointageSortie,
        }
        setIsSaving(true);
        mutate((datas), {
            onSuccess:()=>{
                setIsSaving(false);
            }
        })
    }
    const LeverleDoute = (type:'E'|'S')=>{
        if(type === 'E'){
            setIsDoubtE(false);
        } else {
            setIsDoubtS(false);
        }
        setIsDirtyDoubt(true);
    }
    return (
        <div className={`OnePTEdit ${className} ${open ? 'isOpen' : 'isClosed'}`}>
            <div className={`head-one`} >
                <div className={`slide-up`} onClick={handleClickHead}>
                    <div className={`upDown`}><BiChevronUp/></div>
                </div>
                <div className={`lib-pt`} onClick={handleClickHead}>Période de travail {index}/{nbPTS}</div>
                <div className={`how-is-it`}>
                    {isResetable &&
                        <div className={`round-how reset`} data-tooltip={"Remise à zero"} onClick={RAZ}>
                            <GrPowerReset/>
                        </div>
                    }
                    <div className={`round-how ${inAno ? 'in-ano' : 'no-ano'}`} data-tooltip={inAno ? 'Anomalie' : 'aucune anomalie'}>
                        {inAno ? <MdWarningAmber/> : <BsCheck/>}
                    </div>
                </div>
            </div>
            <div className={`body-one`} aria-expanded={!open}>
                {isSaving &&
                    <div className={`shadow-saving`}>
                        <div className={`contentLoad`}>
                            <MutatingDots
                                height="100"
                                width="100"
                                color={theme.Primary}
                                secondaryColor={theme.Primary}
                                radius='12.5'
                                ariaLabel="mutating-dots-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                            />
                        </div>
                    </div>
                }
                <div className={`body-wrap`}>
                    <div className={`body-main`}>
                        <div className={"relevees"}>
                            <div className={`title-body`}>Heures de travail pointées, ajustées et finales</div>
                            <div className={"body-in"}>
                                <div className={"draw-releve"}>
                                    <div className={`line-input-times`}>
                                        <div className={`one-block-horaires`}>
                                            <div className={`real-pointage`}>({PointeuseTreat.pointageEntree ? MinutesToHHMM(PointeuseTreat.pointageEntree) : '--:--:--'}) {isJUn ? 'o' : 'n'}</div>
                                            <OneDropHour type={"heureDebut"} value={heureDeb} setValue={HandleChangeHeures} isDoubt={isDoubtE} leverDoute={LeverleDoute} jUn={isJUn} setJUn={setIsJUn}/>
                                        </div>
                                        <div className={`one-block-horaires`}>
                                            <div className={`real-pointage`}>({PointeuseTreat.pointageSortie ? MinutesToHHMM(PointeuseTreat.pointageSortie) : '--:--:--'})</div>
                                            <OneDropHour type={"heureFin"} value={heureFin} setValue={HandleChangeHeures} isDoubt={isDoubtS} leverDoute={LeverleDoute} setJUn={setIsJUn} jUn={isJUn}/>
                                        </div>
                                    </div>
                                    <div className={`line-drawing-time`}>
                                        Presence attendue - planning ({MinutesToHHMM(PointeuseTreat.planningDebut)} - {MinutesToHHMM(PointeuseTreat.planningFin)})
                                    </div>
                                    <div className={`line-soon-late`}>
                                        <div className={`check-soon-late`}>
                                            <div className={`checkMark ${isLate ? 'checked' : ''}`} onClick={()=>setSoonLate("late")}/>
                                            <div className={`lib-check`}>Arrivée en retard</div>
                                        </div>
                                        <div className={`check-soon-late`}>
                                            <div className={`checkMark ${isSoon ? 'checked' : ''}`} onClick={()=>setSoonLate("soon")}/>
                                            <div className={`lib-check`}>Partie en avance</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"retenues"}>
                            <div className={`title-body`}>Heures retenues (après calcul système)</div>
                            <div className={"body-in"}>
                                <div className={`line-final`}>
                                    <div className={`entry-time is-in`}>
                                        <div className={`lib`}>Entrée</div>
                                        <div className={`hours`}>
                                            <MdLogin/><span>{PointeuseTreat.pointageEntreeRetenu ? MinutesToHHMM(PointeuseTreat.pointageEntreeRetenu) : '--:--'}</span>
                                        </div>
                                    </div>
                                    <div className={`entry-time is-out`}>
                                        <div className={`lib`}>Sortie</div>
                                        <div className={`hours`}>
                                            <MdLogout/><span>{PointeuseTreat.pointageSortieRetenu ? MinutesToHHMM(PointeuseTreat.pointageSortieRetenu) : '--:--'}</span>
                                        </div>
                                    </div>
                                    <div className={`wrap-comment`}>
                                        <TimedInputText
                                            label={"Commentaires"}
                                            type={"text"}
                                            isRequired={false}
                                            isError={false}
                                            helperText={''}
                                            value={comment}
                                            setFieldValue={HandleCommentTime}
                                            id={"coment"}
                                            trigFocusOut={true}
                                        />
                                    </div>
                                    <div className={`wrap-time`}>
                                        <TimedInputText
                                            label={"Temps de travail"}
                                            type={"time"}
                                            isRequired={false}
                                            isError={false}
                                            helperText={''}
                                            value={time === 0 ? '' : DecToHHMM(time)}
                                            setFieldValue={HandleCommentTime}
                                            id={"time"}
                                            trigFocusOut={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`body-details`}>
                        <div className={`title-body`} style={{marginBottom:"10px"}}>Plages Plannings</div>
                        {PointeuseTreat.plages.map((item:Plage)=>(
                            <div className={`onePlage-detail`} key={`p-${item.id}`}>
                                <div className={`trait-lieu`} style={{background:item.Lieu.backColor}}/>
                                <div className={"infos-plage"}>
                                    <div className={`content-infos`}>
                                        <div className={`lieu-horaires`}><strong>{item.Lieu.libelle}</strong> - {item.Acte.libelle} ({ConvertBase5(item.heureDebut)} - {ConvertBase5(item.heureFin)})</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </div>
    )
}

export const OnePlageTreatEdit = styled(OnePlageTreatEditCtlr)`
  width: 100%;
  background: white;
  border-radius: 8px;
  margin-bottom: 25px;
  .real-pointage{
    font-size: 14px;
    font-style: italic;
    text-align: center;
    margin-bottom: 5px;
  }
  .onePlage-detail{
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    gap: 10px;
    margin-bottom: 5px;
    .trait-lieu{
      width: 5px;
      border-radius: 3px;
      min-height: 32px;
    }
    .infos-plage{
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 14px;
    }
  }
  .body-wrap{
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    .body-main{
      width: 70%;
    }
    .body-details{
      flex-grow: 1;
      padding: 0 1rem;
      border-left: solid ${props => props.theme.PrimaryExtraLight} 1px;
    }
  }
  .shadow-saving{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    background: rgba(255,255,255,.6);
    backdrop-filter: blur(3px);
    width: 100%;
    height: 100%;
    .contentLoad{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .entry-time{
    &.is-in{
      .hours{
        color: ${props=>props.theme.Success};
      }
    }
    &.is-out{
      .hours{
        color: ${props=>props.theme.Complementary};
      }
    }
    .lib{
      font-size: 14px;
      font-weight: bold;
      color: ${props=>props.theme.Dark};
    }
    .hours{
      padding: 0.25rem;
      font-weight: bold;
      display: flex;
      justify-content: flex-start;
      gap: 5px;
      align-items: center;
      font-size: 13px;
    }
  }
  .line-final{
    display: flex;
    justify-content: flex-start;
    gap:10px;
    align-items: baseline;
    margin: 20px auto;
  }
  &.isClosed{
    .head-one{
      .slide-up{
        .upDown{
          transform: rotate(180deg);
          transition: transform 0.3s;
        }
      }
    }
  }
  .check-soon-late{
    display: flex;
    gap:10px;
    align-items: center;
    .checkMark{
      width: 15px;
      height: 15px;
      border-radius: 2px;
      border:solid ${props => props.theme.Dark} 1px;
      position: relative;
      &:hover{
        background: ${props=>props.theme.NeutreMegaLight};
        cursor: pointer;
      }
      &:after{
        content: '✔';
        font-size: 12px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: scale(0) translate(-50%,-54%);
        opacity: 0;
        transition: transform 0.2s;
      }
      &.checked:after{
        transform: scale(1) translate(-50%,-54%);
        opacity: 1;
        transition: transform 0.2s;
      }
    }
    .lib-check{
      font-size: 14px;
    }
  }
  .line-soon-late{
    display: flex;
    margin-top: 5px;
    justify-content: space-between;
  }
  .retenues{
    margin-top: 20px;
    border-top:dashed 2px ${props => props.theme.Primary};
  }
  .body-one[aria-expanded="true"] {
    max-height: 0;
    padding: 0;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  }
  .body-one{
    padding: 0.25rem 1rem;
    width: 100%;
    overflow: hidden;
    max-height: 1000px;
    transition: max-height 1s ease-in-out;
    position: relative;
    .title-body{
      font-size: 14px;
      font-weight: bold;
      margin: 0.75rem 0 0.5rem 0;
      color:${props => props.theme.Dark};
    }
  }
  .head-one{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: ${props=>props.theme.PrimaryExtraLight};
    padding: 0.25rem;
    .lib-pt{
      font-size: 14px;
      font-weight: bold;
      color: ${props=>props.theme.Dark};
      &:hover{
        cursor: pointer;
      }
    }
    .how-is-it{
      flex-grow: 1;
      padding-right: 0.5rem;
      display: flex;
      justify-content: flex-end;
      .round-how{
        width: 22px;
        height: 22px;
        border-radius: 50%;
        text-align: center;
        svg{margin: auto}
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: white;
        color: ${props=>props.theme.Success};
        position: relative;
        &:hover:after{
          content: attr(data-tooltip);
          font-size: 12px;
          left: 50%;
          top: calc(100% + 5px);
          transform: translateX(-100%);
          position: absolute;
          padding: 0.25rem;
          width: max-content;
          background: ${props=>props.theme.PrimaryDark};
          color: white;
          border-radius: 0.15rem;
          ${myBoxShadow};
        }
        &.in-ano{
          background: ${props=>props.theme.Complementary};
          color: white;
          font-size: 12px;
        }
        &.reset{
          margin-right: 15px;
          &:hover{
            cursor: pointer;
          }
        }
      }
    }
    .slide-up{
      padding: 0 0.25rem;
      &:hover{
        cursor: pointer;
      }
      .upDown{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transform: rotate(0deg);
        transition: transform 0.3s;
      }
    }
  }
  .line-drawing-time{
    width: 100%;
    border-radius: 8px;
    padding: 0.5rem;
    background: ${props => props.theme.PrimarySuperLight};
    color: ${props=>props.theme.PrimaryMegaDark};
    font-size: 13px;
  }
  .line-input-times{
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
  }
  .body-in{
    width: 80%;
    margin: auto;
  }
  .line-input-times{
    
  }
  .triangle{
    position: absolute;
    top: 100%;
    left: 10px;
    right: auto;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 7.5px 0 7.5px;
    border-color: ${props => props.theme.SecondaryExtraLight} transparent transparent transparent;
    &.end{
      border-color: ${props => props.theme.ComplementaryExtraLight} transparent transparent transparent;
      left: auto;
      right: 10px;
    }
  }
`
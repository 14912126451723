import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {ConvertMinToHeure} from "../../../../components/functions/TimesFunctions";
import {useDrop} from "react-dnd";
import {ItemTypes} from "../../../Modaux/ModalGereDayPointage";
import {MdLogin, MdLogout} from "react-icons/md";
import { BsFlagFill } from "react-icons/bs";

interface OneDropHourCtrlProps{
    className?:string;
    type:"heureDebut"|"heureFin"|"time";
    value:number;
    setValue:(heureStr:string, type:"heureDebut"|"heureFin"|"time", jUn:boolean)=>void
    isDoubt:boolean;
    leverDoute:(type:'E'|'S')=>void;
    jUn:boolean;
    setJUn:(is:boolean)=>void;
}

const OneDropHourCtrl = ({className, type, value, setValue, isDoubt, leverDoute, jUn, setJUn}:OneDropHourCtrlProps)=>{
    const refH = useRef<HTMLInputElement>(null)
    useEffect(()=>{
        console.log('JJJJJJJJJJJJ')
        console.log(jUn)
    }, [jUn])
    const HandleChange = ()=>{
        if(refH.current){
            setValue(refH.current.value, type, jUn)
        }
    }
    const [{ isOver }, drop] = useDrop(
        () => ({
            accept: ItemTypes.Horaire,
            drop: (item) => moveHoraire(item),
            collect: (monitor) => ({
                isOver: !!monitor.isOver()
            })
        }),
        []
    )
    const moveHoraire = (item:any)=>{
        console.log(item);
        setValue(item.horaire, type, item.jUn)
    }
    return (
        <div className={`heureDeb is-hour ${className} ${isOver ? 'overing' : ''}`} ref={drop}>
            {isDoubt &&
                <div className={`doubt ${type==='heureDebut' ? 'is-entree' : 'is-sortie'}`} onClick={()=>leverDoute(type==='heureDebut' ? 'E' : 'S')} data-infos={"Timed a detecté ce pointage par calcul, si vous êtes d'accord avec celui-ci, cliquez sur le drapeau pour lever le doute"}><BsFlagFill/></div>
            }
            <div className={`triangle ${type === 'heureFin' ? 'end' : ''}`}/>
            {type === 'heureDebut' ? <MdLogin/> : <MdLogout/>}
            <input className={`input-time`} type={"time"} ref={refH} value={value === 0 ? '' : ConvertMinToHeure(value)} onChange={HandleChange}/>
            {type === 'heureFin' &&
                <div className={"wrap_jun"}>
                    <input className={`input-jun`} type={"checkbox"} disabled={value===0} checked={jUn} onChange={()=>setJUn(!jUn)}/>
                    <label>j+1</label>
                </div>
            }
        </div>
    )
}

export const OneDropHour = styled(OneDropHourCtrl)`
    border-radius: 12px;
    width: max-content;
    position: relative;
    background: ${props => props.type === 'heureDebut' ? props.theme.SuccessExtraLight : props.theme.ComplementaryExtraLight};
    color: ${props => props.type === 'heureDebut' ? props.theme.SuccessDark : props.theme.ComplementaryDark};
    padding: 0.25rem 1rem;
    display: flex;
    justify-content: flex-start;
  align-items: center;
  gap: 5px;
  position: relative;
  .wrap_jun{
    position: absolute;
    display: flex;
    justify-content: flex-start;
    gap: 3px;
    top: 50%;
    left: 105%;
    transform: translateY(-50%);
  }
  .doubt{
    position: absolute;
    top: 50%;
    width: 25px;
    height: 25px;
    background: ${props=>props.theme.TertiaryExtraLight};
    transform: translateY(-50%);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:hover{
      cursor: pointer;
    }
    &:hover:after{
      content: attr(data-infos);
      position: absolute;
      color: ${props=>props.theme.PrimaryDark};
      width: 120px;
      left: calc(100% + 20px);
      right: auto;
      z-index: 6;
      font-size: 14px;
      padding: 0.25rem;
      border-radius: 6px;
      background: white;
      box-shadow: 1px 1px 3px rgba(0,0,0,0.2);
    }
    svg{
      font-size: 14px;
      fill:${props=>props.theme.TertiaryDark};
      margin: auto;
    }
    &.is-entree{
      left:105%;
      right: auto;
    }
    &.is-sortie{
      right:105%;
      left: auto;
      &:hover:after{
        right: calc(100% + 20px);
        left: auto;
      }
    }
  }
  &.overing{
    filter: brightness(95%);
    .triangle{
      filter: brightness(95%);
    }
  }
  input{
    background: transparent;
    border:none;
    color: ${props => props.type === 'heureDebut' ? props.theme.SuccessDark : props.theme.ComplementaryDark};
  }
`

import React from "react";
import styled from "styled-components";
import {useGetUserHeureDD} from "../../../../../../features/userHeureDD/userHeureDD.hooks";
import {OneitemDateW} from "./WeeklyHeures";
import {getNumWeek} from "../../../../../../components/functions/TimesFunctions";
import TimedIconButton from "../../../../../../components/atoms/TimedIconButton/TimedIconButton";
import {BsFillCalendarDateFill} from "react-icons/bs";
import {useNavigate} from "react-router-dom";
import {useCentreConnectedData} from "../../../../../../services/hooks";


interface WeekTimeCtrlProps{
    className?:string;
    OneitemDateW:OneitemDateW
    idUser:number;
    supervisor?:boolean;
}

const WeekTimeCtrl = ({className, OneitemDateW, idUser, supervisor}:WeekTimeCtrlProps)=>{
    const centre = useCentreConnectedData();
    const navigate = useNavigate();
    const TimesQuery = useGetUserHeureDD(idUser, OneitemDateW.Monday, OneitemDateW.Sunday, supervisor ? centre : null)
    const GoPlanning = (Monday:Date)=>{
        const daty = Monday.toLocaleDateString();
        navigate('/timed_application/planning_indi_hebdo?idCollab='+idUser+'&from='+daty);
    }
    return (
        <div className={`OneWeekTime ${className}`}>
            <div className={`head`}>
                <div className={"lib-sem"}>
                    {`S${getNumWeek(OneitemDateW.Monday)} - ${OneitemDateW.Monday.toLocaleDateString()} au ${OneitemDateW.Sunday.toLocaleDateString()}`}
                </div>
                <div className={`wrap-go-pl`}>
                    <TimedIconButton
                        size={"sm"}
                        themeColor={"Primary"}
                        Icon={<BsFillCalendarDateFill/>}
                        onClick={()=>GoPlanning(OneitemDateW.Monday)}
                        toolTip={"voir le planning"}
                    />
                </div>
            </div>
            <div className={`body`}>
                {TimesQuery.isLoading ? 'En cours de chargement...' :
                    <>
                        <table className={`table-heure`}>
                            <tbody>
                                <tr className={`line-std`}>
                                    <td>Heures Contrat</td>
                                    <td>{TimesQuery.data ? Math.round(TimesQuery.data.timeToDo*100)/100 : 0}</td>
                                </tr>
                                <tr className={`line-std`}>
                                    <td>Heures travaillées (planning)</td>
                                    <td>{TimesQuery.data ? Math.round(TimesQuery.data.timeSchedules*100)/100 : 0}</td>
                                </tr>
                                <tr className={`line-std`}>
                                    <td>Heures non travaillées (planning)</td>
                                    <td>{TimesQuery.data ? Math.round(TimesQuery.data.timeNoWork*100)/100 : 0}</td>
                                </tr>
                                <tr className={`sous-total`}>
                                    <td>Heures totales planning</td>
                                    <td>{Math.round(((TimesQuery.data ? TimesQuery.data.timeNoWork : 0) + (TimesQuery.data ? TimesQuery.data.timeSchedules : 0))*100)/100}</td>
                                </tr>
                                <tr className={`line-std`}>
                                    <td>Heures travaillées (relevées)</td>
                                    <td>{TimesQuery.data ? Math.round(TimesQuery.data.timeDid*100)/100 : 0}</td>
                                </tr>
                                <tr className={`line-std`}>
                                    <td>Heures non travaillées (relevées)</td>
                                    <td>{TimesQuery.data ? Math.round(TimesQuery.data.timeNoWork*100)/100 : 0}</td>
                                </tr>
                                <tr className={`sous-total`}>
                                    <td>Heures totales relevées</td>
                                    <td>{Math.round(((TimesQuery.data ? TimesQuery.data.timeNoWork : 0) + (TimesQuery.data ? TimesQuery.data.timeDid : 0))*100)/100}</td>
                                </tr>
                                <tr className={`line-std`}>
                                    <td>Heures Régulées</td>
                                    <td>{TimesQuery.data ? Math.round(TimesQuery.data.timeBalancing*100)/100 : 0}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={'footer'}>
                            <table className={`table-footer`}>
                                <tbody>
                                    <tr className={`footer_line`}>
                                        <td>Solde de la semaine</td>
                                        <td className={TimesQuery.data && TimesQuery.data.solde > 0 ? 'good' : 'bad'}>{TimesQuery.data ? Math.round(TimesQuery.data.solde*100)/100 : 0}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

const WeekTime = styled(WeekTimeCtrl)`
  .footer{
    padding: 0.5rem 0;
    table{
      tr{
        td.good{
          color:${props => props.theme.Secondary}
        }
        td.bad{
          color:${props => props.theme.Complementary}
        }
        td{
          font-size: 15px;
          font-weight: bold;
        }
      }
    }
  }
  .head{
    display: flex;
    margin-bottom: 10px;
    justify-content: start;
    color:${props => props.theme.Dark};
    font-weight: bold;
    align-items: center;
    .lib-sem{
      flex-grow: 1;
    }
    .wrap-go-pl{
      margin-left: 5px;
    }
  }
  background: white;
  margin: 0.5rem auto;
  padding: 0.5rem;
  border-radius: 6px;
  font-size: 13px;
  width: 350px;
  flex-shrink: 0;
  table{
    width: calc(100% - 10px);
    border-collapse: collapse;
    &.table-heure{
      margin-left: 10px;
    }
    tr{
      td{
        padding: 0.2rem;
      }
      &.sous-total{
        td{
          border-top: solid ${props=>props.theme.PrimaryDark} 1px;  
          border-bottom: solid ${props=>props.theme.PrimaryDark} 1px;
          font-weight: bold;
        }
        
      }
      &.line-std{
        td:first-child{
          color: ${props=>props.theme.Neutre};
        }
      }
      td:last-child{
        text-align: right;
      }
    }
  }
`

export default WeekTime;

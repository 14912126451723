import styled, {useTheme} from "styled-components";
import {useCentreConnectedData} from "../../../../services/hooks";
import {useGetAllActes} from "../../../../features/acte/acte.hooks";
import support from "../../VieCentre/Support/Support";
import {useGetAllLieu} from "../../../../features/lieu/lieu.hooks";
import {useEffect, useRef, useState} from "react";
import {useDrag} from "react-dnd";
import {ItemType} from "../PlanningGlobalLieu/ItemType";
import {ItemTypePP} from "./ItemTypePP";
import {getEmptyImage} from "react-dnd-html5-backend";
import {BiTargetLock} from "react-icons/bi";

interface PlageAdd_dragCtrlProps{
    className?:string;
    supervisor?:boolean;
}

const PlageAdd_dragCtrl = (props:PlageAdd_dragCtrlProps)=>{
    const centre = useCentreConnectedData()
    const theme = useTheme();
    const ActesQuery = useGetAllActes(props.supervisor ? centre : null)
    const LieuxQuery = useGetAllLieu(props.supervisor ? centre : null)
    const [backColor, setBackColor] = useState(theme.PrimaryLight)
    const [acteCurr, setActeCurr] = useState<Acte|null>(null)
    const [lieuCurr, setLieuCurr] = useState<Lieu|null>(null)
    const refActe = useRef<HTMLSelectElement>(null)
    const refLieu = useRef<HTMLSelectElement>(null)
    const handleChangeActe = ()=>{
        if(refActe.current){
            const myActe = ActesQuery.data?.find(a=>a.id.toString() === refActe.current?.value)
            setActeCurr(myActe||null)
            setBackColor(myActe ? myActe.backColor : theme.PrimaryLight)
        }
    }
    const handleChangeLieu = ()=>{
        if(refLieu.current){
            const myLieu = LieuxQuery.data?.find(l=>l.id.toString() === refLieu.current?.value)
            setLieuCurr(myLieu||null)
        }
    }
    const [{isDragging}, drag, preview] = useDrag(()=>({
        type:ItemTypePP.NEWPLAGE,
        item:{lieu:lieuCurr, acte:acteCurr},
        canDrag:()=>(acteCurr!==null && lieuCurr!==null),
        collect:(monitor)=>({
            isDragging: !!monitor.isDragging()
        })
    }), [acteCurr, lieuCurr])
    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true })
    }, [])
    return (
        <div className={`plage_add_drap ${props.className}`} style={{background:backColor}} ref={drag}>
            <div className={`wrap_drag_item ${!!lieuCurr && !!acteCurr ? 'grabbable' : ''}`}><BiTargetLock/></div>
            <select value={lieuCurr ? lieuCurr.id : 0} onChange={handleChangeLieu} ref={refLieu}>
                <option value={0}>Lieu</option>
                {LieuxQuery.data?.map((item:Lieu)=>(
                    <option key={`choice_lieux${item.id}`} value={item.id}>{item.libelle}</option>
                ))}
            </select>
            <select value={acteCurr ? acteCurr.id : 0} onChange={handleChangeActe} ref={refActe}>
                <option value={0}>Acte</option>
                {ActesQuery.data?.filter(a=>a.isPresent).map((item:Acte)=>(
                    <option key={`choice_acte${item.id}`} value={item.id}>{item.libelle}</option>
                ))}
            </select>
        </div>
    )
}

export const PlageAdd_drag = styled(PlageAdd_dragCtrl)`
  width: max-content;
  gap: 10px;
  padding: 0.25rem 0.5rem;
  display: flex;
  border-radius: 4px;
  align-items: center;
  select{
    background: transparent;
    border-radius: 6px;
  }
  .wrap_drag_item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    &.grabbable:hover{
      cursor: grab;
    }
  }
`


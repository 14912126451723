import {dataFetcher} from "../../services/dataGuetter/dataFetcher";

/**
 * Permet d'ajouter un contrat à un collaborateur
 * @param datas
 * @constructor
 */
export function NewContrat(datas:ContratFormData):Promise<Contrat>{
    return dataFetcher(`contrats/add_new`, {
        method:'post',
        body:datas,
    })
}

export function EndContrat(datas:ContratEndFormData):Promise<Contrat>{
    return dataFetcher(`contrats/${datas.id}`, {
        method:'put',
        body: {
            endAt:datas.endAt,
            motifFinContrat:datas.motifFinContrat
        }
    })
}


export function ModifyContrat(id:number,datas:ContratEditFD):Promise<Contrat>{
    return dataFetcher(`contrats/${id}`, {
        method:'put',
        body:datas
    })
}

export function updateJoursTravContrat(id:number, datas:JoursTravail):Promise<User>{
    return dataFetcher(`contrats/${id}`, {
        method:'put',
        body: {joursTravail:datas}
    })
}
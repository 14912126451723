import styled from "styled-components";

interface OneTimeCtrlProps{
    className?:string;
    height:number;
    myHour:number;
    Wid:string;
}

const OneTimeCtrl = ({className, myHour}:OneTimeCtrlProps)=>{
    return (
        <div className={`one_hours ${className}`}>
            <span>{(`0${myHour}`).slice(-2)}</span>
        </div>
    )
}

export const OneTime = styled(OneTimeCtrl)`
  width: ${props => props.Wid};
  height: ${props => props.height+"px"};
  position: relative;
  border-top : solid 1px transparent;
  border-bottom: solid ${props=>props.theme.NeutreLight} 1px;
  span{
    font-size: 11px;
    position: absolute;
    background: white;
    padding: 0.25rem;
    left: 0;
    top: 100%;
    transform: translateY(-50%);
  }
`
import React from "react";
import { FiChevronRight } from "react-icons/fi";
import styled from "styled-components";

interface TimedItemContextCtrlProps{
    className?:string;
    Icon:React.ReactNode;
    label:string;
    Action:Function;
    isSub?:boolean;
    fontSize?:string;

}

const TimedItemContextCtrl = ({className, Icon, label, Action, isSub}:TimedItemContextCtrlProps)=>{
    return (
        <div className={`TimedItemContext ${className}`} onClick={(e)=>Action(e)}>
            <div className={`icon-place`}>{Icon}</div>
            <div className="lib-place">{label}</div>
            {isSub &&
                <div className={"next-place"}><FiChevronRight/></div>
            }
        </div>
    )
}

const TimedItemContext = styled(TimedItemContextCtrl)`
    width: 100%;
  padding: 0.5rem 0.25rem;
  display: flex;
  justify-content: start;
  align-items: stretch;
  gap: 5px;
  color:${props=>props.theme.TertiaryExtraDark};
  font-size: ${props=>props.fontSize ? props.fontSize : "16px"};
  &:first-child{
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &:last-child{
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  &:hover{
    cursor: pointer;
    background: ${props=>props.theme.PrimaryMegaLight};
    font-weight: bold;
  }
  .icon-place{
    width: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    svg{
      margin: auto;
    }
  }
  .lib-place{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 8px;
  }
  .next-place{
    padding-right: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

export default TimedItemContext;
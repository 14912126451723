import React, {useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import {UseGetPointeuseTreatByParams} from "../../../../../../features/pointeuseTreat/pointeuseTreat.hooks";
import TimedSkeletonList from "../../../../../../components/atoms/TimedSkeleton/TimedSkeletonList";
import TimedIconButton from "../../../../../../components/atoms/TimedIconButton/TimedIconButton";
import {AiOutlinePlus} from "react-icons/ai";
import {useModal} from "../../../../../../hooks/useModal";
import {ModalNewRegule} from "../../../../../Modaux/ModalNewRegule";
import {useGetOneUser} from "../../../../../../features/user/user.hooks";
import {useCentreConnectedData} from "../../../../../../services/hooks";

interface DetailsDailyRegulesCtrlProps{
    className?:string;
    dateUseEnd:string;
    idUser:number;
    supervisor?:boolean;
}

const DetailsDailyRegulesCtrl = ({className, idUser, dateUseEnd, supervisor}:DetailsDailyRegulesCtrlProps)=>{
    const centre = useCentreConnectedData();
    const [params, setParams] = useState<any|undefined>(undefined)
    const UserQuery = useGetOneUser(idUser);
    const PTQuery = UseGetPointeuseTreatByParams(params);
    const {open, toggle} = useModal();
    useEffect(()=>{
        const now = new Date();
        let dateS = new Date(new Date(dateUseEnd).getFullYear(), new Date(dateUseEnd).getMonth(), 1);
        dateS = new Date(dateS.getTime() - dateS.getTimezoneOffset()*60000);
        const dateE = new Date(dateUseEnd).getMonth() === now.getMonth() ? new Date(now.getTime() - 86400000) : new Date(dateUseEnd);
        let paramsX:any = {"dateAT[after]":dateS.toISOString().slice(0,10), "dateAT[before]":dateE.toISOString().slice(0,10), User:idUser}
        if(supervisor){
            paramsX.Centre = centre;
        }
        setParams(paramsX)
    }, [dateUseEnd, idUser, supervisor, centre])
    const ListRegule = useMemo(()=>{
        let list:PointeuseTreat[] = [];
        if(PTQuery.data){
            return PTQuery.data.filter(pt=>!!pt.MotifReguleTemps && pt.MotifReguleTemps.id !==7).sort((a:PointeuseTreat, b:PointeuseTreat)=>{
                return new Date(a.dateAT) > new Date(b.dateAT) ? 1 : -1;
            })
        }
        return list;
    }, [PTQuery.data])
    const handleClickAddRegule = ()=>{
        toggle();
    }
    return (
        <div className={`dailyRegules ${className}`}>
            <div className={`wrap-action`}>
                {UserQuery.data &&
                    <TimedIconButton
                        size={"medium"}
                        themeColor={"Success"}
                        Icon={<AiOutlinePlus/>}
                        toolTip={"Ajouter une Régulation"}
                        onClick={handleClickAddRegule}
                        BackAlw={true}
                        isActive={false}
                    />
                }
            </div>
            {PTQuery.isLoading ?
                <TimedSkeletonList nbBar={3}/>:
                <>
                    {ListRegule.length === 0 ?
                        <p className={`no-result`}>Aucune régulation</p>:
                        <ul>
                            {ListRegule.map((item:PointeuseTreat, idx:number)=>(
                                <li key={`one-regule${idx}`} className={`one-regule`}>
                                    <div className={`wrap-infos-regule`}>
                                        <div className={`date-place`}>{new Date(item.dateAT).toLocaleDateString()}</div>
                                        <div className={`motif`}>{item.MotifReguleTemps?.libelle}</div>
                                        <div className={`temps ${item.MotifReguleTemps?.coef === -1 ? 'warn' : 'good'}`}>{item.timeFinal} heures</div>
                                        {item.comentFinalHour !== '' && item.comentFinalHour!==null &&
                                            <div className={`coment`}>{item.comentFinalHour}</div>
                                        }
                                    </div>
                                </li>
                            ))}
                        </ul>
                    }
                </>
            }
            {open && UserQuery.data &&
                <ModalNewRegule
                    title={"Nouvelle régulation"}
                    Close={toggle}
                    size={"regular"}
                    position={"right"}
                    topPosition={"Top"}
                    user={UserQuery.data}
                    month={new Date(dateUseEnd).getMonth()+1}
                    year={new Date(dateUseEnd).getFullYear()}
                />
            }
        </div>
    )
}

const DetailsDailyRegules = styled(DetailsDailyRegulesCtrl)`
  .wrap-action{
    padding: 1rem 0.5rem 0.5rem 0.5rem;
    display: flex;
    justify-content: flex-end;
  }
  .date-place{
    font-weight: bold;
  }
  .temps{
    font-weight: bold;
    &.good{
      color:${props => props.theme.Success};
    }
    &.warn{
      color:${props => props.theme.Complementary};
    }
  }
  ul{
    padding: 0 .5rem;
  }
  .wrap-infos-regule{
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    gap:10px
  }
`

export default DetailsDailyRegules;
import styled from "styled-components";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {useGetAllUser} from "../../../features/user/user.hooks";
import {useGetFonctions} from "../../../features/fonction/fonction.hooks";
import {LibelleCurrentSmart} from "./LibelleCurrentSmart";
import {ListSmart} from "./ListSmart";
import {GrClose} from "react-icons/gr";
import {ContentSmart, ZoneSearchSmart} from "./styledElt";
import {FaUser} from "react-icons/fa";
import {BsPlus, BsPlusCircleFill} from "react-icons/bs";

interface TimedSelectPersonneScopeCtrlProps{
    className?:string;
}
const useGetParams = ()=>{
    const [searchParams, setSearchParams] = useSearchParams();
    const [TabFrom, setTabFrom] = useState<string|undefined>(undefined);
    const [TabTo, setTabTo] = useState<string|undefined>(undefined);
    const [TabLieu, setTabLieu] = useState<string|undefined>(undefined);
    const [TabFx, setTabFx] = useState<string|undefined>(undefined);
    const [TabCollab, setTabCollab] = useState<string|undefined>(undefined);
    const [TabVues, setTabVues] = useState<string|undefined>(undefined);
    useEffect(()=>{
        const TabParamsB = [...Array.from(searchParams)];
        const TabFromx = TabParamsB.find(tab => tab[0] === 'from');
        const TabTox = TabParamsB.find(tab => tab[0] === 'to');
        const TabLieux = TabParamsB.find(tab => tab[0] === 'lieu');
        const TabFxx = TabParamsB.find(tab => tab[0] === 'fx');
        const TabCollabx = TabParamsB.find(tab => tab[0] === 'idCollab');
        const TabVuesx = TabParamsB.find(tab=>tab[0]==='vues');
        setTabFrom(TabFromx ? TabFromx[1] : undefined)
        setTabTo(TabTox ? TabTox[1] : undefined)
        setTabLieu(TabLieux ? TabLieux[1] : undefined)
        setTabFx(TabFxx ? TabFxx[1] : undefined)
        setTabCollab(TabCollabx ? TabCollabx[1] : undefined)
        setTabVues(TabVuesx ? TabVuesx[1] : undefined)
    }, [searchParams])
    return {TabFrom, TabTo, TabFx, TabLieu, TabVues, TabCollab}
}
interface my_params{
    index:string,
    from?:string,
    to?:string,
    lieu?:string,
    fx?:string,
    idCollab?:string,
    vues?:string
}

interface ListByFonction{
    libelle:string;
    list:User[];
}

const TimedSelectPersonneScopeCtrl = ({className}:TimedSelectPersonneScopeCtrlProps)=>{
    const {TabFrom, TabTo, TabFx, TabLieu, TabVues, TabCollab} = useGetParams()
    const UsersQuery =  useGetAllUser();
    const FonctionsQuery = useGetFonctions();
    const refObj = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [libelleCurrent, setLibelleCurrent] = useState('');
    const refSearch = useRef<HTMLInputElement>(null)
    const [search, setSearch] = useState('');
    const myListFonctions = useMemo(()=>{
        let list:Fonction[] = [];
        const regex = new RegExp(""+search+"", 'gi');
        if(FonctionsQuery.data){
            list = FonctionsQuery.data.filter(f=>f.libelle.match(regex) || search==='').sort((a:Fonction, b:Fonction)=>a.libelle>b.libelle ? 1 : -1)
        }
        return list;
    }, [search, FonctionsQuery.data])
    const myListUser = useMemo(()=>{
        const list:ListByFonction[] = []
        const regex = new RegExp(""+search+"", 'gi');
        if(FonctionsQuery.data && UsersQuery.data){
            FonctionsQuery.data.map(fx=>{
                const UserConcerned = UsersQuery.data.filter(u=>u.Fonction.id === fx.id && u.FonctionAdm.id !== 3 && u.isActif && (search === '' || (`${u.prenom} ${u.nom}`).match(regex)))
                console.log(fx.libelle, UserConcerned.length)
                if(UserConcerned.length>0){
                    list.push({libelle:fx.libelle, list:UserConcerned.sort((a:User, b:User)=>a.nom > b.nom ? 1 : -1)})
                }
            })
            const UserReplaced = UsersQuery.data.filter(u=>u.FonctionAdm.id === 3 && u.isActif && (search === '' || (`${u.prenom} ${u.nom}`).match(regex)))
            const UserInactif =UsersQuery.data.filter(u=>!u.isActif && (search === '' || (`${u.prenom} ${u.nom}`).match(regex)))
            if(UserReplaced.length>0){
                const FonctionOne = FonctionsQuery.data.find(f=>f.id === 1);
                if(FonctionOne) {
                    list.push({libelle: FonctionOne.libelle+" Remplaçant", list:UserReplaced.sort((a:User, b:User)=>a.nom > b.nom ? 1 : -1)})
                }
            }
            if(UserInactif.length>0){
                list.push({libelle:"Inactif", list:UserInactif.sort((a:User, b:User)=>a.nom > b.nom ? 1 : -1)})
            }

        }
        return list;
    }, [FonctionsQuery.data, UsersQuery.data, search])
    useEffect(()=>{
        if(FonctionsQuery.data && UsersQuery.data){
            if(TabFx){
                const ids = TabFx.split('#');
                const Fonctions = FonctionsQuery.data.filter(f=>ids.indexOf(f.id.toString())!==-1);
                if(Fonctions){
                    setLibelleCurrent(Fonctions.map(f=>f.libelle).join(', '))
                }
            } else if(TabCollab){
                const ids = TabCollab.split('#');
                const Users = UsersQuery.data.filter(u=>ids.indexOf(u.id.toString())!==-1)
                if(Users){
                    setLibelleCurrent(Users.map(u=>`${u.prenom} ${u.nom}`).join(', '))
                }
            } else {
                setLibelleCurrent('')
            }
        }
    }, [TabCollab, TabFx, FonctionsQuery.data, UsersQuery.data])
    useEffect(() => {
        function handleClickOutside(event:any) {
            if (refObj.current && !refObj.current.contains(event.target)) {
                setOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refObj]);

    const AddUser = (user:User, option:0|1)=>{
        let newTabCollab;
        if(TabCollab && option===0) {
            const TabCollabTab = TabCollab.split('#')
            if(TabCollabTab.indexOf(user.id.toString())!==-1){
                newTabCollab = [...TabCollabTab].filter(s=>s!==user.id.toString()).join('#')
            } else {
                newTabCollab = [...TabCollabTab, user.id.toString()].join('#');
            }
        } else{
            newTabCollab = user.id.toString()
        }
        let myParams: my_params = {
            from: TabFrom!==undefined ? TabFrom : '',
            to: TabTo!==undefined ? TabTo : '',
            lieu: TabLieu!==undefined ? TabLieu : '',
            fx: TabFx,
            idCollab: newTabCollab!=='' ? newTabCollab : undefined,
            vues:TabVues!==undefined ? TabVues : '',
            index: '0'
        };
        if(TabFrom===undefined) delete myParams.from;
        if(TabTo===undefined) delete myParams.to;
        if(TabLieu===undefined) delete myParams.lieu;
        if(TabVues===undefined) delete myParams.vues;
        if(myParams.idCollab===undefined) delete myParams.idCollab;
        delete myParams.fx;
        const meP = {...myParams}
        setSearch('');
        setSearchParams(meP);
        if(option === 1){
            setOpen(false);
        }
    }
    const AddFonction = (fx:Fonction, option:0|1)=>{
        let newTabFx;
        if(TabFx && option===0) {
            const TabFxTab = TabFx.split('#')
            if(TabFxTab.indexOf(fx.id.toString())!==-1){
                newTabFx = [...TabFxTab].filter(s=>s!==fx.id.toString()).join('#')
            } else {
                newTabFx = [...TabFxTab, fx.id.toString()].join('#');
            }
        } else{
            newTabFx = fx.id.toString()
        }
        let myParams: my_params = {
            from: TabFrom!==undefined ? TabFrom : '',
            to: TabTo!==undefined ? TabTo : '',
            lieu: TabLieu!==undefined ? TabLieu : '',
            fx: newTabFx!=='' ? newTabFx : undefined,
            idCollab: TabCollab!==undefined ? TabCollab : '',
            vues:TabVues!==undefined ? TabVues : '',
            index: '0'
        };
        if(TabFrom===undefined) delete myParams.from;
        if(TabTo===undefined) delete myParams.to;
        if(TabLieu===undefined) delete myParams.lieu;
        if(TabVues===undefined) delete myParams.vues;
        if(myParams.fx===undefined) delete myParams.fx;
        delete myParams.idCollab;
        const meP = {...myParams}
        setSearch('');
        setSearchParams(meP);
        if(option === 1){
            setOpen(false);
        }
    }
    const Remove = ()=>{
        let myParams: my_params = {
            from: TabFrom!==undefined ? TabFrom : '',
            to: TabTo!==undefined ? TabTo : '',
            lieu: TabLieu!==undefined ? TabLieu : '',
            fx: TabFx!==undefined ? TabFx : '',
            idCollab: TabCollab!==undefined ? TabCollab : '',
            vues:TabVues!==undefined ? TabVues : '',
            index: '0'
        };
        if(TabFrom===undefined){
            delete myParams.from;
        }
        if(TabTo===undefined){
            delete myParams.to;
        }
        if(TabLieu===undefined){
            delete myParams.lieu;
        }
        if(TabVues===undefined){
            delete myParams.vues;
        }
        delete myParams.idCollab
        delete myParams.fx
        const meP = {...myParams}
        setSearchParams(meP);
    }

    const handleChangeSearch = ()=>{
        if(refSearch.current){
            setSearch(refSearch.current.value);
        }
    }
    return (
        <div className={`element_smart_nav timed_select_url ${className}`} ref={refObj}>
            <LibelleCurrentSmart
                text={libelleCurrent}
                Icon={<FaUser/>}
                themeColor={"Secondary"}
                Actif={libelleCurrent!==''}
                placeholder={"Personne ou fonction"}
                Remove={Remove}
                ClickOnMe={()=>setOpen(o=>!o)}
            />
            <ListSmart open={open} wD={"260px"}>
                <div className={`content-smart`}>
                    <ZoneSearchSmart>
                        <input placeholder={"Recherche"} ref={refSearch} value={search} onChange={handleChangeSearch}/>
                        {search!=='' &&
                            <div className={`close-search`} onClick={()=>setSearch('')}><GrClose/></div>
                        }
                    </ZoneSearchSmart>

                    <ContentSmart>
                        <div className={`one_block`}>
                            <div className="titre">Fonctions</div>
                                <div className={"content_block"}>
                                    <ul>
                                        {FonctionsQuery.isLoading ? '...' : FonctionsQuery.isError ? 'error datas':

                                            myListFonctions.map((item, idx:number)=>(
                                                <li key={`one_choiceFx${idx}`} className={`one_choice_fx ${(TabFx &&TabFx?.split('#').indexOf(item.id.toString())!==-1) ? 'selected' : ''}`}>
                                                    <div className={`libelle`} onClick={()=>AddFonction(item, 1)}>{item.libelle}</div>
                                                    {(TabFx &&TabFx?.split('#').indexOf(item.id.toString())!==-1) ?
                                                        <div className={`unsel`}>
                                                            <div className={`remove_item`} onClick={()=>AddFonction(item, 0)}><GrClose/></div>
                                                        </div>:
                                                        <div className={`selPlus`} onClick={()=>AddFonction(item, 0)}>
                                                            <div className={`add_item`}>
                                                                <BsPlus/>
                                                            </div>
                                                        </div>
                                                    }
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                        </div>
                        <div className={`one_block`}>
                            <div className="titre">Collaborateurs</div>
                            <div className={"content_block"}>
                                {FonctionsQuery.isLoading ? '...' : FonctionsQuery.isError ? 'error datas':
                                    myListUser.map((item, idx:number)=>(
                                        <div className={`one_block_fonction`} key={`oneBFx${idx}`}>
                                            <div className={`titre_ss`}>
                                                <span>{item.libelle}</span>
                                            </div>
                                            <div className={`content_block_fonction`}>
                                                <ul>
                                                {item.list.map((itemU)=>(
                                                    <li key={`one_choice_user${itemU.id}`} className={`one_choice_user  ${(TabCollab && TabCollab.split('#').indexOf(itemU.id.toString())!==-1) ? 'selected' : ''}`}>
                                                        <div className={`libelle`} onClick={()=>AddUser(itemU, 1)}>{itemU.prenom} {itemU.nom}</div>
                                                        {(TabCollab &&TabCollab?.split('#').indexOf(itemU.id.toString())!==-1) ?
                                                            <div className={`unsel`}>
                                                                <div className={`remove_item`} onClick={()=>AddUser(itemU, 0)}><GrClose/></div>
                                                            </div>:
                                                            <div className={`selPlus`}>
                                                                <div className={`selPlus`} >
                                                                    <div className={`add_item`} onClick={()=>AddUser(itemU, 0)}>
                                                                        <BsPlus/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }

                                                    </li>
                                                ))}
                                                </ul>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </ContentSmart>
                </div>
            </ListSmart>
        </div>
    )
}

export const TimedSelectPersonneScope = styled(TimedSelectPersonneScopeCtrl)`
  position: relative;
  .titre_ss{
    font-weight: bold;
    text-decoration: underline;
    margin-bottom: 5px;
    padding: 0.25rem 0.5rem;
  }
  .one_block_fonction{
    margin-bottom: 10px;
  }
  .one_block{
    font-size: 14px;
    margin-bottom: 10px;
    .titre{
      font-weight: bold;
      font-size: 15px;
      margin-bottom: 5px;
      border-bottom: 1px solid ${props => props.theme.SecondaryExtraLight};
    }
    .content_block{
      ul{
        margin: 0;
        padding: 0;
        li{
          
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 5px;
          margin-bottom: 2px;
          .libelle{
            flex-grow: 1;
            border-radius: 12px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 0.25rem 0.5rem 0.25rem  0.75rem;
          }
          .unsel{
            padding-right: 3px;
            &:hover{
              cursor: pointer;
            }
            
          }
          .selPlus{
            opacity: 0;
            .add_item{
              padding-left: 8px;  
            }
            &:hover{
              cursor: pointer;
            }
          }
          .add_item{
            margin: auto;
            transition: padding-left 200ms;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 22px;
            height: 22px;
            font-size: 22px;
            
            svg{
              margin: auto;
              path{
                color: ${props=>props.theme.Secondary};
              }
            }
            &:hover{
              background: ${props=>props.theme.SecondaryDark};
              color: ${props=>props.theme.SecondaryExtraLight};
              svg path{
                color:${props=>props.theme.SecondaryExtraLight};
              }
            }
          }
          .remove_item{
            border-radius: 50%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 22px;
            height: 22px;
            font-size: 14px;
            svg{
              margin: auto;
              
            }
            &:hover{
              background: ${props=>props.theme.SecondaryDark};
              color: ${props=>props.theme.SecondaryExtraLight};
              svg path{
                stroke:${props=>props.theme.SecondaryExtraLight};
              }
            }
          }
          &:not(.selected):hover{
            .libelle{
              background: ${props => props.theme.SecondaryMegaLight};
              transition: background-color 300ms;
              font-weight: bold;
            }
            .selPlus{
              opacity: 1;
              .add_item{
                padding-left: 0px;
                transition: padding-left 200ms;
              }
              
            }
            cursor: pointer;
          }
          &.selected{
            font-weight: bold;
            background: ${props=>props.theme.SecondaryExtraLight};
            color: ${props=>props.theme.SecondaryExtraDark};
            border-radius: 12px;
          }
        }
      }
    }
  }        
`
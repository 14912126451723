import {useMutation, useQueryClient} from "react-query";
import {EndContrat, ModifyContrat, NewContrat, updateJoursTravContrat} from "./contrat.services";
import {toast} from "react-toastify";



export const useNewContrat = ()=>{
    const queryClient = useQueryClient();
    return useMutation((datas:ContratFormData)=>{
        return NewContrat(datas)
    },
        {
            onSuccess:()=>{
                queryClient.invalidateQueries(["contrats"]);
                queryClient.invalidateQueries(["week"]);
                queryClient.invalidateQueries(["user"]);
                queryClient.invalidateQueries(["users_ids"]);
                toast.success("contrat ajouté !", {});
            },
            onError: (error: any) => {
                toast.error("Erreur : " + error.message, {});
            },
        }
    )
}

export const useEndContrat = ()=>{
    const queryClient = useQueryClient();
    return useMutation((datas:ContratEndFormData)=>{
            return EndContrat(datas)
        },
        {
            onSuccess:()=>{
                queryClient.invalidateQueries(["contrats"]);
                queryClient.invalidateQueries(["user"]);
                toast.success("contrat terminé !", {});
            },
            onError: (error: any) => {
                toast.error("Erreur : " + error.message, {});
            },
        }
    )
}

export const useModifyContrat = ()=>{
    const queryClient = useQueryClient();
    return useMutation((datas:{id:number, data:ContratEditFD})=>{
        return ModifyContrat(datas.id, datas.data);
    }, {
        onSuccess:()=>{
            queryClient.invalidateQueries(["contrats"]);
            queryClient.invalidateQueries(["user"]);
            toast.success("contrat modifié !", {});
        },
        onError:()=>{
            toast.error("Une erreur est survenue.")
        }
    }
    )
}

export const useUpdateJoursTravContrat = ()=>{
    const queryClient = useQueryClient();
    return useMutation((datas: {idContrat:number, jt:JoursTravail })=>{
            return updateJoursTravContrat(datas.idContrat, datas.jt)
        },
        {
            onSuccess:()=>{
                queryClient.invalidateQueries(["contrats"]);
                queryClient.invalidateQueries(["user"]);
                toast.success("contrat modifié !", {});
            },
            onError: (error: any) => {
                toast.error("Erreur : " + error.message, {});
            },
        }

    )
}
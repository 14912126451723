import React, {useMemo} from "react";
import styled from "styled-components";
import WidgetList from "../Components/WidgetList";
import TimedChipsUser from "../../../components/atoms/TimedChips/TimedChipsUser";
import {useGetAllUser} from "../../../features/user/user.hooks";
import {useNavigate} from "react-router-dom";
import {TimedEmptyFlex} from "../../../components/molecules/TimedWidgetEmpty/TimedEmptyFlex";
import IllCtOk from "../../../assets/images/ContratsOK.png";
import {TextStd} from "../../../components/molecules/TimedWidgetEmpty/TextStd";
import {useCentreConnectedData} from "../../../services/hooks";


interface WidgetNoContractCtrlProps{
    className?:string;
    supervisor?:boolean;
}


const WidgetNoContractCtrl = ({className, supervisor}:WidgetNoContractCtrlProps)=>{
    const centre = useCentreConnectedData();
    const UserQuery = useGetAllUser(supervisor ? centre : null);
    const navigate = useNavigate();
    const GoFiche = (User:User)=>{
        navigate(`/timed_application/fiche_personnel?idCollab=${User.id}`)
    }
    const listUser = useMemo(()=>{
        let list:User[] = [];
        if(UserQuery.data){
            list = UserQuery.data.filter(u=>u.Fonction.id !==1 && u.contrats.length === 0);
        }
        return list;
    }, [UserQuery.data])
    return (
        <WidgetList
            title={`Collaborateurs sans contrats`}
            isPending={UserQuery.isLoading}
            width={"100%"}
            height={"100%"}
            isFullH={true}
            isAlert={listUser.length>0}
            isNoAlert={listUser.length===0}
        >
            <div className={`NoContract ${className}`}>
                {!UserQuery.isLoading && listUser.length === 0 ?
                    <TimedEmptyFlex
                        Ill={IllCtOk}
                        HeiImg={"115px"}
                    >
                        <TextStd
                            firstLine={"Un contrat est renseigné"}
                            sdLine={"Pour tous les collaborateurs"}
                        />
                    </TimedEmptyFlex>:
                    <table className={`last-entry ${className}`}>
                        <tbody>
                        {listUser.map((item:User, idx:number)=>(
                            <tr key={`oneLC${idx}`}>
                                <td>
                                    <TimedChipsUser
                                        Hei={"24px"}
                                        User={item}
                                        Action={GoFiche}
                                        backHover={"primary"}
                                        Fx={true}
                                        Border={false}
                                    />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                }
            </div>
        </WidgetList>
    )
}

const WidgetNoContract = styled(WidgetNoContractCtrl)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;

  .wrap-ill {
    z-index: 2;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .one-part {
    width: 50%;
    height: 100%;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .legende {
      text-align: center;
      padding: 0.25rem;
      font-size: 13px;
    }

    .wrap-bar {
      width: 100%;
      flex-grow: 1;
      position: relative;

      .barreNb {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        &.is-homme {
          background: #d7d7f8;
        }

        &.is-femme {
          background: #fae2e5;
        }
      }
    }
  }
`

export default  WidgetNoContract

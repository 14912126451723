import React, {useEffect, useMemo, useRef, useState} from "react";
import styled from "styled-components";
import TimedRoundAvatar from "../TimedRoundAvatar/TimedRoundAvatar";
import {BiChevronDown} from "react-icons/bi";
import { GrFormClose } from "react-icons/gr";
import { ListChoice } from "../TimedAutoCompletion/ListChoice";

interface TimedSelectUserACCtrlProps{
    className?:string;
    ChoicesUser:User[];
    setFieldValue:Function;
    Current:string;
    label:string;
    placeHolder:string;
    id:string;
    isReq:boolean;
    posLeft?:string;
    posTop?:string;
    MaxList?:string;
    isError?:boolean;
    clearError:Function;
}

const TimedSelectUserACCtrl = ({placeHolder,label,className, ChoicesUser, setFieldValue, Current, id, isReq, ...rest}:TimedSelectUserACCtrlProps)=>{
    const [open, setOpen] = useState(false);
    const [userCurrent, setUserCurrent] = useState<User|null>(null);
    const refObj = useRef<HTMLDivElement>(null);
    const [search, setSearch] = useState<string>('')
    const refSearch = useRef<HTMLInputElement>(null)
    const [onHight, setOnHight] = useState(false);
    useEffect(()=>{
        setOnHight(open);
    }, [open])
    const handleSearchChange = ()=>{
        if(refSearch.current){
            setSearch(refSearch.current.value);
        }
    }
    useEffect(() => {
        function handleClickOutside(event:any) {
            if (refObj.current && !refObj.current.contains(event.target)) {
                setOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refObj]);
    useEffect(()=>{
        if(Current!=='' && ChoicesUser){
            const myChoice = ChoicesUser.find(u=>u.fullName === Current);
            if(myChoice){
                setUserCurrent(myChoice);
            }
        } else {
            setUserCurrent(null);
        }
    }, [Current, ChoicesUser])
    const handleClickOnIt =()=>{
        if(!open){
            setOpen(true);
        }
    }
    const handleClickChoice = (userChoice:User|null)=>{
        if(!!userChoice) {
            rest.clearError(id);
            setFieldValue(id, userChoice.fullName, {shouldDirty: true})
        } else {
            setFieldValue(id, '', {shouldDirty: true})
        }
        setOpen(false);
    }
    const UsersFiltered = useMemo(()=>{
        if(search===''){
            return ChoicesUser;
        } else {
            const regex = new RegExp(""+search+"", 'gi');
            return ChoicesUser.filter(u=>u.fullName.match(regex));
        }
    }, [ChoicesUser, search])
    return (
        <div className={`ChoiceUser ${className}`} ref={refObj}>
            <div className={`wrap-input ${rest.isError ? "on-error" : onHight ? "is-hight" : ""}`} onClick={handleClickOnIt}>
                <label>{label} {isReq && '*'}</label>
                <div className={`front-input`}>
                    {userCurrent ?
                        <div className={`item-choice`}>
                            <div className={`avat-place`}>
                                <TimedRoundAvatar BoxShadow={"1px 1px 3px rgba(0,0,0,.7)"} Border={true} src={userCurrent.avatarUrl!==undefined ? userCurrent.avatarUrl : '' } size={"other"} sizeP={"24px"}/>
                            </div>
                            <div className="name">{userCurrent.fullName}</div>
                            <div className={"trash"} onClick={()=>handleClickChoice(null)}>
                                <GrFormClose/>
                            </div>
                        </div>:
                        <div className={`placeHolder`}>
                            <input className={`input-search`} value={search} onChange={handleSearchChange} ref={refSearch} placeholder={placeHolder}/>
                        </div>
                    }
                    <div className={`iconPlace`}><BiChevronDown/></div>
                </div>
            </div>
            {open &&
                <ListChoice MaxList={rest.MaxList} posLeft={rest.posLeft} posTop={rest.posLeft}>
                    <div className={`in-list-user`}>
                        {UsersFiltered.map((u:User, idx:number)=>(
                            <div className={`item-choice isToChoice`} key={`oneChoiceU${idx}`} onClick={()=>handleClickChoice(u)} >
                                <TimedRoundAvatar BoxShadow={"1px 1px 3px rgba(0,0,0,.7)"} Border={true} src={u.avatarUrl!==undefined ? u.avatarUrl : '' } size={"other"} sizeP={"24px"}/>
                                <div className="name">{u.fullName}</div>
                            </div>
                        ))}
                    </div>
                </ListChoice>
            }
        </div>
    )
}

const TimedSelectUserAC = styled(TimedSelectUserACCtrl)`
  background: white;
  padding: 0.5rem;
  position: relative;
  .placeHolder{
    color: #ccc;
    padding: 0.35rem;
  }
  &:hover{
    cursor: pointer;
  }
  
  .input-search{
    width: 100%;
    height: 100%;
    border: none;
    padding: 0 0.25rem;
  }
  .item-choice{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
    padding: 0.25rem 0.5rem;
    .trash{
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: ${props => props.theme.NeutreSuperLight};
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &:hover{
        cursor: pointer;
      }
      svg{
        path{
          stroke:white;
          fill:white;
        }
        margin: auto;
      }
    }
    .name{
      font-weight: bold;
      font-size: 14px;
      flex-grow: 1;
    }
    &.isToChoice{
      &:hover{
        cursor: pointer;
        background: ${props=>props.theme.PrimaryExtraLight};
      }
    }
  }
  .wrap-input{
    border: solid #ccc 1px;
    position: relative;
    border-radius: 4px;
    &.on-error{
      border: solid ${props=>props.theme.Complementary} 1px;
    }
    &.is-hight{
      border: solid ${props=>props.theme.Primary} 1px;
    }
    &:hover{
      border: solid ${props=>props.theme.PrimaryExtraDark} 1px;
    }
    &.onError{
      border-color: red;
    }
    label{
      font-size: 11px;
      position: absolute;
      padding: 0 0.25rem;
      left: 5px;
      top: 0;
      transform: translateY(-50%);
      background: white;
      color:rgba(0,0,0,0.6)
    }
    .front-input{
      width: 100%;
      padding: 0.2rem;
      display: flex;
      justify-content: start;
      align-items: center;
      .item-choice, .placeHolder{
        flex-grow: 1;
      }
      .iconPlace{
        padding: 0.25rem;
      }
    }

  }
`

export default TimedSelectUserAC;
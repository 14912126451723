import styled from "styled-components";
import {useGetRoleNiveaux} from "../../../features/roleNiveau/roleNiveau.hooks";
import {useGetUser} from "../../../features/user/user.hooks";
import {useGetModules} from "../../../features/module/module.hooks";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useMemo, useRef, useState} from "react";
import {LibelleCurrentSmart} from "./LibelleCurrentSmart";
import {SiHackthebox} from "react-icons/si";
import {ListSmart} from "./ListSmart";
import {GrClose} from "react-icons/gr";
import {ContentSmart, ZoneSearchSmart} from "./styledElt";
import {useGetParams} from "../../../services/hooks";

interface TimedSelectUrlCtrlProps{
    className?:string;
}

interface OneUrl{
    libelle:string;
    link:string;
    current:boolean;
}

interface listUrl{
    sectionName:string;
    list:OneUrl[];
}

const TimedSelectUrlCtrl = ({className}:TimedSelectUrlCtrlProps)=>{
    const {TabFrom, TabTo, TabFx, TabLieu, TabVues, TabCollab} = useGetParams()
    const refObj = useRef<HTMLDivElement>(null);
    const RoleNiveauQuery = useGetRoleNiveaux();
    const UserQuery = useGetUser();
    const ModulesQuery = useGetModules();
    const location = useLocation();
    const [locationCurr, setLocationCurr] = useState('Dashboard')
    const [open, setOpen] = useState(false)
    const refSearch = useRef<HTMLInputElement>(null)
    const [search, setSearch] = useState('');
    const navigate = useNavigate();
    const getLink=(link:string)=>{
        return `${link}${location.search}`
    }
    const listModules = useMemo(()=>{
        const list:listUrl[] = [];
        if(RoleNiveauQuery.data && UserQuery.data && ModulesQuery.data){
            const regex = new RegExp(""+search+"", 'gi');
            const Sections:string[] = ModulesQuery.data.map(m=>m.sectionName);
            const SectionsSSD = Sections.reduce((acc:string[], item:string)=>acc.indexOf(item)===-1 ? [...acc, item] : acc, [])
            const rolesUser = UserQuery.data?.roles?.filter(r=>r!=='ROLE_ADMIN');
            let niveauUser = 0;
            RoleNiveauQuery.data.map(item=>{
                if(rolesUser?.indexOf(item.libelle)!==-1 && item.niveau >niveauUser){
                    niveauUser = item.niveau;
                }
            })
            SectionsSSD.map(s=>{
                const ModulesConcerned = ModulesQuery.data.filter(m=>m.sectionName === s && m.niveau <= niveauUser && (search === '' || m.libelle.match(regex))).map(m=>{
                    return {libelle:m.libelle,link:m.url, current:m.libelle === locationCurr}
                });
                if(ModulesConcerned.length>0) {
                    list.push({
                        sectionName: s,
                        list: ModulesConcerned
                    })
                }

                return s;
            })
        }
        return list;
    }, [RoleNiveauQuery.data, UserQuery.data, ModulesQuery.data, locationCurr, search])
    useEffect(() => {
        function handleClickOutside(event:any) {
            if (refObj.current && !refObj.current.contains(event.target)) {
                setOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refObj]);
    const Remove = ()=>{
        setLocationCurr("Dashboard");
        navigate("../dashboard"+location.search);
    }
    useEffect(()=>{
        if(ModulesQuery.data) {
            const Tablocation = location.pathname.split('/');
            const nameThis = Tablocation[2]
            const ModulePresume = ModulesQuery.data.find(m=>m.url === nameThis);
            if(ModulePresume){
                setLocationCurr(ModulePresume.libelle)
            }
        }
    }, [location, ModulesQuery.data])
    const handleChangeSearch = ()=>{
        if(refSearch.current){
            setSearch(refSearch.current.value);
        }
    }
    return (
        <div className={`element_smart_nav timed_select_url ${className}`} ref={refObj}>
            <LibelleCurrentSmart
                text={locationCurr}
                Icon={<SiHackthebox/>}
                themeColor={"Primary"}
                Actif={true}
                placeholder={"Module"}
                Remove={Remove}
                ClickOnMe={()=>setOpen(o=>!o)}
            />
            <ListSmart open={open}>
                <div className={`content-smart`}>
                    <ZoneSearchSmart>
                        <input placeholder={"Recherche"} ref={refSearch} value={search} onChange={handleChangeSearch}/>
                        {search!=='' &&
                            <div className={`close-search`} onClick={()=>setSearch('')}><GrClose/></div>
                        }
                    </ZoneSearchSmart>
                    <ContentSmart>
                        {listModules.map((item, idx:number)=>(
                            <div className={`one_block`} key={`Ob_${idx}`}>
                                <div className="titre">{item.sectionName}</div>
                                <div className={"content_block"}>
                                    <ul>
                                        {item.list.map((itemL, idxL:number)=>(
                                            <li key={`oneUrl${idxL}`}><a href={getLink(itemL.link)}>{itemL.libelle}</a></li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </ContentSmart>
                </div>
            </ListSmart>
        </div>
    )
}

export const TimedSelectUrl = styled(TimedSelectUrlCtrl)`
    border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  position: relative;
  a{
    text-decoration: none;
    color: black;
  }
  .one_block{
    font-size: 14px;
    margin-bottom: 10px;
    .titre{
      font-weight: bold;
      font-size: 15px;
      margin-bottom: 10px;
      border-bottom: 1px solid ${props => props.theme.ComplementaryExtraLight};
    }
    .content_block{
      ul{
        margin: 0;
        padding: 0;
        li{
          padding: 0.25rem 0.5rem 0.25rem  0.75rem;
          border-radius: 12px;
          display: block;
          &:hover{
            font-weight: bold;
            cursor: pointer;
            background: ${props => props.theme.PrimaryMegaLight};
            transition: background-color 300ms;
          }
        }
      }
    }
  }
`
import styled from "styled-components";
import {ConvertBase5} from "../functions/TimesFunctions";
import TimedRoundAvatar from "../atoms/TimedRoundAvatar/TimedRoundAvatar";
import {TiWarning} from "react-icons/ti";
import React from "react";

interface TimedInfosSurvolCtrlProps{
    className?:string;
    PlageCurrent:Plage|null;
    Plages:Plage[];
    visible:boolean;
    posInfos:{x:number, y:number}

}

const TimedInfosSurvolCtrl = React.forwardRef(({className, PlageCurrent, Plages, visible, posInfos}:TimedInfosSurvolCtrlProps, ref:React.ForwardedRef<HTMLDivElement>)=>{
    console.log(PlageCurrent);
    const GetTeam = ()=>{
        if(!PlageCurrent?.equipe){
            return [];
        } else {
            const numEq = PlageCurrent.numEquipe||0;
            const PlagesSameNum = Plages.filter(p=>p.numEquipe === numEq && p.id !==PlageCurrent.id);
            const PlagesTeam:Plage[] = [];
            PlagesSameNum.map(p=>{
                if(PlageCurrent.equipe && p.equipe) {
                    const ThisEquipe = p.equipe ? p.equipe : []
                    const Intersection = PlageCurrent?.equipe.filter(value => ThisEquipe.includes(value));
                    if(Intersection?.length>0){
                        PlagesTeam.push(p);
                    }
                }
                return p;
            })
            return PlagesTeam.sort((a,b)=>a.User.Fonction.id > b.User.Fonction.id ? 1 : 0);
        }
    }
    const getConflict = (plage:Plage)=>{
        const PlageDay = Plages.filter(p=>(new Date(p.plageAt)).toLocaleDateString() === (new Date(plage.plageAt)).toLocaleDateString())
        return PlageDay.filter(pl => {
            return !(pl.Statut.id === 5 || pl.plageAt !== plage.plageAt || pl.User.id !== plage.User.id || pl.heureFin <= plage.heureDebut || pl.heureDebut >= plage.heureFin || pl.id === plage.id);
        });
    }
    if(!PlageCurrent || !visible){
        return <div ref={ref} className={`survol_infos ${visible ? 'visible' : ''}`} style={{left:`${posInfos.x}px`, top:`${posInfos.y}px`}}/>
    } else {
    return (
        <div ref={ref} className={`survol_infos ${visible ? 'visible' : ''}  ${className}`} style={{left:`${posInfos.x}px`, top:`${posInfos.y}px`}}>
            <div className={`section-infos`}>
                <div className={`title-infos`}>Plage ({PlageCurrent.Statut.libelle})</div>
                <div className={`content-section-infos`}>
                    <div className={`line-infos`}>{(new Date(PlageCurrent.plageAt)).toLocaleDateString()}  <strong>de {ConvertBase5(PlageCurrent.heureDebut)} à {ConvertBase5(PlageCurrent.heureFin)}</strong></div>
                    <div className={`line-infos`}>{PlageCurrent.Lieu.libelle} / {PlageCurrent.Acte.libelle} {PlageCurrent.Box ? ` / ${PlageCurrent.Box.libelle}` : ''}</div>
                </div>
            </div>
            <div className={`section-infos`}>
                <div className={`title-infos`}>Collaborateur</div>
                <div className={`content-section-infos`}>
                    <div className={`wrap-infos-collab`}>
                        <div className={`av-place`}>
                            <TimedRoundAvatar BoxShadow={"1px 1px 3px rgba(0,0,0,.7)"} Border={true} src={PlageCurrent.User.avatarUrl!==undefined ? PlageCurrent.User.avatarUrl : '' } size={"other"} sizeP={"28px"}/>
                        </div>
                        <div className={`name-collab`}>({PlageCurrent.User.initials}) <strong>{PlageCurrent.User.prenom} {PlageCurrent.User.nom}</strong> - {PlageCurrent.User.Fonction.libelle} </div>
                    </div>
                </div>
            </div>
            {GetTeam().length>0 &&
                <div className={`section-infos`}>
                    <div className={`title-infos`}>Equipe</div>
                    <div className={`content-section-infos`}>
                        <ul className={`list_equipe`}>
                            {GetTeam().map((item)=>(
                                <li>
                                    <div className={`avat_place_team`}>
                                        <TimedRoundAvatar  size={"other"} sizeP={"28px"} src={item.User.avatarUrl||""} isSquare={true}/>
                                    </div>
                                    <div className={`infos_place_team`}>
                                        <div className={`user_infos`}>
                                            {item.User.prenom} {item.User.nom} - {item.User.Fonction.libelle}
                                        </div>
                                        <div className={`acte_place`}>
                                            {item.Acte.libelle}
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            }
            {getConflict(PlageCurrent).length>0 &&
                <div className={`section-infos`}>
                    <div className={`title-infos`} style={{color:"red"}}><TiWarning/><span style={{marginLeft:"5px"}}>Plages en Conflits</span></div>
                    <div className={`content-section-infos`}>
                        <ul>
                            {getConflict(PlageCurrent).map((p:Plage)=>(
                                <li className={`one_conflit`} key={`conflict_${p.id}`}>
                                    {p.Lieu.libelle} - {p.Acte.libelle} - {ConvertBase5(p.heureDebut)} | {ConvertBase5(p.heureFin)}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            }
        </div>
    )
    }
})

export const TimedInfosSurvol = styled(TimedInfosSurvolCtrl)`
  display: none;
  width: 250px;
  padding: 0.5rem;
  font-size: 14px;
  height: auto;
  background: white;
  border-radius: 6px;
  position: fixed;
  z-index: 4;
  opacity: 0;
  transition: opacity 300ms;
  box-shadow: 1px 1px 2px 2px rgba(0,0,0,.1);
  .list_equipe{
    li{
      list-style: none;
      display: flex;
      justify-content: flex-start;
      gap: 3px;
      align-items: center;
      .infos_place_team{
        display: flex;
        flex-direction: column;
        justify-content: center;
        .user_infos{
          font-weight: bold;
        }
        .acte_place{
          font-style: italic;
        }
      }
    }
  }
  &.visible{
    display: block;
    opacity: 1;
    transition: opacity 300ms;
  }
  .section-infos{
    width: 100%;
    .title-infos{
      font-weight: bold;
      color: ${props=>props.theme.TertiaryExtraDark};

    }
    .content-section-infos{
      margin: 10px auto 10px 15px;
      .line-infos{
        margin-bottom: 5px;
      }
      .wrap-infos-collab{
        .av-place{
          width: 28px;
        }
        padding: 0.25rem 0;
        display: flex;
        gap: 10px;
        justify-content: flex-start;
        align-items: center;
      }
    }

  }
`
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";

export const useGetParamsTimed=()=>{
    const [searchParams] = useSearchParams();
    const [From, setFrom] = useState<Date|undefined>(undefined);
    const [To, setTo] = useState<Date|undefined>(undefined);
    const [TabLieu, setTabLieu] = useState<string[]|undefined>(undefined);
    const [TabFx, setTabFx] = useState<string[]|undefined>(undefined);
    const [TabCollab, setTabCollab] = useState<string[]|undefined>(undefined);
    const [TabVues, setTabVues] = useState<string[]|undefined>(undefined);
    const [onSetting, setOnSetting] = useState<boolean>(false);
    useEffect(()=>{
        setOnSetting(true);
        const TabParams = [...Array.from(searchParams)];
        const mTabFrom = TabParams.find(tab=>tab[0]==='from');
        const mTabTo = TabParams.find(tab=>tab[0]==='to');
        const mTabLieu = TabParams.find(tab=>tab[0]==='lieu');
        const mTabFx = TabParams.find(tab=>tab[0]==='fx');
        const mTabCollab = TabParams.find(tab=>tab[0]==='idCollab');
        const mTabVues = TabParams.find(tab=>tab[0]==='vues');
        if(mTabFrom!==undefined){
            const TabDaty = mTabFrom[1].split('/');
            const TabStrDaty = `${TabDaty[2]}-${TabDaty[1]}-${TabDaty[0]}`;
            setFrom(new Date(TabStrDaty));
        } else {
            setFrom(undefined)
        }
        if(mTabTo!==undefined){
            //const TabDaty = mTabTo[1].split('/');
            //const TabStrDaty = `${TabDaty[2]}-${TabDaty[1]}-${TabDaty[0]}`;
            const TabDaty = mTabTo[1].split('/');
            const TabStrDaty = `${TabDaty[2]}-${TabDaty[1]}-${TabDaty[0]}`;
            setTo(new Date(TabStrDaty));
        } else {
            setTo(undefined)
        }
        if(mTabLieu!==undefined){
            setTabLieu(mTabLieu[1].split("#"));
        } else {
            setTabLieu(undefined)
        }
        if(mTabVues!==undefined){
            setTabVues(mTabVues[1].split("#"));
        } else {
            setTabVues(undefined)
        }
        if(mTabFx!==undefined){
            setTabFx(mTabFx[1].split("#"));
        } else {
            setTabFx(undefined)
        }
        if(mTabCollab!==undefined){
            setTabCollab(mTabCollab[1].split("#"));
        } else {
            setTabCollab(undefined);
        }
        setOnSetting(false);
    }, [searchParams])

    return {From, To, TabLieu, TabFx, TabCollab, TabVues, onSetting};
}
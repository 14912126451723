import React, {useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import {useGetCreneauxSuggestions} from "../../../../features/creneau/creneau.hooks";
import TimedSkeleton from "../../../../components/atoms/TimedSkeleton/TimedSkeleton";
import {BsChevronLeft, BsChevronRight} from "react-icons/bs";
import FormNewCreneau from "./FormNewCreneau";
import {useCentreConnectedData} from "../../../../services/hooks";

interface SuggestionsCreneauxCtrlProps{
    className?:string;
    supervisor?:boolean;
}

const SuggestionsCreneauxCtrl = ({className, supervisor}:SuggestionsCreneauxCtrlProps)=>{
    const centre = useCentreConnectedData();
    const SuggestionsQuery = useGetCreneauxSuggestions(supervisor ? centre : null);
    const [index, setIndex] = useState(1);
    const [max, setMax] = useState(0);
    useEffect(()=>{
        if(SuggestionsQuery.data){
            setMax(SuggestionsQuery.data.length);
        }
    }, [SuggestionsQuery.data])
    const myListCreneau = useMemo(()=>{
        let list:Creneau[] = [];
        if(SuggestionsQuery.data){
            SuggestionsQuery.data.map(s=>{
                const myCren:Creneau = {
                    id:0,
                    UserReplaced:s.medecin,
                    UserAffected:null,
                    Lieu:s.Lieu,
                    creneauAt:s.creneauAt,
                    heureDebut:s.heureDebut,
                    heureFin:s.heureFin,
                    flexiDebut:0,
                    flexiFin:0,
                    aide:true,
                    niveau:1,
                    UserCreate:s.userCreate,
                }
                list.push(myCren);
                return s;
            })
        }
        return list;
    }, [SuggestionsQuery.data])
    const MoveCursor = (coef:number)=>{
        let newIndex = index+coef;
        if(newIndex<1){
            newIndex = max;
        }
        if(newIndex>max){
            newIndex = 1;
        }
        setIndex(newIndex);
    }
    return (
        <div className={`Suggestions ${className}`}>
            {SuggestionsQuery.isLoading ?
                <div className={"pad-ske"}><TimedSkeleton width={"100%"} type={"rect"} nbOccurence={4}/></div> :
                <div className={`suggestions`}>
                    <div className={`cursor-mover`}>
                        <div className={`move isRewind`} onClick={()=>MoveCursor(-1)}><BsChevronLeft/></div>
                        <div className={`currentCursor`}>{index}/{max}</div>
                        <div className={`move isForward`} onClick={()=>MoveCursor(1)}><BsChevronRight/></div>
                    </div>
                    <div className={`wrapper-suggestions`}>
                        <div className={`wrappy-suggestion`} style={{left:`${(0-(index-1))*100}%`, width:`${max*100}%`}}>
                            {myListCreneau.map((c:Creneau, idx:number)=>(
                                <div className={`wrap-one-sugg`} key={`WOS${idx}`} style={{width:`${100/max}%`}}>
                                    <FormNewCreneau Creneau={c} supervisor={supervisor}/>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

const SuggestionsCreneaux = styled(SuggestionsCreneauxCtrl)`
    padding: 0.5rem;
  background: white;
  .wrapper-suggestions{
    width: 100%;
    height: 148px;
    position: relative;
    overflow: hidden;
    .wrappy-suggestion{
      position: absolute;
      top: 0;
      height: 100%;
      display: flex;
      justify-content: start;
      transition: left 0.3s;
      .wrap-one-sugg{
        height: 100%;
      }
    }
  }
  .suggestions{
    width: 100%;
  }
  .cursor-mover{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 0.5rem;
    .move{
      &:hover{
        cursor: pointer;
      }
    }
  }
`

export default SuggestionsCreneaux;
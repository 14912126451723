import React from "react";
import styled from "styled-components";
import {UseQueryResult} from "react-query";
import TimedSmartNav from "../../organisms/TimedSmartNav/TimedSmartNav";
import {myBoxShadow} from "../../../assets/styles/elements";
import TimedNotificationCenter from "../TimedNotificationsCenter/TimedNotificationCenter";
import {useGetCentreActuel} from "../../../features/centre/centre.hooks";
import {TimedSelectUrl} from "../../organisms/TimedSmartNav/TimedSelectUrl";
import {TimedSelectPersonne} from "../../organisms/TimedSmartNav/TimedSelectPersonne";
import {TimedSelectDates} from "../../organisms/TimedSmartNav/TimedSelectDates";
import {TimedSelectLieux} from "../../organisms/TimedSmartNav/TimedSelectLieux";
import {TimedSelectPersonneScope} from "../../organisms/TimedSmartNav/TimedSelectPersonneScope";
import {TimedSelectLieuxScope} from "../../organisms/TimedSmartNav/TimedSelectLieuxScope";

interface TimedHeaderCtrlProps{
    className?:string;
    UserQuery:UseQueryResult<UserSimple, Error>;
    OpenNotif?:boolean;
    SwitchNotif?:Function;
    FonctionsQuery:UseQueryResult<Fonction[], Error>,
    UsersQuery:UseQueryResult<User[], Error>,
    LieuxQuery:UseQueryResult<Lieu[], Error>
    ModulesQuery:UseQueryResult<Module[], Error>
    VuesQuery:UseQueryResult<VuePlanning[], Error>
}

const TimedHeaderCtrl = ({className, UserQuery, SwitchNotif, OpenNotif, ModulesQuery, VuesQuery, UsersQuery, FonctionsQuery, LieuxQuery}:TimedHeaderCtrlProps)=>{
    const CentreQuery = useGetCentreActuel();
    return (
        <header className={`TimedH ${className}`}>
            <div className={"space"}>
                <div className={"round"}>
                    {CentreQuery.data &&
                        <img src={`${process.env.REACT_APP_API_URL_ROOT}/CentreLogos/${CentreQuery.data.logo}`}/>
                    }
                </div>
            </div>
            <div className={`wrapSmart`}>
                {UserQuery && UserQuery.data &&
                    // <TimedSmartNav
                    //     FonctionsQuery={FonctionsQuery}
                    //     UserConnected={UserQuery.data}
                    //     UsersQuery ={UsersQuery}
                    //     LieuxQuery ={LieuxQuery}
                    //     ModulesQuery={ModulesQuery}
                    //     VuesQuery={VuesQuery}
                    // />
                    <>
                    <TimedSelectUrl/>
                    <TimedSelectPersonneScope/>
                    <TimedSelectDates/>
                    <TimedSelectLieuxScope/>
                    </>
                }
            </div>
            <div className={"space"}>
                <div className={"rest"}>
                    <div className={`round`}>
                        {UserQuery.isLoading ? '' : <img src={`${process.env.REACT_APP_API_URL_ROOT}${UserQuery.data?.avatarUrl}`} alt={"user_avatar"}/>}
                    </div>
                    {(!UserQuery.isLoading && UserQuery.data) &&
                        <TimedNotificationCenter User={UserQuery.data} OpenNotif={OpenNotif} SwitchOpen={SwitchNotif}/>
                    }
                </div>
            </div>
        </header>
    )
}

const TimedHeader = styled(TimedHeaderCtrl)`
  width: 100%;
  padding: 1.3rem;
  display: flex;
  justify-content: start;
  height: 90px;
  align-items: center;
  .wrapSmart{
    border-radius: 25px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 1px 1px 3px rgba(0,0,0,.2);
  }
  .space{
    width: 112px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
  }
  .round{
    width: 48px;
    flex-shrink: 0;
    height: 48px;
    background: white;
    border-radius: 50%;
    ${myBoxShadow};
    overflow: hidden;
    img{
      width: 100%;
      height: auto;
    }
  }
  .rest{
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    gap: 8px;
  }
  .wrapSmart{
    flex-grow: 1;
    width: 80%;
  }
`

export default TimedHeader;
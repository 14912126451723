import React from "react";
import styled, {useTheme} from "styled-components";
import {Oval} from "react-loader-spinner";

interface TimedSkeletonBigIconCtrlProps{
    className?:string;
    Icon:React.ReactNode;
    Text?:string;
    width?:string;
    height?:string;
}

const TimedSkeletonBigIconCtrl = ({className, Icon, Text}:TimedSkeletonBigIconCtrlProps)=>{
    const theme = useTheme();
    return (
        <div className={`TimedSkeletonBI ${className}`}>
            <div className={`ske-in`}>
                <div className={`spinner-place`}>
                    <Oval
                        height={120}
                        width={120}
                        color={theme.Primary}
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='Chargement'
                        secondaryColor={theme.PrimaryLight}
                        strokeWidth={2}
                        strokeWidthSecondary={2}

                    />
                </div>
                <div className={`Icon-place`}>
                    {Icon}
                </div>
                {Text &&
                    <div className={"text-place"}>{Text}</div>
                }
            </div>
        </div>
    )
}

const TimedSkeletonBigIcon = styled(TimedSkeletonBigIconCtrl)`
    width: ${props => props.width ? props.width : "300px"};
    height: ${props => props.height ? props.height : "300px"};
    position: relative;
    background: ${props=>props.theme.NeutreExtraLight};
  border-radius: 12px;
    .ske-in{
      position: absolute;
      left: 50%;
      top: 50%;
      width: 120px;
      height: 120px;
      transform: translate(-50%,-50%);
      .Icon-place{
        position: absolute;
        left: 50%;
        top: 54%;
        transform: translate(-50%,-50%);
        font-size: 32px;
        svg{
          color: ${props=>props.theme.Primary};
        }
      }
    }
`

export default TimedSkeletonBigIcon;
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {useGetModules} from "../../../features/module/module.hooks";
import {useLocation} from "react-router-dom";
import {UseQueryResult} from "react-query";
import TimedSideBar from "../TimedSideBar/TimedSideBar";
import TimedSubMenu from "../TimedSubMenu/TimedSubMenu";
import {useGetRoleNiveaux} from "../../../features/roleNiveau/roleNiveau.hooks";
import {useCentreConnectedData} from "../../../services/hooks";
import {useGetOneCentre} from "../../../features/centre/centre.hooks";

interface TimedFullSideBarCtrlProps{
    className?:string;
    UserQuery?:UseQueryResult<UserSimple, Error>;
    supervisor?:boolean;
}

const TimedFullSideBarCtrl = ({className, UserQuery, supervisor}:TimedFullSideBarCtrlProps)=>{
    const centre = useCentreConnectedData();
    const CentreQuery = useGetOneCentre(centre||-1);
    const RoleNiveauQuery = useGetRoleNiveaux();
    const {isLoading, isError, data:modules} = useGetModules();
    const [subMenu, setSubMenu] = useState<Module[]|null>(null)
    const [sectionName, setSectionName] = useState<string|null>(null);
    const location = useLocation();
    useEffect(()=>{
        const TabLoc = location.pathname.split('/');
        if(!isError && !isLoading && modules && UserQuery?.data && RoleNiveauQuery.data){
            const myModule = modules.find(m=>m.url===TabLoc[2]);
            if(myModule){
                if(myModule.sectionName!=='Main' && myModule.sectionName!=='Messagerie') {
                    const MyModules:Module[] = [];
                    //const niveauModule = myModule.niveau;
                    const rolesUser = UserQuery.data?.roles?.filter(r=>r!=='ROLE_ADMIN');
                    let niveauUser = 0;
                    RoleNiveauQuery.data.map(item=>{
                        if(rolesUser?.indexOf(item.libelle)!==-1 && item.niveau >niveauUser){
                            niveauUser = item.niveau;
                        }
                        return item;
                    })
                    const ModulesLooked = modules.filter(m => m.sectionName === myModule.sectionName)
                    ModulesLooked.map(m=>{
                        if(m.niveau<=niveauUser){
                            MyModules.push(m);
                        }
                        return m;
                    })

                    setSubMenu(MyModules);
                } else {
                    setSubMenu(null);
                }
                setSectionName(myModule.sectionName);
            } else {
                setSectionName('Main')
                setSubMenu(null);

            }
        }
    }, [location, isLoading, isError, modules, UserQuery, RoleNiveauQuery.data])
    return (
        <div className={`FullSideBar ${className}`}>
            <TimedSideBar
                UserQuery={UserQuery}
                sectionName={sectionName}
                Openable={!subMenu}
                supervisor={supervisor}
            />
            {(subMenu && UserQuery && UserQuery.data && !supervisor)  &&
                <TimedSubMenu subMenus={subMenu} sectionName={sectionName} Centre={UserQuery.data.Centre}/>
            }
            {(subMenu && supervisor && CentreQuery.data) &&
                <TimedSubMenu subMenus={subMenu} sectionName={sectionName} Centre={CentreQuery.data} supervisor={supervisor}/>
            }
        </div>
    )
}

const TimedFullSideBar = styled(TimedFullSideBarCtrl)`
    display: flex;
  justify-content: start;
  align-items: stretch;
  z-index: 3;
`

export default TimedFullSideBar;